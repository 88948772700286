.widgets-container {
    .row {
        margin-left: -7px;
        margin-right: -7px;

        .col-sm-6,
        .col-md-6 {
            padding: 0 7px 10px;

            &.no-padding-bottom {
                padding-bottom: 0;
            }
        }
    }

    .label {
        font-size: 14px;

        .money {
            font-size: 20px;
            vertical-align: top;
            line-height: 20px;
        }
    }

    .widget {
        height: 81px;
        padding: 18px 15px;
        position: relative;

        .label {
            text-align: left;
            display: block;
            margin: 0;
            padding: 0;

            &.column-content {
                font-size: 26px;
                line-height: 25px;
                padding-top: 0;
                font-family: 'Open Sans', sans-serif;

                @media (min-width: 750px) and (max-width: 1024px) {
                    font-size: 24px;

                    .money {
                        font-size: 16px;
                    }
                }

                @media (min-width: 750px) and (max-width: 850px) {
                    font-size: 20px;
                }
            }

            &.column-name {
                margin-top: 7px;

                @include bp(medium) {
                    margin-top: 3px;
                }
            }
        }

        &.widget-grey {
            background-color: $natural-medium;

            .label {
                &.column-content {
                    color: $sky;
                    margin: -5px;
                    padding: 5px;
                }
            }

            &.lapsed {
                height: 100%;

                .lapse-note {
                    margin-bottom: 0;
                }
            }
        }

        &.widget-blue {
            background-color: $sky;

            .label {
                &.column-content {
                    color: $white;
                }

                &.column-name {
                    color: $sky-medium-text;
                }
            }
        }

        &.widget-green {
            background-color: $kelly;

            .label {
                &.column-content {
                    color: $white;
                }

                &.column-name {
                    color: lighten($kelly-medium-text, 10%);
                }
            }
        }

        .icon {
            color: $sky;
            font-size: 25px;
            position: absolute;
            right: 0;
            top: 36%;
            @include transition(all .25s cubic-bezier(.64, .57, .67, 1.53));
        }

        &:hover > .icon {
            right: -10px;
        }

        &.payable {
            @include transition(all .25s ease);

            label {
                &:hover {
                    cursor: pointer;
                }
            }

            &:hover {
                background-color: darken($natural-medium, 10%);
                cursor: pointer;
            }
        }

        &.lapse {
            color: $cherry;
            background-color: lighten($cherry, 20%);

            .icon {
                color: $cherry;
            }

            .label {
                color: $cherry;

                &.column-content {
                    color: $cherry;
                }
            }

            &:hover {
                background-color: lighten($cherry, 25%);
            }
        }

        .lapse-note {
            margin-top: 0;
        }

        &-lapsed {
            height: 100px;
        }

        &.payment-disabled {
            .label {
                &.column-content {
                    font-size: 15px;
                }
            }
        }
    }

    .calendars-row {
        margin-bottom: 30px;

        .icon {
            color: $kelly;
            padding-right: 5px;
            font-size: 18px;
            line-height: 18px;
            font-weight: 600;
        }

        .label {
            color: $chalkboard-dark;
            font-weight: normal;
        }

        .icon,
        .label {
            vertical-align: middle;
        }
    }
}
