.policy-funds {
    $policy-funds-edge-padding-unit: .5em;

    &-overlay {
        background-color: $natural;
        height: 1000000px;
        left: 0;
        position: absolute;
        opacity: 0;
        top: 0;
        -webkit-transition: opacity .25s;
        transition: opacity .25s;
        width: 100%;
        z-index: 900;

        &.opaque {
            opacity: 1;
        }
    }

    .percent-input {
        background-color: $white;
        display: inline-block;
        height: inherit;
        padding: 0 .25em 0 0;
        text-align: right;
        width: 36px; // IE makes these really wide when "auto".

        &.disabled {
            opacity: .25;
        }
    }
    margin-top: 50px;
    position: relative;

    input[type=number] {
        -moz-appearance: textfield;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }

    .form-control {
        border-width: 1px;

        &.ng-valid {
            border-color: $silver;

            &:focus {
                border-color: $sky;
            }
        }

        &.changed-fund-allocation.ng-valid {
            border-color: $kelly;
        }
    }

    thead {
        th {
            padding: 1em 0;
            position: sticky; /* autoprefixer: off */
            top: 62px;
            z-index: 2;
        }
    }

    .policy-funds-changing .fund-allocations-overall-table-headers th {
        top: 112px;
        z-index: 1; // XXX: Needs to be below the funds-total-and-set-row because of its box-shadow "border-bottom".
    }

    .policy-funds-complete .fund-allocations-overall-table-headers th {
        top: 62px;
    }

    .general-details-table tr {
        th,
        td {
            &:first-child {
                padding-left: $policy-funds-edge-padding-unit * 4;

                @include bp(small) {
                    padding-left: $policy-funds-edge-padding-unit * 2;
                    max-width: 230px;
                }

                @include bp(xsmall) {
                    padding-left: $policy-funds-edge-padding-unit;
                }

                @include bp(medium) {
                    max-width: 300px;
                }
            }

            &:last-child {
                padding-right: $policy-funds-edge-padding-unit * 4;

                @include bp(small) {
                    padding-right: $policy-funds-edge-padding-unit * 2;
                }

                @include bp(xsmall) {
                    padding-right: $policy-funds-edge-padding-unit;
                }
            }
        }
    }

    .policy-fund-allocation-td {
        position: relative;
    }

    .invalid-fund-allocation-message {
        background-color: $natural-medium;
        border: 1px solid $cement;
        border-radius: 3px;
        box-shadow: 1px 1px 1px rgba($chalkboard, .4);
        color: $cherry;
        height: 24px;
        line-height: 24px;
        right: 66px;
        padding: 0 .5em;
        position: absolute;
        white-space: nowrap;
        @include bp(smaller) {
            height: auto;
            text-align: left;
            top: -10px;
            white-space: normal;
            width: 120px;
        }
    }

    .must-total-hundred-percent-message {
        color: $chalkboard-dark;
        font-size: .85em;
        font-weight: normal;
        line-height: 14px;
        position: absolute;
        right: 0;
        top: 1.5em;
    }

    &.future-allocations {
        @include bp(smaller) {
            .policy-funds-changing {
                tbody,
                .fund-allocations-overall-table-headers {
                    th,
                    td {
                        &:nth-child(2) {
                            display: none;
                        }
                    }
                }

                .funds-total-and-set-row th:first-child() {
                    display: none;
                }
            }
        }
    }

    &.fund-rebalance {
        @include bp(smaller) {
            .policy-funds-changing {
                th,
                td {
                    &:nth-child(3) {
                        display: none;
                    }
                }
            }

            .policy-funds-confirming .funds-total-and-set-row th:nth-child(3) {
                display: table-cell;
            }
        }
    }

    .policy-funds-complete {
        tbody,
        .fund-allocations-overall-table-headers {
            th,
            td {
                &:nth-child(2) {
                    display: none;
                }
            }

            &.rebalance-overall-table-headers {
                th,
                td {
                    &:nth-child(2) {
                        display: block;
                    }
                }
            }

            td {
                &.percentage-of-total {
                    display: block;
                }
            }
        }
    }

    tfoot,
    thead {
        .invalid-total-fund-allocations {
            color: $cherry;
        }

        th {
            background-color: $white;
            border-bottom: 1px solid $natural-dark;
            border-top: 1px solid $natural-dark;
            padding: 13px 8px; // To match general details table styling for th elements in tfoots.
            text-align: right;
        }
    }

    tfoot button {
        display: block;
        margin-left: auto;
        min-width: 85px; /* Both the save and cancel buttons should be the same size.*/

        &.btn-secondary {
            margin-top: 1em;
        }
    }

    thead th {
        box-shadow: 0 1px 0 $natural-dark;
    }

    caption {
        background-color: $natural-medium;
        box-sizing: border-box;
        padding: 1.5em $policy-funds-edge-padding-unit * 4;

        @include bp(small) {
            padding: 1.5em $policy-funds-edge-padding-unit * 2;
        }

        @include bp(xsmall) {
            padding: 1.5em $policy-funds-edge-padding-unit;
        }

        button {
            margin-top: .25em;
            @include bp(smaller) {
                margin-top: 1.25em;
            }
        }
    }

    .caption-innards {
        background-color: $natural-medium;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 1.5em 2em;

        .policy-funds-note {
            flex-basis: 100%;
        }
    }

    &.fund-rebalance .fund-allocation-total-percent-header-cell {
        max-width: 172px;
        padding-left: 0;
        white-space: nowrap;
    }

    .fund-allocation-total-percent {
        max-width: 310px;
        text-align: right;
        overflow-x: visible;
        position: relative;
    }

    h1 {
        vertical-align: middle;

        @include bp(smaller) {
            flex: 1 1 100%;
        }
    }

    &-change {
        margin-left: 3em;
        @include bp(smaller) {
            margin-left: 0;
        }
    }

    td,
    th {
        text-align: right;
    }

    td,
    th {
        &:first-child {
            text-align: left;
        }

        &:last-child {
            padding-left: 2em;

            @include bp(small) {
                padding-left: 1em;
            }

            @include bp(xsmall) {
                padding-left: 0;
            }
        }
    }

    .ng-enter {
        transition: .3s linear all;
        opacity: 0;
    }

    .ng-enter.ng-enter-active {
        opacity: 1;
    }

    .policy-funds-complete {
        th,
        td {
            &:nth-child(3) {
                padding-right: $policy-funds-edge-padding-unit * 4;

                @include bp(small) {
                    padding-right: $policy-funds-edge-padding-unit * 2;
                }

                @include bp(xsmall) {
                    padding-right: $policy-funds-edge-padding-unit;
                }
            }
        }
    }
}

@keyframes double-fade-faster-out {
    0% {
        opacity: 1;
    }

    5% {
        opacity: 0;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.policy-funds-viewing {
    animation-name: double-fade-faster-out;
    animation-duration: .9s;
}

.future-allocations-confirm-cancel-buttons {
    vertical-align: top;
    float: right;
}

.future-allocations-finalize {
    padding: .5em;

    section {
        display: inline-block;
    }
}

.future-allocations-adviser-owner-agreement {
    margin-right: 10px;

    wg-checkbox {
        display: inline-block;
        float: left;
        margin-right: .5em;
    }
}

.summary-container .future-allocations-adviser-owner-agreement label { // Gotta override summary label styles!
    color: $black;
    margin: 0;
    text-align: left;
    @include bp(medium) {
        font-size: 1em;
        line-height: 22px;
        vertical-align: top;
    }
}

.policy-funds-confirming tfoot th {
    background-color: $natural-dark;
}

.policy-funds.transfer {
    margin-top: 0;
}

.policy-funds.transfer td {
    position: relative;
}
