.policy-detail {
    .policy-detail-row:not(:last-of-type) {
        margin-bottom: 15px;
    }

    .investment-options {
        .cov-info-title {
            margin-bottom: 40px;
            @include bp(small) {
                margin-bottom: 20px;
            }
        }
    }

    .coverage-subheading {
        font-size: 20px;
        font-weight: normal;
        margin: 0;
    }

    &-row {
        text-align: right;
        @include bp(small) {
            text-align: left;
        }
    }
}

.policy-value {
    padding: 0;

    @include bp(small) {
        padding-left: 15px;
    }
}

.coverageInfo {
    padding-bottom: 45px;
    padding-right: 20px;

    .cov-info-title {
        margin-bottom: 40px;
        @include bp(small) {
            margin-bottom: 20px;
        }
    }

    .coverage-values {
        text-align: right;
        @include bp(small) {
            text-align: left;
        }
    }
}

.amount-free-of-charges {
    margin-top: 15px;
}

.cov-info-title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    color: $sky;
    text-align: left;
}

.no-indicator-code-value {
    margin: 0;
}

.requirements-table {
    .col1 {
        width: 25%;
    }

    .col2 {
        width: 23%;
    }

    .col3 {
        width: 8em;
    }

    .col4 {
        width: 8em;
    }
}

.alert-view-container .main-title h4,
.requirements-view-container .main-title h4 {
    margin-top: 36px;
    margin-bottom: 36px;

    &.requirements-header {
        margin-bottom: 0;
    }
}

.main-title {
    position: relative;

    .right-info,
    .left-info {
        position: absolute;
        top: 40px;
    }

    .left-info {
        left: 15px;
    }

    .right-info {
        right: 15px;
    }
}

@media (min-width: 750px) and (max-width: 970px) {
    .policy-details-table {
        font-size: 12px;
    }
}

.mail-info {
    margin-bottom: 60px;

    .cov-info-title {
        text-align: center;
        margin-bottom: 30px;
        font-size: 25px;
        font-weight: 600;
        color: $black;
    }

    .icon-container {
        position: absolute;
        left: -30px;
        top: 13px;
        display: none;
        @include bp(medium) {
            display: block;
        }

        .icon {
            font-size: 20px;
            color: $cement-medium;
        }
    }

    .payment-type {
        color: $sky;
        font-size: 17px;
        font-weight: 400;
    }

    p {
        margin: 0;
    }
}

.coverage-container {
    hr {
        margin-top: 0;
        margin-bottom: 25px;
    }

    .main-title {
        .secondary-label {
            line-height: 31px;
            padding-top: 55px;
        }

        .right-info {
            top: 40px;
        }
    }

    .box {
        div {
            line-height: 18px;
        }

        label {
            line-height: 18px;
        }
    }

    .producer-container {
        hr {
            margin-top: 0;
        }

        .box {
            margin-bottom: 16px;
        }
    }

    .payments-container {
        .quoted-label {
            padding-top: 64px;
            vertical-align: bottom;
            display: inline-block;
        }

        .data-container2 {
            margin-bottom: 36px;
            padding-right: 80px;
        }

        hr {
            margin-top: 0;
            margin-bottom: 25px;
        }

        .row-sep {
            margin-bottom: 5px;
        }

        .payment-general-info {
            margin-top: 30px;

            hr {
                margin-top: 0;
            }

            .box {
                margin-bottom: 16px;
            }
        }

        .coverage-information {
            h4 {
                &.main {
                    margin-top: 15px;
                }
            }
        }

        .mail-info {
            .icon-container {
                padding: 0;
                text-align: right;

                .icon {
                    color: $cement-medium;
                    font-size: 24px;
                    margin: auto;
                }
            }

            h4 {
                margin-bottom: 35px;
                text-align: left;

                &.main {
                    margin-top: 15px;
                    text-align: center;
                }

                &.cov-info-title {
                    margin-bottom: 20px;
                    font-size: 16px;
                    margin-top: 20px;
                    line-height: 18px;
                    font-weight: 600;
                    color: $sky;

                    @include bp(medium) {
                        margin-top: 0;
                    }
                }
            }

            p {
                color: $chalkboard-dark;
                margin: 5px 0;
                line-height: 17px;
                font-weight: 400;

                &.bold {
                    font-weight: 600;
                }
            }
        }
    }

    .transaction-history {
        padding: 20px 0;
        position: relative;

        hr {
            margin: 0 0 25px;
        }

        .account-details {
            margin-bottom: 40px;

            .transaction-history-summary {
                margin-bottom: 35px;

                .cov-info-title {
                    margin: 0;
                }
            }

            .transaction-history-details {
                overflow: hidden;
                height: 350px;

                .general-table-structure {
                    .row-container {
                        padding-left: 15px;
                        padding-right: 15px;
                        height: auto;
                        min-height: 50px;
                    }
                }

                .table-body {
                    height: 300px;
                    overflow: auto;
                }
            }
        }
    }
}

.values-container {
    hr {
        margin-top: 0;
        margin-bottom: 25px;
    }

    .row:nth-of-type(1) .values-section:nth-of-type(1) hr {
        display: none;
    }

    .account-details {
        margin-bottom: 15px;
        padding-left: 0;

        .cost-basis-values {
            float: right;
            @include bp(small) {
                float: left;
            }
        }
    }

    .contract-value {
        float: right;
    }

    .general-details-table {
        .general-table-structure {
            margin-bottom: 15px;
            @include bp(small) {
                display: none;
            }
        }
    }

    .footer-links,
    .disclaimer-container,
    .accounts-footnote {
        display: inline-block;
        vertical-align: top;

        p {
            color: $cement-medium;
            font-size: 12px;
            margin: 0;
            line-height: 16px;
        }
    }

    .accounts-footnote {
        margin-top: 10px;
    }

    .main-title {
        .secondary-label {
            line-height: 31px;
            padding-top: 55px;
            padding-bottom: 18px;
        }

        .left-info {
            top: 4px;
        }

        .quoted-label {
            padding-top: 64px;
            vertical-align: bottom;
            display: inline-block;
        }
    }

    .note {
        font-size: 12px;
        line-height: 14px;

        &.contact-charges {
            padding: 16px 0 15px;
        }
    }

    .policy-loan-balance {
        margin-top: 20px;
        margin-bottom: 50px;
    }

    .line-row {
        margin-bottom: 4px;

        &.highlight-info {
            margin-bottom: 0;

            label,
            span {
                font-weight: 600;
                line-height: 18px;
            }
        }
    }

    .widgets-container {
        margin-top: 30px;

        .row {
            margin-left: -15px;
            margin-right: -15px;
        }
    }

    .math-container {
        hr {
            border-color: #3984a1;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .line-row {
            &.highlight-info {
                margin-bottom: 4px;
            }
        }
    }

    .highlight-info {
        label {
            color: #4d4d4d;
        }

        .text {
            color: #007499;
            font-weight: 600;
        }
    }

    .subaccount-header {
        margin-top: 30px;
        margin-bottom: 30px;

        .subaccount-item {
            float: left;
            margin-right: 20px;
        }
    }

    .transaction-history {
        padding: 0 0 10px;
        position: relative;

        hr {
            margin-top: 25px;
        }

        .transaction-history-summary {
            margin-bottom: 30px;

            .cov-info-title {
                margin: 0;
            }
        }

        .transaction-history-details {
            .general-table-structure {
                .row-container {
                    padding-left: 15px;
                    padding-right: 15px;
                    height: auto;
                    min-height: 50px;

                    .btn-show-details {
                        text-decoration: none;
                        color: $chalkboard-dark;
                        margin-right: 5px;
                    }

                    .sub-section {
                        line-height: normal;

                        & > .row {
                            padding: 0 0 25px;

                            &:first-child {
                                padding-top: 25px;
                            }
                        }
                    }

                    &.open {
                        background-color: #f7f7f7;
                        border-bottom: 0;

                        .sub-section {
                            & > .row {
                                &:first-child {
                                    border-top: 1px solid $silver;
                                }
                            }
                        }
                    }
                }
            }
        }

        .btn-container {
            margin-top: 20px;

            .btn-view-all {
                background: $white 0 0;
                border: 1px solid $cement;
                color: $chalkboard-dark;
                font-weight: 600;
                min-width: 140px;

                &:hover,
                &:focus {
                    color: $chalkboard-dark;
                    border-color: $chalkboard-dark;
                    -webkit-transition: all .3s;
                    -moz-transition: all .3s;
                    -ms-transition: all .3s;
                    -o-transition: all .3s;
                    transition: all .3s;
                }
            }
        }
    }

    .policy-transaction-history-modal {
        &.open {
            display: block;
        }

        .modal-dialog {
            width: auto;

            .modal-header {
                .close {
                    color: $cement-medium;
                    opacity: 1;
                    font-size: 20px;
                    line-height: 22px;
                    font-weight: 400;
                    outline: 0;
                    position: absolute;
                    right: 20px;
                    top: 24px;
                }
            }
        }

        .transaction-history {
            padding: 0 0 20px;
        }
    }

    .spinner-container {
        position: relative;
        height: 50px;
        width: 100%;
        display: block;

        .spinner {
            &.spinner-small {
                bottom: auto;
                top: 50%;
            }
        }
    }

    .section-footnotes {
        padding-bottom: 20px;
    }

    .values-rows-container {
        clear: both;
    }

    .datepicker-container,
    .select-wrapper {
        max-width: 175px;
    }

    .pending-transfer-note {
        margin-top: 0;
    }
}

.riders-container {
    .section:first-of-type {
        margin-top: 0;
        border-top: 0;
    }

    .table {
        margin-bottom: 0;
    }

    .table-sub-accounts {
        margin-top: 22px;
    }
}

.benefits-container {
    .table {
        margin-bottom: 0;
    }

    .table-sub-accounts {
        margin-top: 22px;
    }

    .icon-rider-policy-status {
        position: relative;

        &:before {
            position: absolute;
            font-size: 22px;
            line-height: 22px;
            margin-left: -38px;
            top: calc(50% - 11px);
            color: $sky;
        }
    }
}

.pua-details-container {
    .table {
        > tbody {
            > tr {
                background-color: transparent;

                > td {
                    padding: 4px 8px;

                    &:first-child {
                        color: $cement-medium;
                    }
                }
            }
        }
    }
}

.regulatory-test-value,
.additional-info-value {
    line-height: 18px;
}

.commission-info,
.additional-info {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid $silver;

    .line-row {
        span {
            display: block;
        }
    }
}
