.wg-client-link {
    cursor: pointer;

    .owner-icon,
    .insured-icon,
    .beneficiary-icon {
        float: left;
        margin-right: 0;
        height: 30px;
        line-height: 30px;
        color: $white;
        width: 30px;
        font-size: 16px;
        text-align: center;
        margin-top: 4px;
        border-radius: 30px;
        border-width: 15px;
    }

    .insured-icon {
        background-color: $sky;
    }

    .owner-icon {
        background-color: $apricot-dark;
    }

    .beneficiary-icon {
        background-color: $kelly;
    }

    .icon-multiple-users {
        font-size: 21px;
    }

    .client-link-content {
        margin-left: 40px;
    }

    .column-title,
    .column-content {
        font-size: 16px;
        line-height: 20px;
        color: $chalkboard-dark;
        text-transform: capitalize;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;

        @include bp(medium) {
            font-size: 14px;
            line-height: 18px;
        }
    }

    .column-title {
        margin-top: 2px;

        @include bp(medium) {
            margin-bottom: 4px;
        }
    }
}
