.result-box {
    margin-bottom: 0;
    position: relative;
    cursor: pointer;
    display: block;
    min-height: 78px;
    padding: 0;

    @include bp(medium) {
        background-color: inherit;
        padding: 0 15px;
        margin-bottom: 20px;
    }

    &.inforce .container-policy-item,
    &.unsubmitted .container-policy-item {
        @include policy-border-color($kelly);
    }

    &.annuity .container-policy-item {
        @include policy-border-color($sky);
    }

    &.first .main-row {
        border-top: 1px solid $natural-dark;

        @include bp(medium) {
            border-top: none;
        }
    }

    &.unsubmitted {
        cursor: default;
    }
}

.wg-policy-row {
    position: relative;
    display: block;
    @include policy-left-border();

    .icon-policy,
    .owner-icon,
    .insured-icon,
    .beneficiary-icon {
        float: left;
        margin-right: 0;
        height: 30px;
        line-height: 30px;
        color: $white;
        width: 30px;
        font-size: 16px;
        text-align: center;
        margin-top: 4px;
        border-radius: 30px;
        border-width: 15px;
    }

    .main-row {
        position: initial;
        background-color: transparent;
        padding: 14px 20px 0;

        @include bp(medium) {
            position: relative;
            padding-bottom: 14px;
            background-color: #eaeaea;

            .column-title,
            .column-content {
                line-height: 18px;
            }

            .policy-header {
                font-size: 20px;
                line-height: 26px;
                margin-bottom: 2px;
            }
        }
    }

    .first-column {
        padding-left: 0;

        .icon-policy {
            font-size: 24px;
            line-height: 34px;

            @include bp(medium) {
                line-height: 49px;
            }
        }
    }

    .prominent-feature {
        margin-bottom: 2px;
        padding-left: 28px;

        .column-title,
        .column-content {
            padding-left: 10px;
            padding-right: 15px;
        }
    }

    .column-title,
    .column-content {
        font-size: 16px;
        line-height: 20px;
        color: $cement-medium;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;

        @include bp(medium) {
            font-size: 14px;
            line-height: 17px;
        }
    }

    .column-title {
        text-transform: capitalize;

        @include bp(medium) {
            margin-bottom: 4px;
        }
    }

    .column-content {
        color: $chalkboard-dark;
    }

    .policy-header {
        font-size: 20px;
        line-height: 26px;
        color: $chalkboard-dark;
        margin-bottom: 2px;
    }

    .column-title-nopad {
        padding: 0 !important;
    }

    .post-policy-header-content {
        .column-title {
            margin-top: 2px;
        }

        .column-title,
        .column-content {
            display: inline;

            @include bp(medium) {
                display: block;
            }
        }
    }

    .secondary-row {
        border-bottom: 1px solid $natural-dark;
        background-color: transparent;
        padding: 0 7px 16px;

        @include bp(medium) {
            padding-top: 6px;
            padding-bottom: 12px;
            background-color: $natural-dark;
            border-bottom: none;
            min-height: 45px;
        }

        .column {
            float: left;
            margin-right: 30px;
            margin-top: 8px;

            &.ny-issued {
                float: right;
            }
        }

        .column-title {
            float: left;
            font-size: 14px;
            line-height: 17px;
            color: $cement-medium;
            padding-right: 2px;
            text-transform: none;

            @include bp(medium) {
                margin-bottom: 0;
            }
        }

        .column-content {
            float: left;
            font-size: 14px;
            line-height: 17px;
            color: $chalkboard-dark;
            margin-left: 2px;
        }
    }

    .separator-vertical-line {
        background-color: $silver;
        margin: 0 8px;
    }

    .stacked-item-space-top {
        margin-top: 2px;
    }

    .adjust-policy-alignment,
    .adjust-policy-alignment-status {
        padding: 0 35px 0 0;
    }

    .adjust-policy-alignment {
        @include bp(medium) {
            padding-top: 3px;
        }
    }

    .beneficiary-icon {
        background-color: $sky;
    }

    .owner-icon {
        background-color: $kelly;
    }

    .icon-policy {
        background-color: $apricot-dark;
    }

    .insured-icon {
        background-color: $apricot-dark;
    }

    .icon-multiple-users {
        font-size: 21px;
    }

    .post-policy-header-content {
        margin-left: 38px;
    }

    .requirement-column-container {
        margin-left: 30px;
        margin-top: -3px;

        @include bp(medium) {
            margin-top: 2px;
            margin-bottom: -2px;
            float: right;
        }

        @include bp(small) {
            margin-left: 37px;
        }

        .btn-favorite {
            top: 4px;
        }

        .req-text {
            font-size: 16px;
            line-height: 24px;

            @include bp(medium) {
                font-size: 14px;
                line-height: 17px;
            }
        }
    }

    &.unsubmitted-item {
        .first-column {
            .icon-policy {
                line-height: 48px;
            }
        }

        .requirement-column-container {
            .btn {
                &-favorite {
                    top: 1px;
                }
            }
        }

        .requirements-widget {
            cursor: default;
        }

        .post-policy-header-content {
            @include bp(small) {
                margin-left: 25px;
            }
        }

        .requirement-column-container {
            @include bp(small) {
                display: inline-block;
                margin-left: 12px;

                .widget-content {
                    vertical-align: sub;
                }

                .req-text {
                    line-height: 23px;
                }
            }
        }

        .secondary {
            &-days-open {
                display: inline-block;
                vertical-align: middle;

                .column {
                    &-title,
                    &-content {
                        font-size: 16px;
                    }

                    &-content {
                        line-height: 21px;
                    }
                }

                &.post-policy-header-content {
                    margin-left: 37px;

                    @include bp(small) {
                        margin-left: 12px;
                    }
                }
            }
        }
    }

    .payable {
        &-messaging {
            color: $kelly;
            margin-top: 5px;
            @include bp(small) {
                font-size: 16px;
            }

            .icon {
                font-size: 20px;
            }

            span {
                vertical-align: text-bottom;
            }
        }
    }

    .ny-issued {
        color: $white;
        background: $sky;
        padding: 0 10px;
        border-radius: 11px;
        font-size: 12px;
    }

    .issue-state-restricted {
        color: $white;
        background: $cherry;
        padding: 0 10px;
        border-radius: 11px;
        font-size: 12px;
    }

    &.disabled {
        opacity: .5;
        cursor: not-allowed;
    }
}

.arrow-right-container {
    right: 20px;

    .icon-arrow-right {
        color: $cement;
        font-weight: bold;
        font-size: 18px;
    }
}

.policy-row-favorite .btn-favorite {
    position: relative;
    top: 5px;
}

.unsubmitted {
    .adjust-policy-alignment {
        padding-right: 0;
    }

    .wg-policy-row .post-policy-header-content {
        margin-left: 0;
    }

    &-major-content-column {
        padding-right: 0;

        .column-title {
            overflow: visible;
        }
    }
}

.insured-name-prominent {
    .insured-icon {
        background-color: transparent;
        font-size: 1.75em;
    }

    &.product-policy .insured-icon {
        color: $kelly;
    }

    &.product-annuity .insured-icon {
        color: $sky-dark;
    }

    /*
    .icon-policy {
        color: $white;
    }
    */
}

.product-name-prominent {
    .icon-policy {
        background-color: transparent;
        font-size: 1.75em;
    }

    &.product-policy .icon-policy {
        color: $kelly;
    }

    &.product-annuity .icon-policy {
        color: $sky;
    }
}
