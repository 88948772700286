.policy-print {
    &-container {
        label {
            font-size: 9px;
            color: $cement-dark !important; // Thanks for that bootstrap

            & + p {
                line-height: 10px;
            }
        }

        .table {
            > tbody {
                > tr {
                    > td {
                        font-size: 10px;
                        padding: 0;
                    }
                }
            }
        }

        .secondary-heading-row {
            th {
                font-size: 9px;
                color: $cement-dark !important; // Thanks for that bootstrap
            }
        }

        .mailing-address {
            p {
                line-height: 17px;

                &.full-name,
                &.address-line-primary,
                &.address-line-secondary,
                &.address-city-state-zip {
                    font-size: 14px;
                    margin-bottom: 5px;
                    text-transform: uppercase;
                }
            }

            &.agent {
                .full-name {
                    font-size: 10px;
                    margin-bottom: 0;
                }

                > p,
                .email {
                    text-transform: inherit;
                }
            }
        }

        p {
            font-size: 10px;
        }

        .print {
            &-header {
                padding-top: 0;

                p {
                    font-size: 15px;
                    margin-bottom: 0;
                }
            }
        }

        .contact {
            &-section {
                p {
                    margin: 0;
                }

                .table {
                    span {
                        display: block;
                    }
                }
            }
        }

        .policy {
            &-summary-section {
                h3 {
                    font-size: 27px;
                }
            }

            &-adviser-section {
                h3 {
                    &.adviser-section-heading {
                        font-size: 28px;
                    }
                }
            }
        }

        .copyright {
            padding: 0;
            margin: 15px -15px;
            text-align: left;

            p {
                color: $cement-medium !important; //Thanks again bootstrap!
            }
        }

        .vul-accounts-notes {
            p {
                color: $cement-medium !important;
                margin: 0;
                line-height: 17px;
            }
        }

        .values-rows-container {
            .copyright {
                margin: 15px;
            }
        }
    }
}

.summary {
    &-container {
        .popup-container {
            &.print-container {
                .print-header {
                    margin-top: 0;
                }

                .policy-print-sections {
                    padding: 0;
                }
            }
        }
    }
}