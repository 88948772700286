@font-face {
    font-family: 'icomoon';
    src: url('vendors/icomoon/icomoon.eot?89vm5t');
    src: url('vendors/icomoon/icomoon.eot?89vm5t#iefix') format('embedded-opentype'), url('vendors/icomoon/icomoon.ttf?89vm5t') format('truetype'), url('vendors/icomoon/icomoon.woff?89vm5t') format('woff'), url('vendors/icomoon/icomoon.svg?89vm5t#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-PML_linear_logo_cmyk-white:before {
    content: '\e915';
    color: #fffffe;
}

.icon-account_circle_white_24dp-copy:before {
    content: '\e900';
    color: #fff;
}

.icon-apps_white_24dp-copy:before {
    content: '\e901';
    color: #fff;
}

.icon-maps-location-circle:before {
    content: '\e600';
}

.icon-info:before {
    content: '\e601';
}

.icon-alert:before {
    content: '\e602';
}

.icon-user:before {
    content: '\e603';
}

.icon-uniE604:before {
    content: '\e604';
}

.icon-angled-arrow:before {
    content: '\e605';
}

.icon-policy:before {
    content: '\e606';
}

.icon-calendar:before {
    content: '\e607';
}

.icon-maps-location:before {
    content: '\e608';
}

.icon-business:before {
    content: '\e609';
}

.icon-logout:before {
    content: '\e60a';
}

.icon-statement:before {
    content: '\e60b';
}

.icon-performance:before {
    content: '\e60c';
}

.icon-confirmation:before {
    content: '\e60d';
}

.icon-bill:before {
    content: '\e60e';
}

.icon-letter:before {
    content: '\e60f';
}

.icon-illustration:before {
    content: '\e610';
}

.icon-add:before {
    content: '\e611';
}

.icon-search:before {
    content: '\e612';
}

.icon-profile:before {
    content: '\e613';
}

.icon-warning:before {
    content: '\e614';
}

.icon-close:before {
    content: '\e615';
}

.icon-full-arrow-right:before {
    content: '\e616';
}

.icon-multiple-users:before {
    content: '\e617';
}

.icon-sort-up-arrow:before {
    content: '\e61c';
}

.icon-arrow-right:before {
    content: '\e61d';
}

.icon-sort-down-arrow:before {
    content: '\e61e';
}

.icon-verify:before {
    content: '\e64e';
}

.icon-timer:before {
    content: '\e651';
}

.icon-policy-folded:before {
    content: '\e669';
}

.icon-download:before {
    content: '\e66d';
}

.icon-edit:before {
    content: '\e670';
}

.icon-mail:before {
    content: '\e671';
}

.icon-eye:before {
    content: '\e675';
}

.icon-hamburger:before {
    content: '\e6a8';
}

.icon-mobile:before {
    content: '\e6ac';
}

.icon-recently-viewed:before {
    content: '\e800';
}

.icon-heart-selected:before {
    content: '\e902';
}

.icon-heart-unselected:before {
    content: '\e903';
}

.icon-sort-double-arrow:before {
    content: '\e904';
}

.icon-from-calendar:before {
    content: '\e905';
}

.icon-to-calendar:before {
    content: '\e906';
}

.icon-check:before {
    content: '\e907';
}

.icon-bill-01:before {
    content: '\e908';
}

.icon-more:before {
    content: '\e909';
}

.icon-fax:before {
    content: '\e90a';
}

.icon-mail-heavy:before {
    content: '\e90b';
}

.icon-phone:before {
    content: '\e90c';
}

.icon-logo:before {
    content: '\e90d';
}

.icon-megaphone:before {
    content: '\e90e';
}

.icon-row-view:before {
    content: '\e90f';
}

.icon-inbox-upload:before {
    content: '\e910';
}

.icon-arrow_drop_up:before {
    content: '\e911';
}

.icon-arrow_drop_down:before {
    content: '\e912';
}

.icon-trashcan:before {
    content: '\e913';
}

.icon-table-view:before {
    content: '\e914';
}

.icon-bullhorn:before {
    content: '\e91a';
}

.icon-external-link:before {
    content: '\e9023';
}

.icon-undo:before {
    content: '\e999';
}
  