.agent-menu-container .agent-menu {
    @include bp(medium) {
        right: inherit;
        top: inherit;
        background-color: $natural-medium;
    }

    .scrollable {
        overflow-y: scroll;
    }

    li.active {
        color: #0089d5;
        position: relative;

        &::after {
            content: '\e907';
            color: inherit;
            position: absolute;
            top: 14px;
            right: 20px;
            font-family: icomoon;
            font-weight: bold;
        }
    }

    ul {
        li {
            &:first-child {
                border-top: solid 1px $natural-medium;
            }

            .cat-name {
                width: 100%;
                padding-right: 20px;
            }
        }

        a:first-child li {
            border-top: none;
        }

        > li:first-child {
            border-top: none;
        }
    }

    .close-container {
        padding: 30px 20px;

        .icon-close {
            color: $cement;
            font-size: 26px;
        }
    }

    .search-box {
        height: 52px;
        background-color: #e5e5e5;
        color: $cement-medium;
        margin: 20px;
        border-radius: 5px;

        @include bp(medium) {
            margin: 0;
            border-radius: 0;
        }

        &.icon-search {
            position: relative;

            &::before {
                position: absolute;
                font-size: 22px;
                line-height: 52px;
                left: 18px;
                color: $chalkboard-dark;
            }

            input {
                font-family: 'Open Sans', sans-serif;
            }
        }

        .agent-menu-icon {
            display: block;
            color: $chalkboard-dark;
            padding-left: 50px;
        }

        .form-control {
            background: transparent;
            border: none;
            height: 52px;
            line-height: 52px;
            padding-left: 50px;
        }
    }

    .search-list {
        height: initial;
        overflow-y: scroll;

        @include bp(medium) {
            max-height: 343px;
        }
    }

    .first-time-message {
        margin-top: 74px;

        .icon.icon-search {
            display: block;
            font-size: 34px;
            height: 34px;
            color: #dcdcdc;
            text-align: center;
            cursor: default;
            padding: 0;
            margin: 0;
        }

        p {
            font-size: 14px;
            color: $cement-medium;
            text-align: center;
            padding: 0 40px;
        }
    }
}

.header-lg {
    .agent-menu-container .agent-menu {
        @include bp(medium) {
            right: -95px;
        }
    }
}
