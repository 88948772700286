.alerts {
    &.alert-view-container {
        .list-title {
            color: $chalkboard-dark;
        }

        .link-separator {
            height: 16px;
            margin: 2px 5px 0;
            vertical-align: middle;
        }

        .links-above-table {
            position: relative;
            z-index: 1;

            .link {
                line-height: 20px;
                vertical-align: top;
            }

            & > [class*="col-"] {
                & > div {
                    line-height: 100%;
                }
            }
        }

        .form-alert-filter .form-group:last-child {
            @include bp(small) {
                margin-bottom: 0;
            }
        }

        .view-all-alerts {
            .links-above-table {
                padding-top: 0;
            }

            .alert-mobile-above-boxes {
                padding: 18px 15px;
            }
        }

        .section-top-info {
            padding-top: 20px;
            padding-bottom: 20px;
            color: $white;
            font-weight: 300;

            .alerts-page-title {
                @include bp(medium) {
                    padding: 0 15px;
                    font-size: 24px;
                    text-transform: none;
                }
                @include bp(small) {
                    font-size: 22px;
                    text-transform: none;
                }
            }

            &.section-search-alerts {
                background: $natural;
                padding: 25px 0 0;

                @include bp(medium) {
                    // margin-bottom: 30px;
                    padding-top: 18px;
                    padding-bottom: 20px;
                }

                .link {
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    letter-spacing: normal;
                    font-size: 14px;
                }

                .form-alert-filter {
                    color: $chalkboard-dark;

                    .alert-search-reset {
                        @include bp(medium) {
                            padding-left: 10px;
                        }
                    }

                    & > .row {
                        padding-bottom: 15px;

                        .form-group {
                            &.form-group-datepicker {
                                @include bp(medium) {
                                    width: 100%;
                                }
                            }
                        }

                        &.last {
                            padding-top: 5px;

                            [class*="col-"] {
                                line-height: 100%;
                            }

                            .form-group {
                                &.group-unassigned {
                                    padding: 0;
                                    vertical-align: middle;
                                    line-height: 40px;
                                    padding: 0;
                                    position: relative;

                                    &:first-child {
                                        padding-left: 0;
                                    }

                                    .label-unassigned {
                                        margin-left: 5px;
                                    }
                                }
                            }
                        }
                    }

                    .datepicker-container input {
                        padding-left: 36px;
                    }
                }

                .styled-select {
                    background: url('../images/dropdown-arrow-down-48px-128.png') no-repeat right $white;
                    background-size: 33px;
                }

                .styled-icon-select {
                    position: relative;

                    [class*="icon-"] {
                        position: absolute;
                        right: 16px;
                        bottom: 0;
                        top: 0;
                        margin: auto;
                        height: 11px;
                        font-size: 11px;
                        font-weight: bold;
                        color: $black;
                    }
                }

                .form-control {
                    font-size: 14px;
                    height: 40px;
                    max-width: none;
                    width: 100%;
                    padding: 6px 15px;
                    font-weight: normal;

                    &.btn {
                        min-width: 79px;
                    }

                    &.dropdown {
                        -webkit-appearance: none;
                        background-color: transparent;
                        text-transform: none;
                        padding-right: 30px;
                    }
                }

                input,
                select {
                    &.form-control {
                        color: $chalkboard-dark;

                        &::-webkit-input-placeholder { /* Chrome */
                            color: $cement-medium;
                        }

                        &:-ms-input-placeholder { /* IE 10+ */
                            color: $cement-medium;
                        }

                        &::-moz-placeholder { /* Firefox 19+ */
                            color: $cement-medium;
                            opacity: 1;
                        }

                        &:-moz-placeholder { /* Firefox 4 - 18 */
                            color: $cement-medium;
                            opacity: 1;
                        }

                        &.no-selection {
                            color: $cement-medium;
                        }
                    }
                }

                label {
                    color: $chalkboard-dark;
                    display: block;
                    font-size: 14px;
                    line-height: 16px;
                    margin-bottom: 5px;
                    padding: 0;
                }

                .form-group {
                    vertical-align: bottom;

                    &.group-unassigned {
                        padding: 7px 0;
                    }

                    .wg-checkbox,
                    .label-unassigned {
                        display: inline-block;
                        vertical-align: middle;
                        margin-bottom: 0;
                        cursor: pointer;
                    }
                }

                @include bp(medium) {
                    .form-group {
                        margin-bottom: 0;
                    }
                }
            }

            .wg-checkbox-container {
                padding: 0;
                margin: 0;

                .wg-checkbox-inner {
                    display: block;
                    margin: auto 0;
                }
            }

            hr {
                &.section-horizontal-separator {
                    margin: 15px 0 0;
                }
            }
        }
    }

    .div-relative-container {
        &.circle {
            position: absolute;
            left: -13px;
            top: 8px;
            width: 8px;
            height: 8px;
            background-color: $cherry;
            border-radius: 4px;
        }
    }
}

.client-alerts {
    margin-right: -5px;
}

.alert-label,
.client-alerts .alert-label {
    color: white;
    display: inline-block;
    margin-bottom: 0;
}

.padding-right-8-34 {
    padding-right: 8.34px !important;
}

.padding-left-8-34 {
    padding-left: 8.34px !important;
}

.padding-right-11-67 {
    padding-right: 11.67px !important;
}

.padding-left-11-67 {
    padding-left: 11.67px !important;
}

@media (max-width: 767px) {
    .alerts {
        &.alert-view-container {
            .hidden-xs {
                display: none !important;
            }

            .visible-xs {
                display: block !important;
            }

            .form-inline {
                .form-group {
                    display: block;

                    &.btn-container {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}
