.wg-checkbox-container {
    padding: 7px;
    margin: -7px;
}

.wg-checkbox-inner {
    height: 20px;
    width: 20px;
    border: solid 1px $silver-medium;
    background-color: $white;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    margin-top: 1px;
    @include transition(background-color .5s ease);

    &.checked {
        border: none;
        background-color: $sky;
        color: $white;
        font-size: 16px;
        line-height: 20px;
        padding-top: 1px;
        padding-left: 2px;
    }

    &.disabled {
        background-color: $silver-medium;
        color: $white;
        
        &:hover {
            cursor: default;
        }
    }
}
