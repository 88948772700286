.wg-client-list {
    border-top: 1px solid $natural-dark;

    @include bp(medium) {
        border-top: none;
    }
}

.wg-client-row-desktop,
.wg-client-row-mobile {
    position: relative;
    @include policy-left-border();
    @include policy-border-color($sea-foam);
}

.first .wg-client-row-mobile {
    border-top: 1px solid $natural-dark;
}

.wg-client-row {
    cursor: pointer;

    .icon-user,
    .icon-business {
        color: $sea-foam;
        position: absolute;
        top: 4px;
    }

    .icon-user {
        margin-right: 0;
        font-size: 22px;
    }

    .icon-business {
        margin: 0 -2.5px;
        font-size: 24px;
    }

    .client-info {
        margin-left: 38px;
        vertical-align: bottom;

        .client-name {
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 2px;
        }

        .client-age {
            font-size: 16px;
            line-height: 22px;
        }
    }

    .wg-client-row-mobile {
        margin: 0 -15px;
        padding: 14px 15px 16px 20px;
        cursor: pointer;
        position: relative;
        border-bottom: 1px solid $natural-dark;

        .favorite-container {
            right: 14px;
            top: 22px;
            width: auto;
        }

        .icon-user {
            margin-left: 3px;
        }
    }

    .wg-client-row-desktop {
        margin-bottom: 20px;
        padding: 0 15px;

        .content {
            padding: 17px 10px;
            background-color: #eaeaea;
            position: relative;

            .icon-user {
                top: 2px;
            }

            .icon-business {
                top: 8px;
            }

            .icon-user,
            .client-info {
                padding-top: 7px;
            }

            .column-title {
                color: $cement-medium;
                margin-top: 2px;
                margin-bottom: 4px;
                font-size: 14px;
                line-height: 18px;
                font-weight: normal;
            }

            .column-content {
                color: $chalkboard-dark;
                display: block;
                line-height: 18px;
                font-weight: normal;

                @include bp(medium) {
                    &.address {
                        margin-right: 25px;
                    }
                }
            }
        }

        .footer {
            background-color: $natural-dark;
            padding: 9px 10px 10px;

            .column-content {
                line-height: 26px;
                color: $chalkboard-dark;
                font-size: 14px;
            }

            .btn-favorite {
                position: relative;
                top: 2px;
            }
        }
    }
}
