.pagination {
    > li {
        display: inline-block;

        > a {
            border: 0;
            color: $chalkboard-dark;
            background-color: transparent;
            padding: 6px 8px;

            &:hover {
                color: $chalkboard-dark;
                background-color: transparent;
                font-weight: bold;
            }
        }
    }

    > .active {
        >  {
            a {
                background-color: transparent;
                color: $chalkboard-dark;
                font-weight: bold;
                border-bottom: 1px solid $chalkboard-dark;
                padding: 5px 8px;

                &:hover {
                    color: $chalkboard-dark;
                    background-color: transparent;
                    border-color: $chalkboard-dark;
                }
            }
        }
    }

    > .disabled {
        > a {
            background-color: transparent;
            color: $chalkboard-dark;

            &:hover {
                background-color: transparent;
                color: $chalkboard-dark;
            }
        }
    }

    .pagination {
        &-prev,
        &-next {
            border: 1px solid $silver;
            width: 40px;
            height: 40px;
            position: relative;
            overflow: hidden;

            a {
                width: 100%;
                height: 100%;
                font-size: 14px;
                font-family: 'icomoon';
                padding: 8px 13px;
                transition: padding-left .15s ease-out;

                &:hover {
                    padding-left: 15px;
                }
            }

            &.disabled {
                a {
                    &:hover {
                        padding-left: 13px;
                    }
                }
            }
        }

        &-prev {
            transform: rotate(180deg);
        }
    }
}