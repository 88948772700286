.section {
    &:not(:first-of-type) {
        margin-top: 45px;
        border-top: 1px solid $silver;
    }
    padding-top: 25px;

    .section-heading {
        font-size: 18px;
        font-weight: bold;
        color: $sky;
        margin: 0; // bootstrap override
        padding-top: 8px;
        padding-bottom: 23px;
        text-align: center;

        &.sub-section-heading {
            line-height: 18px;
            padding-top: 18px;
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 2px;
            color: $chalkboard-dark;
            text-align: left;
        }
    }

    &.sub-section {
        border-top: 0;
        padding-top: 0;
        margin-top: 20px;

        .section-heading {
            line-height: 18px;
            padding-top: 18px;
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 2px;
            color: $chalkboard-dark;
            text-align: left;
        }
    }

    &.cms {
        margin-top: 0;

        .field {
            margin-bottom: 10px;
        }

        .section-footer {
            display: block;
            margin: 10px 0;
        }
    }
}

.footnote-row {
    margin-top: 20px;
}

.disclaimer-item {
    padding-bottom: 5px;

    &:last-of-type {
        padding-bottom: 0;
    }
}

.field-row {
    margin-top: 10px;

    &:first-of-type {
        margin-top: 0;
    }
}

.field-row-subsection {
    margin-bottom: 20px;
}

.pua-rider-container {
    text-align: right;

    .label {
        font-size: 14px;
    }
}