a {
    color: $bright-link;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        color: $bright-link;
    }
}

.link-hover-only {
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.link,
.link:hover,
.link:visited {
    color: #0089d5;
    font-size: 14px;
    cursor: pointer;
}

.link-color,
.link-color:hover,
.link-color:visited {
    color: $bright-link;
}

.grey-link,
.bluelink {
    @include bp(small) {
        font-size: 16px;
    }
}

.grey-link {
    color: $chalkboard-dark;
    cursor: pointer;
    font-weight: normal;
}

a.bluelink,
a.bluelink:visited {
    color: $sky;
    cursor: pointer;
}

a.bluelink[disabled],
a.bluelink[disabled]:visited,
a.bluelink[disabled]:hover {
    color: $silver-dark;
    text-decoration: none;
    cursor: default;
}

a.redlink,
a.redlink:visited {
    color: $cherry;
    cursor: pointer;
}

.external-link {
    text-decoration: underline;
}
