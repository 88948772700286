.favorite-right-container {
    right: 18px;
    top: 13px;
    position: absolute;
}

.btn-favorite {
    vertical-align: middle;
    color: $cement;
    cursor: pointer;

    &:focus,
    &:hover {
        text-decoration: none;
    }

    .icon {
        color: $cement-medium;
        font-size: 20px;
        margin: 0;
        margin-left: 7px;
        vertical-align: middle;

        @include bp(medium) {
            font-size: 18px;
            vertical-align: baseline;
        }
    }

    &.active {
        .icon {
            &::before {
                color: $cherry;
                content: '\e902';
            }
        }
    }
}

.client-favorite .icon,
.section-user-info .client-favorite .icon {
    background: none;
    width: initial;
    height: initial;
    margin: 0;
    margin-left: 12px;
}

.wg-favorite {
    display: inline-block;
    vertical-align: bottom;
}

.white-favorite .btn-favorite .icon,
.white-favorite.btn-favorite .icon {
    color: $white;
}
