.funds-transfer-source-row {
    vertical-align: middle;
}

.policy-funds-transfer-section {
    .transfer-heading {
        background-color: $natural-medium;
        padding: 1.5em 2em;
        display: inline-block;
        width: 100%;

        h1 {
            display: inline-block;
            vertical-align: middle;
        }

        .btn {
            &.cancel {
                margin-left: 15px;
            }
        }
    }

    .to-fund {
        tfoot,
        thead {
            .fund-transfer {
                &-overall-table {
                    &-headers {
                        > th,
                        > td {
                            height: 73px;
                        }
                    }
                }
            }
        }
    }

    tfoot,
    thead {
        th {
            @include general-details-table-header-footer;
        }

        .allocation-total {
            &-container {
                position: absolute;
                top: 24px;
                right: 8px;
            }

            &-header {
                width: 30%;
                position: relative;
            }
        }
    }

    tfoot {
        .fund-transfer {
            &-overall-table {
                &-headers {
                    > td {
                        font-weight: 700;

                        span {
                            display: block;
                        }
                    }
                }

                &-headers,
                &-totals {
                    > td {
                        background-color: $white;
                        border-top: 1px solid $natural-dark;
                        border-bottom: 1px solid $natural-dark;
                        text-align: right;
                    }
                }

                &-totals {
                    > td {
                        text-align: right;
                    }
                }

                &-buttons {
                    > td {
                        text-align: right;
                        border-bottom: 1px solid $natural-dark;
                        background-color: $white;

                        .transfer {
                            &-buttons,
                            &-agreement {
                                display: inline-block;
                            }

                            &-buttons {
                                float: right;
                            }
                        }
                    }
                }
            }
        }
    }

    .transfer-button-group {
        @include general-details-table-header-footer;
    }

    .from {
        &-fund {
            position: sticky; /* autoprefixer: off */
            top: 63px;
            z-index: 1;

            th {
                position: inherit;
            }

            &.general-details-table {
                > tbody {
                    > tr {
                        > td {
                            box-shadow: 0 1px 0 $silver; // Box shadow rather than border because of know browser quirks with sticky.
                        }
                    }
                }
            }
        }
    }

    .to {
        &-fund {
            > thead {
                > tr {
                    th {
                        position: relative;
                        position: sticky; /* autoprefixer: off */
                        top: 62px;
                    }
                }
            }
        }
    }

    .pending-transfer {
        &:after {
            content: '*';
            display: inline-block;
            color: $cherry;
        }
    }

    .source,
    .destination {
        &-funds {
            h4 {
                font-weight: 400;
            }
        }
    }

    .destination {
        &-funds {
            margin-top: 25px;
        }
    }

    .source {
        &-funds {
            .percent-input {
                background-color: $white;
                display: inline-block;
                height: inherit;
                padding: 0 .25em 0 0;
                text-align: right;
                width: 36px;
            }

            .general-details-table {
                tr {
                    th,
                    td {
                        &:first-child {
                            padding-left: 2em;
                        }

                        &:last-child {
                            padding-right: 2em;
                        }
                    }
                }

                &.table {
                    > thead {
                        > tr {
                            th {
                                background-color: $white;
                                border-bottom: 1px solid $silver;
                            }
                        }
                    }
                }
            }

            td,
            th {
                text-align: left;
            }

            .btn {
                &-show-funds {
                    margin-top: 15px;
                }
            }

            .notifications-container {
                margin-top: 10px;
                display: inline-block;
            }
        }
    }

    .must-total {
        font-weight: 400;
        display: block;
    }

    .percent-out {
        position: relative;

        .invalid-fund-allocation-message {
            right: 66px;
        }
    }

    .back-to-policy {
        margin-left: 15px;
    }
}
