.policy-loan-balance {
    margin-top: 20px;

    @include bp(medium) {
        margin-top: 5px;
    }

    .label-loan-balance {
        color: $cement;
        padding: 5px 0;
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;

        span {
            color: $chalkboard-dark;
        }
    }

    .icon.icon-loan {
        color: $kelly;
        font-size: 24px;
        line-height: 24px;
        vertical-align: bottom;
    }
}

body.policy-alerts,
body.alert-by-policy-detail,
body.policy-requirements {
    .relatives-container,
    .row-colors,
    .policy-details {
        display: none;
    }
}

.column-content {
    font-size: 16px;
    line-height: 20px;
    color: $chalkboard-dark;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    margin: 0;
    padding: 0;

    @include bp(medium) {
        font-size: 14px;
    }

    &.external-link {
        color: $bright-link;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.summary-container {
    .welis-mobile {
        margin-top: 7px;
    }

    .agent-client-view-switch-section {
        background: $kelly-dark;
        line-height: 100%;
        padding: 10px 0;
        height: 40px;
        overflow: hidden;

        @include bp(medium) {
            padding: 8px 0;
            height: 30px;
        }

        a {
            color: $white;
            display: inline-block;
            vertical-align: bottom;
            font-size: 12px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: 1.8px;
            line-height: 22px;
            opacity: .4;
            margin-right: 14px;
            text-decoration: none;

            @include bp(medium) {
                font-size: 10px;
                line-height: 14px;
            }

            &:hover,
            &.active {
                opacity: 1;
            }

            &.disable {
                cursor: default;

                &:hover {
                    opacity: .4;
                }
            }
        }

        &.annuity {
            background: $sky-dark;

            a {
                color: $sky-dark-text;
                opacity: 1;

                &:hover,
                &.active {
                    color: $white;
                }
            }
        }

        .roles-links {
            a {
                opacity: 1;
            }
        }
    }

    .popup-container {
        min-height: 0;
        bottom: 0;
        overflow: hidden;
        overflow-y: auto;

        &.payments-container,
        &.transactions-history-container,
        &.print-container,
        &.values-container {
            overflow: auto;
            bottom: auto;
            min-height: 100%;
        }

        &.print-container {
            .print-header {
                margin-top: 30px;
            }

            .policy-print-content {
                position: relative;
                overflow: hidden;
            }

            button {
                &.print {
                    top: 85px;
                }
            }

            .table {
                > tbody {
                    > tr {
                        background-color: transparent;

                        > td {
                            padding: 0;
                        }
                    }
                }
            }

            .policy-print-sections {
                padding: 0 25px;
            }
        }
    }

    .transaction-history {
        position: relative;

        .transaction-history-details {
            .general-table-structure {
                min-width: 0;
            }
        }

        .policy-transaction-history-modal {
            &.open {
                display: block;
            }

            .modal-dialog {
                width: auto;

                .modal-header {
                    .close {
                        color: $cement-medium;
                        opacity: 1;
                        font-size: 20px;
                        line-height: 22px;
                        font-weight: 400;
                        outline: 0;
                        position: absolute;
                        right: 20px;
                        top: 24px;
                    }
                }

                .transaction-history {
                    padding: 0 0 20px;
                }

                .transaction-history-details {
                    overflow: hidden;
                    height: 550px;
                }
            }
        }

        .transaction-history-summary {
            margin-bottom: 30px;
        }

        .spinner-container {
            position: relative;
            height: 50px;
            width: 100%;
            display: block;

            .spinner {
                &.spinner-small {
                    bottom: auto;
                    top: 50%;
                }
            }
        }

        .transaction-history-mobile-view {
            & > [class*='col-xs'] {
                margin-bottom: 20px;
            }
        }
    }

    .inforce-info-only {
        display: none;
    }

    .requirements-widget {
        line-height: 20px;
        cursor: pointer;

        .req-text {
            margin-right: 0;
            display: inline-block;
            cursor: pointer;
        }
    }

    .label-dividend {
        white-space: normal;
        line-height: 18px;
    }

    .padding-top {
        padding-top: 25px;
    }

    hr {
        margin-bottom: 30px;
        margin-top: 7px;

        @include bp(medium) {
            margin-bottom: 25px;

            &.no-margin {
                margin-bottom: 0;
                margin-top: 0;
            }
        }

        &.medium-space {
            margin-top: 25px;
        }

        &.small-space {
            margin-top: 6px;
        }
    }

    p {
        &.label {
            display: block;
        }
    }

    span {
        &.label {
            display: inline-block;
        }
    }

    .column-name {
        font-size: 14px;
        color: $cement-medium;
    }

    .print-link {
        margin-top: 10px;
        cursor: pointer;
        color: $chalkboard-dark;
        font-weight: 400;

        &:focus {
            text-decoration: none;
        }

        .icon {
            font-size: 22px;
            display: inline-block;
            margin-right: -10px;
        }

        span {
            font-size: 15px;
            vertical-align: middle;
            margin-left: 5px;
        }
    }

    .print-toggle {
        .btn-link {
            color: $chalkboard-dark;
            font-weight: 400;
            width: 100%;
            text-align: left;

            &:focus {
                text-decoration: none;
            }
        }
    }

    .label {
        font-weight: normal;
        margin: 0;
        padding: 0;
        text-align: left;

        &.list-names-item {
            margin-bottom: 6px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .icon {
            &.icon-eye {
                color: $cement;
                font-size: 24px;
                cursor: pointer;

                &:hover {
                    color: $chalkboard;
                }
            }
        }

        &.section-title {
            font-size: 20px;
            color: $black;
            font-weight: 600;
            margin-bottom: 30px;
            width: 100%;
            text-align: left;

            &.mobile-section-title {
                width: auto;
            }

            @include bp(medium) {
                color: $chalkboard-dark;
                margin-bottom: 15px;
                font-weight: normal;
                width: auto;
                text-align: left;
            }

            &.main-label {
                margin-bottom: 26px;
            }
        }

        &.label22 {
            font-size: 22px;
            color: $sky;
        }

        &.label36 {
            font-size: 36px;
            color: $sky;
        }
    }

    .policy-details {
        .section {
            margin-top: 15px;
            border-top: none;

            &.payment-section {
                padding-top: 0;

                @include bp(medium) {
                    padding-top: 25px;
                }
            }

            .section-title {
                width: auto;
            }
        }

        .footnote {
            margin-bottom: 4px;
            margin-top: 0;
            line-height: 16px;

            p {
                span {
                    color: $chalkboard-dark;
                }
            }
        }

        .label-variable-account {
            color: $cement;
            font-size: 12px;
            white-space: normal;
            margin-top: 60px;
        }

        .beneficiaries {
            .label {
                &.column-content {
                    display: block;
                    margin: 0;
                }
            }
        }

        .requirements-widget {
            float: left;
            margin-right: 26px;
        }

        .alerts {
            text-decoration: none;
            cursor: pointer;
            line-height: 22px;
            height: 22px;
            color: $sky;
            float: left;
        }
    }

    label {
        color: $cement-medium;

        &.policy-funds-label {
            color: $chalkboard-dark;
        }
    }

    .wg-client-payment,
    .wg-conversion-credits {
        label {
            color: $chalkboard-dark;
        }
    }

    .conversion-link {
        position: absolute;
        right: 0;
        top: 20px;
        @include bp($policy-summary-custom-bp) {
            position: relative;
            top: inherit;
        }

        .icon {
            font-size: 10px;
            font-weight: bold;
            padding-left: 2px;
        }
    }

    .policy-funds-transfer {
        .form-group {
            label {
                color: $chalkboard-dark;
                font-weight: normal;
            }
        }
    }

    .star-master-logo {
        display: inline-block;
        margin-left: 10px;

        &.star-logo {
            img {
                width: 90px;
                margin-top: -12px;
            }
        }

        &.master-master-pro {
            img {
                width: 135px;
                margin-top: -6px;
            }
        }

        &.master,
        &.master-pro {
            img {
                width: 80px;
                margin-top: -6px;
            }
        }
    }
}

.pua-paragraph {
    background-color: $natural-medium;
    padding: 15px 20px;
    line-height: 1.5em;
}

.pua-row {
    margin-top: 8px;

    &:first-of-type {
        margin-top: 25px;
    }
}

.blue-circle {
    position: absolute;
    left: 5px;
    top: 7px;
    background-color: $sea-foam;
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 3px;
}

.lapse {
    &-note {
        color: $cherry;
    }
}

.premium {
    &-container {
        position: relative;

        &.ng-enter,
        &.ng-leave {
            transition: all .25s ease;
        }

        &.ng-enter,
        &.ng-leave.ng-leave-active {
            opacity: 0;
            top: 30px;
        }

        &.ng-leave.ng-leave-active {
            position: absolute;

            .widgets-container {
                width: 555px;
            }
        }

        &.ng-leave,
        &.ng-enter.ng-enter-active {
            opacity: 1;
            top: 0;
        }

        .loan,
        .pua,
        .pua-loan {
            &-payment {
                margin-bottom: 7px;
                background-color: $natural-medium;
                padding: 15px;

                .btn {
                    float: right;
                    @include bp(smaller) {
                        float: none;
                        display: block;
                        margin: 10px 0;
                    }
                }

                label {
                    vertical-align: sub;
                    color: $chalkboard-medium;
                }
            }
        }

        .stand-alone-payment-row {
            padding: 0 7px;
        }
    }
}

.policy-print-container {
    label {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 1px;
    }

    p {
        margin-top: 0;
    }

    .print {
        &-header {
            border-bottom: 1px solid $silver;

            p {
                font-size: 22px;
            }

            img {
                margin-bottom: 17px;
                width: 287px;
            }

            .policy-info {
                margin-top: 12px;
                font-weight: bold;
            }
        }

        &-subheader {
            padding-top: 15px;
        }
    }

    .mailing-address {
        margin-top: 10px;
        margin-bottom: 60px;

        &.redacted {
            margin-top: 0;
        }

        p {
            margin-bottom: 0;
            text-transform: capitalize;

            &.full-name,
            &.address-line-primary,
            &.address-line-secondary,
            &.address-city-state-zip {
                font-size: 18px;
                margin-bottom: 3px;
            }
        }
    }

    .policy-values,
    .policy-summary,
    .billing,
    .beneficiary,
    .contact,
    .conversion,
    .assignee,
    .ul-billing,
    .policy-information,
    .riders-benefits,
    .sub-accounts,
    .loans,
    .policy-adviser,
    .policy-agents,
    .pua,
    .payment-information,
    .annuity-values-charges,
    .transaction-history,
    .surrender-charges,
    .investment-options,
    .values,
    .accounts,
    .account-details,
    .payment-confirmation,
    .insured {
        &-section {
            h3,
            h2 {
                border-bottom: 1px solid $silver;
                font-weight: 400;
                font-size: 19px;
                padding-bottom: 13px;
                clear: both;
            }
        }
    }

    .policy-values,
    .billing,
    .conversion,
    .policy-information,
    .loans,
    .sub-accounts,
    .riders-benefits,
    .policy-agents,
    .pua,
    .payment-information,
    .annuity-values-charges,
    .transaction-history,
    .payment-confirmation,
    .contact {
        &-section {
            .table {
                > tbody {
                    > tr {
                        border-bottom: 1px solid $silver;

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }

    .billing {
        &-section {
            .ul-billing-item {
                &:nth-of-type(4n+1) {
                    padding-left: 0;
                    margin-right: -8px;
                }
            }
        }
    }

    .policy-summary,
    .beneficiary,
    .contact,
    .insured {
        &-section {
            text-transform: capitalize;
        }
    }

    .beneficiary {
        &-section {
            p {
                text-transform: none;
            }
        }
    }

    .contact {
        &-item {
            border-right: 1px solid $silver;
            padding-left: 15px;
            margin-bottom: 10px;

            &:nth-of-type(3n+1) {
                padding-left: 0;
            }

            &:nth-of-type(3n+3) {
                border: none;
            }
        }

        &-section {
            .table {
                span {
                    display: block;
                }
            }
        }
    }

    .secondary-heading-row {
        th {
            background-color: transparent;
            border: none;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: 1px;
            color: $cement-medium;
            padding: 0;
        }
    }

    riders-component {
        &.print-layout {
            .field-container {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }
}

.form {
    &-widget-container {
        background-color: $natural-medium;
        padding: 20px 5px;
        position: relative;
        top: 40px;
        margin-bottom: 75px;

        .select-wrapper {
            background-color: $white;
        }

        .form {
            &-control:not(select) {
                background-color: $white;
            }

            &-instruction {
                margin-bottom: 10px;
            }

            &-group {
                position: relative;
            }
        }

        .btn {
            margin-right: 10px;
        }

        .close {
            position: absolute;
            font-size: 18px;
            color: $cement-medium;
            top: 4px;
            right: 12px;
            opacity: 1;
            outline: none;
        }
    }
}

.wg-client-payment,
.wg-conversion-credits {
    &-container {
        &.ng-enter,
        &.ng-leave {
            transition: all .25s ease;
        }

        &.ng-enter {
            transition-delay: .25s;
        }

        &.ng-enter,
        &.ng-leave.ng-leave-active {
            opacity: 0;
        }

        &.ng-leave.ng-leave-active {
            height: 0;
        }

        &.ng-leave,
        &.ng-enter.ng-enter-active {
            opacity: 1;
        }
    }
}

.wg-conversion-credits-container {
    &.ng-enter {
        transition-delay: 0s;
    }
}

.wg-client-payment-container {
    &.ng-enter.ng-enter-active {
        // Obnoxious but needed in order to prevent the policy information section
        // from flashing to the top during the initial transition.
        // There could be a better solution however.
        min-height: 750px;
    }
}

.manage-funds-options {
    $manage-funds-option-height: 4em;
    $manage-funds-option-pad: $manage-funds-option-height / 5;
    background-color: $natural-medium;
    list-style-type: none;
    margin-bottom: 32px;
    padding: 0;

    li {
        border-bottom: 1px solid $natural-dark;
        display: flex;
        min-height: $manage-funds-option-height;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 $manage-funds-option-pad;

        button {
            margin-top: 1.125em;
        }

        label {
            color: $chalkboard-medium;
            line-height: $manage-funds-option-height;
            min-width: 50%;
            @include bp(xsmall) {
                line-height: $manage-funds-option-height / 1.25;
                width: 100%;
            }
        }

        &:last-child {
            border-bottom: none;
        }
    }

    .transfer-message {
        margin-top: 0;
    }
}

.issue-state-restriction-error {
    padding: 35px 0;

    p {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 50px;
    }
}
