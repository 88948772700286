.club-credits {
    .jumbotron {
        padding: 0;

        img {
            width: 100%;
            height: auto;
        }
    }

    .credit-sections {
        display: flex;
        flex-wrap: wrap;
    }

    .leaders,
    .chairmans,
    .star,
    .bonus,
    .life-sales-leader {
        &-section {
            display: flex;
            flex-direction: column;

            .panel {
                border: 1px solid $natural-dark;

                &-heading {
                    background-color: $natural-medium;
                }

                &-body {
                    padding: 15px;

                    .header-summary {
                        p {
                            margin: 0 0 5px;
                            font-size: 17px;
                            font-weight: bold;
                        }
                    }
                }

                &-section {
                    overflow: hidden;

                    h2 {
                        text-align: center;
                        font-size: 17px;
                    }

                    .bar-graphs {
                        p {
                            margin-bottom: 0;
                        }
                    }
                }

                &-footer {
                    background-color: transparent;
                    padding: 0;
                    border: 0;

                    p {
                        font-size: 12px;
                        line-height: 14px;
                        font-weight: 600;
                    }
                }

                .table {
                    &.condensed {
                        tfoot {
                            font-size: 13px;
                            font-weight: 700;

                            td {
                                padding: 5px 4px;
                            }
                        }
                    }

                    &-striped {
                        > thead {
                            font-weight: bold;
                        }

                        > tbody {
                            > tr {
                                &:nth-of-type(odd) {
                                    background-color: $natural-medium;
                                }
                            }
                        }

                        &.qualifications {
                            width: 75%;
                            margin: 0 auto;

                            > thead {
                                font-weight: bold;
                            }
                        }
                    }
                }
            }

            h1 {
                text-transform: uppercase;
                font-size: 24px;
                text-align: center;
                margin: 0;
            }
        }
    }

    .bonus-section {
        table {
            width: 100%;

            th {
                text-align: center;
            }

            td:last-of-type {
                padding-left: 2em;
                text-align: right;
            }

            // tr:last-of-type {
            //     td:last-of-type {
            //         text-align: center;
            //         padding-left: inherit;
            //     }
            // }
        }
    }

    .star-section {
        .panel {
            margin-bottom: 0;
        }

        .panel-section {
            h2 {
                font-size: 12px;
                margin: 0;
                background: $natural-dark;
                padding: 11px 0;
                height: 60px;
                line-height: 14px;
            }

            .star-item {
                text-align: center;
                min-height: 100px;
                margin-bottom: 15px;
            }

            p {
                background: $natural-medium;
                padding: 11px 0;
                margin: 0;

                &.star-status {
                    padding: 0;
                    background: transparent;
                }
            }
        }
    }

    .footer-links {
        p {
            margin: 0;
        }
    }

    .no-credits {
        h1 {
            font-weight: normal;
            font-size: 20px;
            margin: 100px 0;
        }
    }

    .row {
        &.credit-section {
            display: table;
            width: 100%;
        }
    }
}