wg-error {
    display: block;
}

.error-container,
.info-container {
    text-align: center;
    margin-top: 40px;
    padding: 10px 0;
    margin-bottom: 60px;

    .icon-warning {
        font-size: 30px;
        color: $sky;
        line-height: 30px;
        margin-bottom: 7px;
    }

    h3,
    .message {
        font-weight: 400;
    }

    h3 {
        padding: 10px 15px 15px;
        line-height: normal;
        margin: 0;
        padding-bottom: 0;
        font-size: 20px;
        font-weight: bold;
    }

    .message {
        padding: 12px 15px 15px;
        color: $cement-medium;
        font-size: 16px;
        line-height: 16px;
    }

    .btn-back {
        color: $white;
        font-size: 15px;
        line-height: 40px;
        margin: 15px auto 0;
        text-align: center;
        padding: 0;
        width: 130px;
        height: 40px;
    }
}

.forbidden-page {
    .error-container {
        margin-top: 0;

        .message {
            max-width: 608px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
