.search {
    .section-list {
        .title {
            font-size: 20px;
            line-height: 20px;
            font-weight: 600;
            font-family: 'Open Sans', sans-serif;
            padding-bottom: 28px;
            padding-top: 28px;
            margin-bottom: 0;

            @include bp(medium) {
                padding-bottom: 30px;
                padding-top: 38px;
                margin-bottom: 10px;
                font-size: 24px;

                &.with-message {
                    padding-bottom: 0;
                }

                &.no-result {
                    padding-top: 25px;
                }
            }
        }
    }

    .list-view-filter {
        margin-bottom: 30px;
        margin-top: -10px;
        color: $cement-medium;
        text-align: center;

        a {
            color: $cement-medium;
            cursor: pointer;
            margin: 0 6px;

            &:hover {
                text-decoration: none;
            }

            &.active {
                color: $chalkboard-dark;
            }

            &.active,
            &:hover {
                padding-bottom: 2px;
                border-bottom: solid $bright-link 3px;
            }
        }

        .separator-vertical-line {
            background-color: $silver-medium;
        }
    }

    .no-recently-viewed-state {
        text-align: center;
        margin-top: 95px;

        .recently-title {
            font-size: 22px;
            line-height: 30px;
            margin-top: 26px;
        }

        .section-description {
            font-size: 16px;
            color: $cement-medium;
            width: 288px;
            margin: 16px auto 0;
        }

        .icon-recently-viewed {
            color: $sky;
            font-size: 34px;
        }
    }

    .title {
        &.no-result {
            color: $white;
            background-color: $sea-foam;
            padding-bottom: 26px;
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 0;
            line-height: 24px;
            font-weight: 300;
        }
    }

    .search-results-container {
        background-color: $white;
    }

    @include bp(medium) {
        .favorites-container {
            .favorite-item {
                margin-bottom: 15px;
            }
        }
    }
}

// .policy-box {
//     display: none !important; // Needed to trick the browser into thinking the policy blocks are NOT ads
// }

.legacy-nav {
    .search-header-container {
        .page-title {
            font-family: 'Open Sans', sans-serif;
            font-weight: 300;
            font-size: 32px;
            line-height: 32px;
        }

        .search-sub-menu {
            .menu-text {
                text-decoration: none;
                font-size: 16px;
                font-weight: 500;
                margin: 0 10px;
                text-transform: none;
                font-family: 'Open Sans', sans-serif;
                letter-spacing: inherit;

                .search-status-total {
                    font-size: 17px;
                    font-weight: bold;
                    font-family: 'Open Sans', sans-serif;
                    margin-top: 2px;
                }

                .search-status {
                    font-family: 'Open Sans', sans-serif;
                    font-size: 16px;
                    text-transform: none;
                    font-weight: 500;
                    letter-spacing: normal;
                    text-transform: none;
                }

                &.active,
                &:hover,
                &:focus {
                    opacity: 1;
                    font-weight: 500;
                    font-family: 'Open Sans', sans-serif;
                    font-size: 16px;
                    letter-spacing: normal;
                }
            }
        }
    }
}

.search-header-container {
    width: 100%;

    @include bp(medium) {
        padding: 0;
    }

    .section-top-info {
        padding-top: 22px;
        padding-bottom: 22px;

        @include bp(medium) {
            padding-top: 100px;
            padding-bottom: 35px;
        }
    }

    .section-bottom {
        box-shadow: 0 2px 4px 0 $shadow;

        @include bp(medium) {
            height: 60px;
        }
    }

    .page-title {
        color: $white;
        margin-top: 0;
        margin-bottom: 35px;
        font-family: 'TiemposHeadline';
        font-size: 38px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 43px;
    }

    .submenu-text {
        color: $cement-medium;
        margin: 0 10px;
        height: 61px;
        padding: 20px 0;
        display: inline-block;
        cursor: pointer;

        &.active {
            color: $chalkboard-dark;
            border-bottom: solid 3px $bright-link;
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    .search-sub-menu {
        margin-top: 22px;

        a {
            &:active,
            &:hover,
            &:focus {
                opacity: 1;
                text-decoration: none;
            }
        }

        .menu-text {
            color: $white;
            font-size: 16px;
            vertical-align: top;
            margin: 0 15px;
            opacity: .5;
            display: inline-block;
            font-family: 'Pragmatica';
            font-size: 12.5px;
            font-weight: 300;
            letter-spacing: 1px;
            line-height: 18px;
            text-transform: uppercase;
            @include bp(small) {
                margin-top: 10px;
            }

            &.active,
            &:hover,
            &:focus {
                opacity: 1;
                text-decoration: none;
                font-family: 'Pragmatica';
                font-size: 12.5px;
                font-weight: 300;
                letter-spacing: 1px;
                line-height: 18px;
            }

            .search {
                &-status,
                &-status-total {
                    display: block;
                }

                &-status {
                    margin-bottom: -4px;
                    font-family: 'Pragmatica';
                    font-size: 12.5px;
                    font-weight: 300;
                    letter-spacing: 1px;
                    line-height: 18px;
                }

                &-status-total {
                    font-family: 'TiemposHeadline';
                    font-size: 26px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 30px;
                    margin-top: 10px;
                }
            }
        }

        .separator-vertical-line {
            margin: 1px 8px;
        }
    }

    .form-group {
        position: relative;
        max-width: 600px;
        min-width: 400px;
        margin: 0 auto;
        width: 100%;
        overflow: hidden;

        .icon {
            position: absolute;
            left: 20px;
            top: 17px;
            width: 18px;
            height: 18px;
            z-index: 1;
        }

        .icon.icon-search {
            color: $chalkboard-dark;
            top: 20px;
            font-size: 22px;
            line-height: 24px;
            font-weight: 600;
        }

        &.mobile-version {
            min-width: inherit;
            max-width: inherit;

            .icon {
                top: 18px;
                left: 15px;
                font-size: 20px;
                line-height: 11px;
                z-index: 1000;
            }

            .search-criteria {
                height: 45px;
                line-height: 45px;
                font-size: 14px;
                padding-left: 52px;
                border-radius: 5px;
                color: $chalkboard-dark;
                font-weight: 400;
                -webkit-transition: width linear .2s;
                transition: width linear .2s;
                -webkit-transition-delay: .2s; /* Safari */
                transition-delay: .2s;
                position: relative;
                z-index: 999;
                padding-right: 20px;
            }

            .editing {
                -webkit-transition-delay: 0s; /* Safari */
                transition-delay: 0s;
                width: calc(100% - 65px);
            }

            .search-btn {
                width: 65px;
                height: 45px;
                color: $sea-foam;
                font-size: 16px;
                background-color: transparent;
                padding: 0;
                text-align: right;
                opacity: 0;
                -webkit-transition: opacity linear .2s;
                transition: opacity linear .2s;
                -webkit-transition-delay: 0s; /* Safari */
                transition-delay: 0s;

                &:active {
                    box-shadow: none;
                }

                &::-moz-focus-inner {
                    box-shadow: none;
                }
            }

            .editing {
                -webkit-transition-delay: .2s; /* Safari */
                transition-delay: .2s;
                opacity: 1;
            }
        }
    }

    .search-criteria {
        height: 60px;
        padding-left: 56px;
        padding-right: 130px;
        font-size: 14px;
        width: 100%;
        border: 0;
        background-color: $white;
        color: $chalkboard-dark;

        &.custom-placeholder {
            &::-moz-placeholder {
                color: $cement-medium;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: $cement-medium;
            }
            // Internet Explorer 10+
            &::-webkit-input-placeholder {
                color: $cement-medium;
            }
        }
        // Safari and Chrome
        &:focus {
            outline: none;
        }
    }

    form.dark input[type='text']:focus {
        border: 0;
    }

    .search-btn {
        font-size: 14px;
        width: 110px;
        position: absolute;
        right: 0;
        height: 60px;
        top: 0;
    }
}

.filter-section-dropdown {
    @include bp(medium) {
        float: left;
        text-align: left;
    }

    .small-icon {
        font-size: 10px;
        font-weight: bold;
        display: inline-block;
        margin-left: 5px;
        @include transition(all .15s ease);
    }

    &.open {
        .small-icon {
            @include transform(rotate(180deg));
        }
    }
}

.filter-section {
    padding: 19px 0 18px;

    @include bp(medium) {
        padding: 40px 0 20px;
    }
}

.filter-label-text {
    color: $cement-medium;
}

.client-list {
    &-container {
        margin-top: 50px;
    }
}

@media (max-width: 374px) {
    .search {
        .search-header-container {
            .search-sub-menu {
                .menu-text {
                    font-size: 14px;
                }

                &.menu-text-small {
                    .menu-text {
                        font-size: 12.5px;
                    }
                }
            }

            .form-group {
                &.mobile-version {
                    &.icon-search {
                        left: 12px;
                        top: 13px;
                    }

                    .search-criteria {
                        padding-left: 40px;
                        padding-right: 12px;
                    }
                }
            }
        }
    }
}
