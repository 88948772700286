// .modal {
//     &.credit-card-form {
//         .modal-dialog {
//             &.modal-lg {
//                 width: 54em;
//             }
//         }
//     }
// }

credit-card-form {
    color: black;
    margin: .5em;
    font-family: inherit;
    font-size: 14px;
    // height: 400em;
    display: flex;
    flex-direction: column;

    iframe {
        height: 70em;
        width: 100%;
    }

    .scroll-container {
        overflow: scroll;
        height: 10em;
        font-size: 1em;
        font-weight: normal;
    }

    .buttons-justified {
        display: flex;
        flex-direction: column;
        width: 40%;
        margin: 1em;
    }

    .buttons-justified button {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .debugger {
        display: flex;
    }

    .debug-log {
        width: 60%;
        overflow: scroll;
    }

    .debug-log ul {
        list-style: square;
        line-height: 1.6em;
        /* padding: 0; */
    }

    .popup {
        &-container {
            background-color: transparent;
        }
    }

    .cc-iframe-container {
        border-radius: 5px;
    }
}