riders-component {
    border-top: 1px solid $silver;
    display: block;

    &:first-of-type {
        border: 0;
    }

    .section {
        &:not(:first-of-type) {
            border: 0;
        }
    }
}

.policy-print-container {
    .riders-benefits-section {
        riders-component {
            border-top: 0;

            h2,
            h3 {
                font-weight: 400;
                text-align: left;
                color: $chalkboard-dark;
                margin-bottom: revert;
            }

            .section {
                &.cms {
                    .field {
                        height: 50px;
                    }
                }
            }
        }
    }
}