.epay {
    .btn {
        &-group {
            float: right;
            @include bp($policy-summary-custom-bp) { // Needs a proper breakpoint and not section specific.
                float: left;
                clear: left;
            }

            .btn {
                margin-left: 13px;

                &:first-child {
                    @include bp($policy-summary-custom-bp) { // Needs a proper breakpoint and not section specific.
                        margin-left: 0;
                    }
                }
            }
        }
    }

    &-confirmation {
        h2 {
            font-size: 1.25em;
            margin-bottom: 0;
        }

        &-messaging {
            p {
                margin-top: 5px;
                font-size: 18px;
                font-weight: 100;
            }
        }

        .download-policy-pages-container button {
            float: right;
        }

        .error {
            &-messaging {
                margin-top: 50px;

                .icon {
                    &-close {
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                        background-color: $cherry;
                        color: $white;
                        border-radius: 50%;
                        text-align: center;
                        font-weight: bold;
                        margin-right: 5px;
                    }
                }
            }

            &-heading {
                color: $black;
            }

            &-note {
                display: inline-block;
                margin-bottom: 0;
            }

            &-description {
                margin-top: 0;
            }

            &-note,
            &-description {
                color: $cherry;
            }

            &-contact {
                font-size: 12px;
                color: $cement-medium;
            }
        }

        .table {
            margin-bottom: 5px;

            .label {
                color: $black;
                font-weight: bold;
                display: block;
                font-size: 14px;
            }
        }

        .email-note {
            color: $chalkboard-dark;
            display: inline-block;
            margin-bottom: 15px;
        }

        .confirmation-note {
            display: block;
            width: 75%;
            float: right;

            @include bp(small) {
                float: left;
                margin-top: 20px;
            }
        }

        .icon {
            &-close {
                display: inline-block;
                @include bp(small) { // This should be using a variable.  This is only a workaround until the breakpoint mixins are fixed.
                    display: none;
                }
            }
        }
    }

    label {
        display: block;
        color: $black;
        font-weight: 600;
    }

    &.failure {
        .btn {
            &-group {
                float: none;
                margin-top: 25px;
            }
        }
    }

    &-footer {
        border-bottom: 1px solid $silver;
        margin-top: 15px;
    }

    &-review {
        &-disclaimer {
            display: block;
            text-align: right;
            font-size: 80%;
            margin: 5px 0;
            @include bp($policy-summary-custom-bp) {
                text-align: left;
            }
        }
    }

    .form-control {
        &[disabled] {
            background-color: transparent;
            border: 0;
            padding: 0;

            &:hover {
                cursor: default;
            }
        }
    }

    &-account-nickname {
        &.ng-enter,
        &.ng-leave {
            @include transition(all .2s ease);
        }

        &.ng-enter,
        &.ng-leave.ng-leave-active {
            opacity: 0;
        }

        &.ng-leave,
        &.ng-enter.ng-enter-active {
            opacity: 1;
        }

        &-wrap {
            @include bp($medium) {
                clear: left;
            }
        }
    }

    .progress-container {
        margin-bottom: 25px;
    }

    &-form-section {
        .row {
            padding-top: 15px;
        }
    }

    .saved-accounts {
        strong {
            display: inline-block;
            margin: 15px 0;
        }
    }

    wg-checkbox {
        display: block;
        margin-bottom: 15px;
    }

    &-review {
        .section-title {
            border-bottom: 1px solid $silver;
            font-weight: 400;
            font-size: 19px;
            padding-bottom: 13px;
            clear: both;
        }

        .table {
            > tbody {
                > tr {
                    > td {
                        padding-left: 0;
                        padding-bottom: 0;
                    }
                }
            }
        }
    }

    wg-client-payment-accounts {
        width: 265px;
    }
}

.ep {
    &-total {
        text-align: right;
        font-weight: 600;
        font-size: 18px;
        @include bp(969px) { // This should be using a variable.  This is only a workaround until the breakpoint mixins are fixed.
            text-align: left;
        }
    }

    &-checkbox {
        margin-top: 5px;
        float: left;

        .opt-in-label {
            font-weight: normal;
            font-size: 11px;
            padding-left: 10px;
        }

        .wg-checkbox {
            margin-right: 5px;
            display: inline-block;

            &-inner {
                width: 15px;
                height: 15px;
                vertical-align: middle;

                &.checked {
                    font-size: 12px;
                    line-height: 15px;
                }
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    &-next-step,
    &-effective-issue-message {
        float: right;
        clear: right;
        @include bp($policy-summary-custom-bp) {
            float: left;
            clear: left;
        }
    }

    &-next-step {
        margin-bottom: 0;
    }

    &-step-container {
        border-top: 2px solid $silver;
        position: relative;
        height: 47px;
        margin-top: 47px;
    }

    &-step {
        position: absolute;
        color: $white;
        background-color: $silver;
        width: 47px;
        height: 47px;
        border: 4px solid $silver;
        border-radius: 50%;
        bottom: 50%;
        font-size: 20px;
        font-weight: bold;
        line-height: 38px;
        text-align: center;
        right: 29%;
        @include transition(all .25s ease);

        &:first-child {
            left: 29%;
        }

        &.active {
            background-color: $sky;

            &:hover {
                cursor: pointer;
                @include box-shadow(inset 0 0 6px darken($sky, 15%));
            }
        }
    }

    &-summary {
        p {
            margin: 0;
        }
    }

    &-status-label {
        color: $silver-dark;

        span {
            color: $black;
        }
    }

    &-payment {
        &-section {
            border-top: 1px solid $silver;

            .form-group {
                @include bp($medium) {
                    // padding-left: 0;
                }
            }

            &.ep-summary {
                border-top: 0;
            }

            &.payment-options,
            &.penn-check-section {
                padding-top: 15px;
            }
        }

        &-summary {
            .form-group {
                overflow: hidden;

                .label {
                    clear: left;
                    color: $chalkboard-dark;
                }

                .premium,
                .pua,
                .conversion,
                .total {
                    &-amount {
                        height: 55px;

                        * {
                            height: 55px;
                        }

                        p {
                            line-height: 55px;
                        }
                    }
                }

                &.totals {
                    border-top: 1px solid $silver;

                    .table {
                        margin-top: 15px;
                        width: 40%;
                        float: right;
                        @include bp($small) {
                            width: 100%;
                        }

                        > tbody {
                            > tr {
                                &.payment-total {
                                    td {
                                        font-weight: bold;
                                        font-size: 16px;
                                    }
                                }

                                > td {
                                    padding: 5px 8px;
                                    vertical-align: inherit;
                                }
                            }
                        }

                        td {
                            &.value {
                                text-align: right;

                                input {
                                    width: 60%;
                                    text-align: right;
                                    float: right;
                                    @include bp($medium) {
                                        width: 80%;
                                    }
                                }
                            }
                        }
                    }

                    .conversion-addition-note {
                        float: right;
                        clear: right;
                    }
                }

                .total-amount {
                    font-weight: bold;
                    font-size: 16px;
                }
            }
        }
    }
}

.epay-disabled-message {
    display: flex;
    justify-content: center;
    font-size: 1.2em;
    padding: .5em;
    margin-top: 1em;
}