.logo {
    width: 40px;
    height: 40px;
    fill: $white;
}

body {
    background-color: $natural;

    &.login-advisor {
        background: $white url('../images/login-bg.jpg') no-repeat center;
        background-size: cover;
    }
}

.login {
    width: 100%;
    height: calc(100% - 30px);
    min-height: inherit;
    display: table;

    .conn-status {
        display: table-row;
        height: 30px;
    }

    .copyright {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
    }

    .main-view-container {
        padding-top: 0;
    }

    .main-container {
        display: table;
        width: 100%;
        vertical-align: middle;
        margin: 0 auto;
        padding-top: 50px;

        .notifications {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
        }

        .login-form-container {
            padding: 0 37px;

            .login-content {
                margin: 0 auto;
                border-top: solid 5px $sky;
                float: none;
                padding-left: 0;
                padding-right: 0;
                background-color: $white;

                .logo {
                    display: inline-block;
                    width: 280px;
                    height: 95px;
                    fill: $white;
                    margin-bottom: 36px;

                    img {
                        width: 389px;
                        height: auto;
                    }

                    &.client-login {
                        padding-top: 50px;

                        img {
                            width: 100%;
                        }
                    }
                }

                .page-title {
                    font-family: 'Open Sans', sans-serif;
                    font-weight: 400;
                    color: $white;
                    font-size: 36px;
                    line-height: 36px;
                    margin: 0;
                }

                .login-info-message {
                    font-size: 16px;
                    color: $white;
                    text-align: center;
                    margin: 10px 0 35px;
                    font-weight: 300;
                }

                .submit-group {
                    .btn-success,
                    .btn-info {
                        font-size: 18px;
                        margin: 0 auto;
                        padding-top: 12px;
                        border-radius: 4px;
                        font-weight: normal;
                    }

                    .btn-success {
                        border-bottom: 2px solid $kelly-medium;
                    }

                    .btn-info {
                        border-bottom: 2px solid $sky-medium;
                        margin: 20px auto 0;
                    }
                }

                .title {
                    font-size: 32px;
                    line-height: 43px;
                    padding: 15px 0 30px;
                    display: block;
                }

                .error {
                    color: $alert-danger-text;
                    display: block;
                    margin-bottom: 15px;
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }

        .login-body {
            border-bottom: 1px solid $natural-dark;
            border-left: 1px solid $natural-dark;
            border-right: 1px solid $natural-dark;
        }

        .account-support {
            padding-bottom: 20px;
        }

        .forgot-list {
            li {
                &:first-child {
                    border-right: solid 1px $silver-dark;
                    padding-right: 10px;
                }
            }
        }
    }

    .main-container {
        @include bp(xsmall) {
            min-height: inherit;
        }
    }

    .copyright {
        position: relative;
        display: table;
    }

    &.login-advisor {
        .main-container {
            display: table-cell;
            padding-top: 0%;

            .login-form {
                &-container {
                    .login-content {
                        background-color: transparent;
                        border-top: 0;

                        .submit-group {
                            .btn-success {
                                width: 190px;
                                height: 50px;
                            }
                        }

                        .logo {
                            width: 389px;
                            @include bp(small) {
                                width: 280px;
                                height: 95px;
                            }
                        }
                    }
                }
            }
        }

        .copyright {
            position: fixed;
        }
    }
}

/* Intermediate devices (large phones, 480px and up) */
@media (min-width: 480px) {
    .login {
        .main-container {
            padding-top: 100px;
        }
    }
}

/* Small devices (tablets, 750px and up) */
@media (min-width: 750px) {
    .login {
        .main-container {
            .login-form-container {
                .login-content {
                    .logo {
                        margin-bottom: 15px;
                    }

                    .page-title {
                        font-size: 52px;
                        line-height: 54px;
                    }

                    .login-info-message {
                        font-size: 22px;
                        line-height: 30px;
                        margin: 18px 0 46px;
                    }

                    .submit-group {
                        .btn-success {
                            font-size: 16px;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
}

/* Small height devices*/
@media (max-height: 319px) {
    body.login {
        display: block;
        background-attachment: fixed;

        .main-container {
            display: block;

            .main-view-container {
                padding-top: 50px;
            }
        }

        .copyright {
            position: static;
        }
    }
}
