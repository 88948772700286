.address-change {
    margin-top: 50px;

    &-section {
        margin-top: 50px;

        .address {
            font-size: 16px;
            margin-left: 15px;

            &-heading-container {
                background-color: $natural-medium;
                padding: 15px;

                .wg-checkbox-inner {
                    margin-top: 3px;
                }
            }

            &-role {
                border-bottom: 1px solid $silver-medium;
                margin-bottom: 10px;
                padding-bottom: 10px;

                p {
                    margin: 0;
                }

                &:last-child {
                    border-bottom: 0;
                }
            }

            &-container {
                position: relative;

                wg-checkbox {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            padding-left: 15px;

            p {
                margin: 0;
            }

            &-listing {
                margin-bottom: 25px;
                border-bottom: 1px solid $silver-medium;
                padding-bottom: 20px;

                &:nth-last-of-type(2) {
                    border: 0;
                }

                .address {
                    &-pid,
                    &-marketing-name {
                        margin-top: 0;
                    }

                    &-marketing-name {
                        margin-bottom: 0;
                    }

                    &-pid,
                    &-marketing-name,
                    &-role-list {
                        @include bp($medium) {
                            padding-left: 15px;
                        }
                    }
                }

                &-container {
                    padding: 45px 0;
                    background-color: $white;
                    margin-bottom: 25px;
                }
            }

            &-edit-container {
                position: absolute;
                top: 0;
            }
        }
    }

    .popup-content {
        margin: 0 auto;
        max-width: 750px;
    }

    &-contents {
        p {
            margin: 0;

            &.address-change-party-role {
                margin: 7px 0;
            }
        }
    }

    &-personal-info {
        width: 33%;
    }

    &-party-role {
        &.insured {
            &:before {
                background-color: $apricot-dark;
            }
        }

        &.owner {
            &:before {
                background-color: $kelly;
            }
        }

        &.payor,
        &.payer {
            &:before {
                background-color: $sea-foam-dark;
            }
        }

        &.annuitant {
            &:before {
                background-color: $cement-dark;
            }
        }

        &:before {
            content: '\e603';
            font-family: 'icomoon';
            color: $white;
            display: inline-block;
            width: 22px;
            height: 22px;
            text-align: center;
            border-radius: 50%;
            padding-left: 1px;
            margin-right: 8px;
        }
    }

    &-caption {
        background-color: $natural-medium;
        padding: 1.5em 2em;

        h1 {
            font-size: 20px;
            font-weight: 400;
            color: $chalkboard-dark;
            margin: 0;
        }

        &.errored-results {
            margin-bottom: 15px;
        }
    }

    .address-current p {
        line-height: 8px;
    }

    &-container {
        .address {
            &-edit-container {
                background: $white;
                margin-bottom: 25px;
                padding-bottom: 45px;
            }

            &-new-address-form-section {
                opacity: 1;
                transition: opacity .25s linear;

                &.loading {
                    opacity: 0;
                }

                wg-spinner-small {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                }

                select {
                    &.ng-invalid {
                        color: $black;
                    }
                }
            }
        }

        .btn {
            &-group {
                .btn {
                    float: none;
                }
            }
        }

        &.advanced {
            wg-checkbox {
                margin-right: 10px;
            }

            .role-name {
                display: inline-block;
                margin-left: 10px;
            }
        }
    }
}

.address-change {
    h1 {
        font-weight: 400;
        font-size: 22px;
    }

    .address,
    .role {
        &-new-address-form-section {
            .zip-input {
                &:after {
                    content: '\2013';
                    position: absolute;
                    top: 50%;
                    right: -3px;
                }
            }

            .form-control {
                &.ng-touched {
                    &.ng-valid {
                        border-color: $silver;

                        &.ng-invalid {
                            border-color: $cherry;
                        }
                    }
                }
            }
        }

        &-listing {
            border-bottom: 1px solid $silver-medium;

            &:nth-last-of-type(1) {
                border: 0;
            }

            .policy-list-header {
                margin-bottom: 0;
                font-size: 14px;
            }
        }

        &-changing-section,
        &-confirmation,
        &-new-address-form-section {
            p {
                margin: 0;
            }

            .address-listing,
            .role-listing {
                padding: 20px 0;
            }
        }

        &-new-address-form-section {
            p {
                &.address-validation-error {
                    margin-bottom: 15px;
                }
            }
        }

        &-confirmation {
            .address-listing {
                border-bottom: 0;

                .btn {
                    margin: 5px 0;
                }
            }
        }
    }
}

.address-verify {
    .address {
        &-suggested,
        &-proposed {
            position: relative;
            padding: 15px;
        }

        &-suggested {
            border-bottom: 1px solid $silver-medium;
        }
    }

    &-open {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    input,
    .address {
        display: inline-block;
    }

    .address p {
        margin: 0;
    }

    input {
        vertical-align: top;
        margin-top: 0;
        margin-right: 10px;
    }

    .form-control {
        width: initial;
    }

    .btn {
        position: absolute;
        top: 32%;
        right: 15px;

        @include bp(420px) {
            position: relative;
            display: block;
            margin: 15px 40px;
        }
    }

    .popup {
        &-container {
            @include bp(small) {
                margin-top: 0;
            }

            .popup-content {
                width: 500px;

                @include bp(small) {
                    width: 100%;
                }

                .modal-header {
                    position: relative;
                }

                .close {
                    top: 21px;
                }
            }
        }
    }

    &.beneficiary-change {
        .address {
            &-suggested,
            &-proposed {
                .btn {
                    position: absolute;
                }
            }
        }

        .btn {
            position: inherit;
        }

        .popup-container {
            background: transparent;
        }
    }
}
