.wg-conversion-credits {
    header {
        margin-bottom: 20px;
    }

    .conversion {
        &-date {
            .form-group {
                width: 33%;
                @include bp(969px) {
                    width: 45%;
                }
            }
        }

        &-date,
        &-amount {
            label {
                padding-top: 14px;
            }
        }

        &-date,
        &-current-face-amount,
        &-amount,
        &-amount-after,
        &-credit-result,
        &-refund-result,
        &-date-result,
        &-face-amount,
        &-convert-amount,
        &-cancel-amount,
        &-amount-after {
            margin: 8px 0;
        }

        &-amount-after {
            span {
                border-top: 1px solid $black;
                @include bp($policy-summary-custom-bp) {
                    border: 0;
                }
            }
        }

        &-amount-after,
        &-amount,
        &-date {
            small {
                display: block;
                @include bp($policy-summary-custom-bp) {
                    float: left;
                    clear: left;
                }
            }
        }

        &-form {
            margin-top: 20px;
        }

        &-current-face-amount,
        &-amount-after {
            span {
                margin-right: 10px;
                float: right;
                @include bp($policy-summary-custom-bp) {
                    float: left;
                    clear: left;
                    font-size: 18px;
                    margin: 0;
                }
            }
        }

        &-application-init {
            .owners,
            .insureds {
                margin-bottom: 25px;

                h2 {
                    font-weight: 500;
                    font-size: 18px;
                }
            }

            .address {
                p {
                    margin: 0;
                }
            }

            button {
                margin-right: 0;
                clear: right;
                margin-top: 10px;
            }

            strong {
                &.opt-in-label {
                    margin-left: 5px;
                    cursor: pointer;
                }
            }
        }
    }

    .btn {
        &-group {
            margin-top: 15px;
        }
    }

    &.form-widget-container {
        top: 0;
    }

    label {
        font-weight: bold;
        @include bp($policy-summary-custom-bp) {
            float: left;
        }
    }

    .view-success {
        .conversion {
            &-amount-after {
                span {
                    border: 0;
                    margin-right: 0;
                    @include bp($policy-summary-custom-bp) {
                        clear: inherit;
                        font-size: 14px;
                    }
                }
            }

            &-credit-result {
                &:before {
                    content: '';
                    display: block;
                    border-top: 1px solid $silver;
                    margin-bottom: 10px;
                }
            }
        }

        button {
            margin-right: 0;
        }
    }

    .form {
        &-group {
            float: right;
            @include bp($policy-summary-custom-bp) {
                float: left;
                clear: left;
                margin-bottom: 0;
            }
        }

        &-control {
            @include bp($policy-summary-custom-bp) {
                text-align: left;
            }
        }
    }
}