.batch-statements {
    margin-top: 50px;

    quarter-selector {
        display: block;

        .btn {
            margin-right: .35em;

            &-request {
                &[disabled] {
                    &:hover {
                        background-color: $sky;
                    }
                }
            }
        }

        .btn-primary:active,
        .btn-primary.active {
            background-color: $sky;
        }
    }

    pending-statement-jobs {
        display: flex;
    }

    available-downloads {
        display: flex;
    }

    .batch-statement-introduction-section {
        background-color: $natural-medium;
        padding: 1.5em 2em;
        display: inline-block;

        h1 {
            margin: 0;
            line-height: 1.69;
            font-size: 20px;
            font-weight: 400;
            color: $chalkboard-dark;
            display: inline-block;
        }

        .btn {
            margin-left: 15px;
        }
    }

    .spacer-column {
        width: 19.4%;
    }

    .status-column {
        padding-right: 20px;
    }

    .batch-statement {
        &-quarter-section,
        &-pending-section,
        &-available-section {
            margin-bottom: 40px;

            h5 {
                font-weight: 400;
            }
        }

        &-pending-section {
            .spinner {
                position: relative;
                top: inherit;
                bottom: inherit;
                left: inherit;
                z-index: 0;
                display: inline;
            }
        }

        &-quarter-section {
            .btn {
                @include bp(small) {
                    width: 100%;
                    margin-bottom: 10px;
                }

                &-group {
                    @include bp(small) {
                        width: 100%;
                    }
                }
            }
        }
    }
}

