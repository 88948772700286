
// Breakpoint Sizes
$large: 1200px;
$medium: 969px;
$small: 749px;
$smaller: 554px;
$extraSmall: 359px;
$policy-summary-custom-bp: 419px;


// Colors
$chalkboard: #797979;
$chalkboard-medium: #585858;
$chalkboard-dark: #3d3d3d;
$cement: #a8a8a8;
$cement-medium: #939393;
$cement-dark: #656565;
$kelly: #0eac85;
$kelly-medium: #088264;
$kelly-dark: #006850;
$kelly-text: #1bb4a3;
$kelly-medium-lines: #359980;
$kelly-medium-text: #9ccdc1;
$finality-btn: #00a87e;
$finality-btn-hover: #009671;
$cherry: #ea5959;
$cherry-medium: #bd4747;
$cherry-dark: #9a4044;
$warning-btn: #9a4044;
$warning-btn-hover: #d24f4f;
$apricot: #ffc353;
$apricot-medium: #ff9847;
$apricot-dark: #ff7747;
$sky: #006187;
$sky-medium: #004e6c;
$sky-dark: #004059;
$sky-button: #015d82;
$normal-btn-hover: #005779;
$bright-link: #0089d5;
$sky-medium-lines: #337189;
$sky-medium-text: #99b8c4;
$sky-dark-text: #668b99;
$sea-foam: #88979e;
$sea-foam-medium: #627b86;
$sea-foam-dark: #4b6773;
$secondary-btn-hover: #586e78;
$sea-foam-icon: #778d96;
$natural: #f8f8f8;
$natural-medium: #f0f0f0;
$natural-dark: #dfdfdf;
$natural-light: #f0f0f08f;
$silver: #dadada;
$silver-medium: #cecece;
$silver-dark: #c8c8c8;
$white: #fff;
$black: #000;
$form-placeholder-text: #c9c9c9;
$shadow: rgba(0, 0, 0, .15);
$shadow-color: rgba(0, 0, 0, .15);
$box-shadow: 0 6px 18px $shadow-color;
$modal-background: rgba(0, 0, 0, .8);
$max-width: 1170px;
$common-nav-bg: #37474f;
$app-select-open: #ffc02d;
$common-nav-underline: #004d91;
$lg-nav-vertical-border: #979797;
$search-bg: #003666;
