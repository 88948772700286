.new-beneficiary {
    width: 100%;

    .header {
        position: relative;
        background-color: inherit;

        button {
            position: absolute;
            right: 0;
            top: 5px;
        }
    }

    h1 {
        font-weight: 100;
        font-size: 24px;
        padding-bottom: 15px;
        border-bottom: 1px solid $silver;
        margin-top: 0;
    }

    .icon-close {
        color: $black;
    }

    .panel {
        &-heading {
            padding: 10px 0;
        }

        &-title {
            padding-bottom: 10px;
        }
    }

    .form-group {
        &.share {
            position: relative;

            span {
                position: absolute;
                right: 0;
                top: 50%;
            }
        }
    }
}

.beneficiary-change {
    .editor-buttons {
        border-top: solid 1px black;
    }

    .table {
        margin-bottom: 10px;

        > thead {
            > tr {
                th {
                    &.pml-list-view-title {
                        background: transparent;
                        padding-left: 0;
                        font-weight: 400;
                        font-size: 20px;
                        border: 0;
                    }
                }
            }
        }

        > tfoot {
            border-top: 2px solid $silver;

            > tr {
                > td {
                    &.add-beneficiary {
                        padding-left: 0;

                        .select-wrapper {
                            max-width: 500px;
                        }
                    }
                }
            }
        }
    }

    pml-ng-list-view {
        margin-bottom: 0;
    }

    // beneficiary-category {
    //     border-bottom: 1px solid $silver;
    //     padding-bottom: 50px;
    //     display: block;
    //     margin-bottom: 50px;
    // }

    .beneficiary-edit {
        display: block;
        margin-bottom: 15px;
        padding-bottom: 30px;
        border-bottom: 1px solid $silver;

        &.client {
            .table {
                > tbody {
                    > tr {
                        > td {
                            vertical-align: middle;
                        }
                    }
                }
            }

            .total-header {
                vertical-align: top;

                .total-message {
                    right: 0;
                    top: 22px;
                }
            }
        }

        &.final {
            .sub-title {
                display: block;
                margin-bottom: 15px;
            }
        }

        beneficiary-edit-trigger {
            display: inline-block;
            margin-right: 15px;
        }
    }

    beneficiary-category {
        &:last-of-type {
            .beneficiary-edit {
                border: 0;
            }
        }
    }

    .select-wrapper {
        background-color: $white;
    }

    pml-ng-list-view {
        h4 {
            &.pml-list-view-title {
                padding-bottom: 10px;
            }
        }
    }

    .final-bene-options {
        input {
            &[type='radio'] {
                margin-right: 6px;
            }

            &.form-control {
                margin-left: 0;
            }
        }
    }

    .beneficiary-controls {
        margin-top: -35px;

        slider-switch {
            margin-right: 15px;
            padding-top: 28px;
        }
    }

    &.client {
        .form-control {
            background: $white;
        }

        .select-wrapper {
            &.transparent {
                .form-control {
                    background: transparent;
                }
            }
        }

        .combobox {
            height: 45px;
        }

        phone-number-input {
            .delete-phone {
                right: 40px;
            }
        }

        .dist-read-only {
            span {
                display: block;
                margin-top: 2px;
            }
        }
    }

    role-address {
        display: block;
        border-top: 1px solid $silver;
        margin-top: 30px;
    }
}