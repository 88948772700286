pml {
    &-ng-list-view {
        display: block;
        margin-bottom: 15px;

        h4 {
            border-bottom: 1px solid $silver;
            padding-bottom: 30px;
            font-weight: 400;
            font-size: 20px;

            &.pml-list-view-title {
                border-bottom: 0;
                padding: 0;
                margin: 0;
            }
        }
    }

    &-list-view {
        &-filter {
            button {
                border: 0;
                background-color: transparent;
                color: $cement-medium;
                position: absolute;
                right: 5px;
                top: 7px;

                &:focus {
                    outline: none;
                }
            }
        }
    }
}

.pml-list-view-note {
    margin-top: 1em;
    margin-bottom: 1em;
}