.all-policies {
    .filter-container {
        margin-bottom: 40px;

        .filter-label {
            margin-right: 10px;
        }

        hr {
            margin: 0;
            margin-right: 10px;
            margin-left: 10px;
        }

        .panel-dark {
            background-color: $natural;
        }

        label {
            font-size: 14px;
            line-height: 22px;
            padding: 0;
            padding: 2px 0;
            color: $chalkboard-dark;
        }

        .wg-checkbox,
        label {
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 0;
        }

        .checkbox-container {
            display: inline-block;
            margin-right: 10px;
            padding: 7px 10px;
            background-color: $white;
            border: solid 1px $silver-medium;
            cursor: pointer;

            .wg-checkbox {
                margin-right: 5px;
            }
        }

        .filter-tab-container {
            .filter-tab {
                display: inline-block;
                padding: 18px 0px;
                margin: 0 9px;
                cursor: pointer;

                &.active {
                    border-bottom: 3px solid #0089d5;
                }
            }
        }

        .filter-lob-container {
            padding: 15px 10px;
        }

        .filter-product-type-container {
            padding: 15px 10px;

            .checkbox-container {
                background-color: $natural;
                border: 0;
            }
        }
    }

    filter-bar {
        display: flex;
        padding-top: .50em;
        padding-bottom: .50em;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        @include bp($medium) {
            flex-direction: column;
            justify-content: normal;
            align-items: inherit;
        }

        > filter {
            flex-basis: 25%;
            align-items: center;
            padding-right: 2em;

            @include bp($medium) {
                flex-basis: 100%;
                padding-right: 0;
            }

            @include bp(620px) {
                padding: 0;
            }


            // > label {
            //     margin-right: .5em;
            // }

            > select {
                padding: .25em;
                // width: 3em;
            }

            &.holding-status {
                &-filter {
                    flex-basis: 34%;

                    @include bp(1199px) {
                        flex-basis: 45%;
                    }
                }
            }
        }

        .btn {
            margin-top: 7px;
            height: 100%;
        }
    }

    .table > tbody > tr > td {
        padding: 1px 8px;
        border-top: 0;
    }
}
