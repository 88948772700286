profile-dropdown {
    ul {
        li {
            display: inline-block;
            color: $white;

            h2 {
                font-family: 'TiemposHeadline';
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 20px;
                display: inline-block;
                padding-bottom: 2px;
                cursor: pointer;
                color: $black;

                &:after {
                    content: '';
                    border-bottom: 2px solid $common-nav-underline;
                    width: 100%;
                    display: block;
                    margin-top: 2px;
                    opacity: 0;
                    visibility: hidden;
                    @include transition(opacity .25s ease);
                }

                &:hover {
                    &:after {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            &.dropdown {
                &.open {
                    display: inline-block;

                    & > .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        padding: 36px;
                        width: 302px;
                        @include bp(small) {
                            width: calc(100% + 2px);
                        }

                        &.profile {
                            padding: 20px;
                            @include bp(small) {
                                padding: 0;
                            }
                        }
                    }
                }
            }

            &.desktop {
                @include bp(small) {
                    display: none;
                }
            }

            &.mobile {
                display: none;
                @include bp(small) {
                    display: block;
                }
            }

            &.dropdown-menu {
                display: block;
                opacity: 0;
                visibility: hidden;
                @include transition(opacity .25s ease);

                li {
                    color: $black;
                }
            }
        }

        .profile-item {
            .full-name {
                color: $white;

                &:before {
                    content: '\e900';
                    font-family: 'icomoon';
                    font-size: 32px;
                    position: absolute;
                    left: -12px;
                    top: -4px;
                }
            }
        }

        .full-name {
            font-family: 'Pragmatica';
            font-weight: bold;
            font-size: 12.5px;
            line-height: 18px;
            text-transform: uppercase;
            vertical-align: super;
            margin-left: 25px;
            @include bp(small) {
                line-height: inherit;
                vertical-align: bottom;
            }

            &:before {
                content: '\e900';
                font-family: 'icomoon';
                font-size: 32px;
                position: absolute;
                left: -12px;
                top: -4px;
            }

            &-message {
                @include bp(small) {
                    display: none;
                }
            }
        }
    }

    .profile-welcome-message {
        @include bp(small) {
            display: none;
        }
    }
}