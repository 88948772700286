body.policy-detail-values {
    .account-details {
        margin-top: 4em;
    }

    .values-container {
        margin: 0;
        padding: 0;
    }

    .values-table-structure {
        page-break-before: always;
        break-before: page;
    }

    .summary-container .popup-container {
        overflow: visible;
        overflow-y: visible;
    }

    .values-account-detail {
        float: left;
        width: 33%;
    }

    .values-section {
        .table {
            margin-bottom: 0;
        }
    }

    .coverageInfo {
        padding-right: 0;
    }

    .print {
        &-header,
        &-subheader {
            float: left;
            width: 100%;
        }
    }

    .policy-print-container {
        .copyright {
            margin: 15px;
        }
    }

    /* I'm really sorry, but this needs to happen to print out the disclaimer in landscape orientation. */
    @media (min-width: $small + 1px) {
        .coverageInfo .col-sm-3 {
            float: none;
        }
    }
}
