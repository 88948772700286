app-switcher {
    @include bp(small) {
        position: fixed;
        display: block;
        width: 50px;
        height: 50px;
        background-color: $common-nav-underline;
        border-radius: 50%;
        right: 20px;
        bottom: 20px;
    }

    .app-switcher-toggle {
        &:before {
            @include bp(small) {
                font-size: 30px;
                position: absolute;
                left: 10px;
                top: 10px;
            }
        }
    }

    .icon {
        font-size: 32px;
    }

    ul {
        li {
            display: inline-block;
            color: $white;

            h2 {
                font-family: 'TiemposHeadline';
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 20px;
                display: inline-block;
                padding-bottom: 2px;
                cursor: pointer;

                &:after {
                    content: '';
                    border-bottom: 2px solid $common-nav-underline;
                    width: 100%;
                    display: block;
                    margin-top: 2px;
                    opacity: 0;
                    visibility: hidden;
                    @include transition(opacity .25s ease);
                }

                &:hover {
                    &:after {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                a {
                    color: $black;

                    &:hover {
                        text-decoration: none;
                        color: $black;
                    }
                }
            }

            p {
                font-family: 'Pragmatica';
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 20px;
                margin-top: 0;
            }

            &.dropdown {
                &.open {
                    display: inline-block;

                    .icon {
                        &:before {
                            color: $app-select-open;
                            @include transition(all .25s ease);
                            @include bp(small) {
                                content: '\e615';
                                color: $white;
                            }
                        }
                    }

                    & > .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        padding: 36px;
                        width: 302px;
                        @include bp(small) {
                            position: fixed;
                            width: 55%;
                            top: auto;
                            right: 15px;
                            bottom: 10%;
                            display: block;
                            padding: 36px;
                            @include transition(opacity .25s ease);
                        }

                        &.profile {
                            padding: 20px;
                        }
                    }
                }
            }
        }

        &.dropdown-menu {
            display: block;
            opacity: 0;
            visibility: hidden;
            @include transition(opacity .25s ease);

            li {
                color: $black;
            }
        }
    }
}