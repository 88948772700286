.fund-rebalance {
    .fixed-funds-section,
    .variable-funds-section {
        h3 {
            font-weight: 400;
            font-size: 20px;
        }
    }

    .variable-funds {
        &-header {
            color: $chalkboard-dark;
        }
    }

    .back-to-policy {
        margin-left: 15px;
    }
}