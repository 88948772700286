.unread-alert {
    div {
        border-radius: 50%;
        width: 12px;
        height: 12px;
        padding: 0;
        background: $bright-link;
        margin-top: .4em;
    }
}

.general-details-table.alerts-table {
    table-layout: fixed;
    position: relative;

    td {
        border-bottom: solid 1px $silver;
    }

    tbody {
        tr {
            th:first-child,
            th:nth-child(2),
            th:nth-child(3),
            th:nth-child(4),
            th:nth-child(5),
            th:nth-child(6),
            th:nth-child(7),
            th:last-child {
                width: inherit;
                padding-right: 15px;
            }

            &.empty-table-body {
                height: 350px;

                td {
                    vertical-align: middle;
                    text-align: center;
                    font-size: 16px;
                    color: $cement-medium;
                    padding: 20px;
                }
            }

            &.unread {
                background-color: $natural;

                td {
                    font-weight: bold;
                }
            }

            th {
                cursor: pointer;
                position: sticky;
                top: 0;
                z-index: 99;
            }

            td,
            th {
                font-size: 14px;
                height: 50px;
                padding-left: 4px;
                padding-right: 4px;

                &:first-child {
                    width: 20px;
                }

                &.col-checkbox {
                    width: 4%;
                }

                &.col-activity-type {
                    width: 16%;
                }

                &.col-activity-date {
                    width: 14%;
                }

                &.col-advisor {
                    width: 16%;
                }

                &.col-policy-number {
                    width: 14%;
                }

                &.col-insured {
                    width: 14%;
                }

                &.col-product {
                    width: 16%;
                }

                &.col-viewdetails {
                    width: 10%;
                }
            }
        }
    }
}

.alert-content-box {
    background-color: $white;
    color: $chalkboard-dark;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 20px 11px;
    border-bottom: none;

    &-info {
        padding-left: .8em;
        width: 100%;
    }
}

.alert-mobile-above-boxes {
    border-bottom: $silver solid 1px;
    padding: 18px 20px;
    height: 58px;

    &:not(:first-child) {
        border-top: $silver solid 1px;
    }
}

.alert-mobile-box {
    border-bottom: $silver solid 1px;
    -webkit-font-smoothing: antialiased;

    .alert-indicator {
        width: .5em;
    }

    .box-header {
        h5 {
            font-size: 16px;
            line-height: 22px;
            margin-top: 0;
            margin-bottom: 0;
            color: $black;
            font-weight: normal;
        }

        h5.unread-alert {
            color: $black;
            font-weight: bold;
        }

        .small-date {
            color: $cement-medium;
            font-size: 14px;
            font-weight: normal;
            line-height: 22px;
            text-align: right;
        }
    }

    .box-body {
        .grey-text-row {
            color: $cement-medium;
            font-size: 16px;
            margin: 6px 0;
            line-height: 18px;
            font-weight: normal;
        }

        .label {
            &.column-content {
                line-height: 18px;
            }
        }
    }

    .box-footer {
        padding: 11px 20px;
        margin: 0;
        background-color: $natural-dark;

        .icon {
            font-size: 24px;
            color: $cement-medium;
            float: right;
        }
    }
}

.view-all-alerts .alerts-table tbody tr {
    td,
    th {
        @include bp(medium) {
            font-size: 12px;
        }
        @include bp(large) {
            font-size: 14px;
        }
    }
}

.no-results-text-mobile {
    padding-top: 40px;
    text-align: center;
    font-size: 16px;
    line-height: 1.5em;
    color: $cement-medium;
}
