.club-credits {
    .progress {
        height: 20px;
        margin-bottom: 20px;
        overflow: hidden;
        background-color: #f5f5f5;
        border-radius: 4px;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);

        &-bar {
            text-align: center;
            float: left;
            height: 100%;
            font-size: 12px;
            line-height: 20px;
            color: $white;
            background-color: #337ab7;
            box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
            transition: width .6s ease;
            background-image: none;

            &-policies-sold {
                .progress-bar {
                    background-color: #bf0774;
                }
            }

            &-warning {
                background-image: none;

                .progress-bar {
                    background-color: #f0ad4e;
                }
            }

            &-info {
                background-image: none;

                .progress-bar {
                    background-color: #5bc0de;
                }
            }
        }
    }
}