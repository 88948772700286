body.policy-requirements,
body.pending {
    .policy-header-owner-name {
        margin-top: 0;
    }

    .product-info-section {
        text-align: left;

        .name-container {
            float: left;
            margin: 0;
            padding: 0;

            .policy-header-content.pol-number {
                display: inline;
                margin-left: 1em;
                vertical-align: middle;
            }
        }

        .user-details-container {
            float: left;
            margin: 0;
            margin-left: 2em;
            padding: 0;

            .column-name {
                line-height: 24px;
            }

            span {
                display: inline;
            }
        }

        hr {
            display: none !important;
        }

        wg-favorite-policy {
            display: none;
        }
    }
}
