.client-site {
    .client-correspondence {
        margin: 17px 0 0 30px;

        @include bp(small) {
            clear: left;
            margin-left: 0;
        }
    }
}

.address-container {
    padding-right: 31px;
}

.unpaid-policy-messages {
    margin-top: 2em;

    .unpaid-policy-container {
        background-color: $sky-medium;
        color: white;
        padding: 1em;

        + .unpaid-policy-container {
            margin-top: 1em;
        }
    }

    .unpaid-alert-message {
        float: left;
        width: calc(100% - 9em);

        .icon {
            display: inline-block;
            font-size: 1.5em;
            vertical-align: top;
            width: 1em;
        }

        p {
            display: inline-block;
            margin: 0;
            max-width: calc(100% - 2em);
        }
    }

    .btn {
        background-color: $kelly;
        float: right;
        vertical-align: top;
        width: 8em;

        &:focus,
        &:hover {
            background-color: $kelly-medium;
        }
    }
}

.section-user-info {
    color: $white;
    font-size: 16px;
    line-height: 18px;
    padding-top: 26px;
    padding-bottom: 18px;

    .client-meta-data {
        display: inline-block;
        width: 55%;
    }

    @include bp(medium) {
        padding-top: 20px;
        padding-bottom: 20px;

        &.client {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .top-space {
            margin-top: 0;
            margin-bottom: 8px;
        }
    }

    .client-site & .insured-date {
        margin-left: 56px;
    }

    .client-favorite-container {
        position: absolute;
        right: 25px;
    }

    .icon,
    .client-name {
        display: inline-block;
        vertical-align: top;
    }

    .outline-button {
        min-width: 160px;
        min-height: 36px;
        line-height: 34px;
        margin-top: 4px;
        display: inline-block;
    }

    .btn-favorite {
        margin-left: 18px;

        .icon {
            margin-top: 10px;

            @include bp(medium) {
                margin-top: 2px;
            }
        }
    }

    .icon-user,
    .icon-business {
        width: 36px;
        height: 36px;
        line-height: 38px;
        color: $white;
        font-size: 20px;
        margin-right: 12px;
        background-color: $sea-foam-icon;
        border-radius: 50%;
        text-align: center;
        margin-top: 5px;
        position: relative;

        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &.section-top-info {
        .client-name {
            .icon-user,
            .icon-business {
                width: 36px;
                height: 36px;
                line-height: 38px;
                color: $white;
                font-size: 20px;
                margin-right: 12px;
                background-color: $sea-foam-icon;
                border-radius: 50%;
                text-align: center;
                margin-top: 5px;
                position: relative;

                &:before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .column-title.not-long-text {
                display: block;
            }
        }
    }

    .column-title {
        color: $sea-foam;
    }

    .address-link,
    .phone-link,
    .email-link {
        //display: block;
        cursor: pointer;

        &.default {
            cursor: default;
            text-decoration: none;
        }

        &:visited,
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    .address-link {
        margin-bottom: 5px;
    }

    hr {
        border-color: $sky-medium-lines;
        margin: 15px 0;
        margin-left: -5px;

        @include bp(medium) {
            margin-top: 18px;
            margin-bottom: 18px;
        }
    }

    .border-left {
        border-left: 1px solid $sea-foam-dark;
    }

    .column-content,
    a.column-content {
        color: $white;
        line-height: 18px;
    }

    .client-name {
        width: calc(100% - 90px);

        .column-title {
            font-size: 32px;
            line-height: 38px;
            color: $white;
            font-weight: 300;
            vertical-align: bottom;

            @include bp(medium) {
                font-size: 40px;
                line-height: 45px;
            }
        }

        @include bp(medium) {
            &.client-name-sm .column-title {
                font-size: 34px;
                padding-bottom: 0;
            }
        }

        .column-content,
        a.column-content {
            color: $white;
            line-height: 18px;
        }

        .column-title,
        .client-info {
            padding-left: 5px;
            margin-bottom: 0;
            padding-bottom: 4px;
        }

        @include bp(medium) {
            .client-info {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }

    h3 {
        color: $white;
    }

    label {
        color: $sky-medium-text;
        margin-bottom: 7px;
        display: block;

        & + div,
        & + ul li,
        & + .address-link,
        & + .phone-link,
        & + .email-link {
            color: $white;
            margin-top: 0;

            &.bigger {
                font-size: 24px;
                line-height: 30px;
                font-weight: 300;
                font-family: 'Open Sans', sans-serif;
            }
        }
    }

    .inline-content {
        display: inline-block;
    }

    .info-box {
        margin-bottom: 30px;
    }

    .scroll-container {
        width: 100%;
        height: 48px;
        overflow: hidden;

        .inside-scroll-container {
            width: 100%;
            overflow-x: scroll;
            overflow-y: hidden;

            .inside-scroll-content {
                width: 685px;
                height: 70px;
            }

            .inside-box {
                float: left;
                margin-left: 30px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    .requirements-widget {
        &.client-alerts {
            margin: 0 0 8px;
            display: inline-block;
            vertical-align: top;
        }
    }

    .client-ins-since {
        margin-left: 26px;
    }

    .email-address {
        margin-top: 15px;
    }
}

.policy-roles {
    margin-top: 10px;
    margin-left: 42px;

    .policy-role {
        height: 15px;
        font-size: 10px;
        text-transform: uppercase;
        line-height: 15px;
        padding-left: 6px;
        padding-right: 6px;
        color: $white;
        background-color: $black;
        float: left;
        margin-right: 6px;
        margin-bottom: 6px;
        letter-spacing: 1px;

        &.owner {
            background-color: $cherry-medium;
        }

        &.insured {
            background-color: $sky;
        }

        &.payor {
            background-color: $sea-foam;
        }

        &.beneficiary {
            background-color: $apricot-dark;
        }
    }
}

.contact-info-container {
    .contact-info-content {
        background-color: #efefef;
        padding: 16px 18px 15px;
        border-bottom: 1px solid $natural-dark;
        cursor: pointer;
        display: block;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .inline-siblings {
        display: inline-block;
        vertical-align: middle;
    }

    .large-sibling {
        width: calc(100% - 100px);
    }

    .action-link {
        font-size: 14px;
        text-transform: capitalize;
        color: $sky;
        font-weight: 600;
        min-width: 60px;

        &:hover {
            text-decoration: none;
        }

        &.gray-color {
            color: $silver-dark;
        }
    }

    .contact-text,
    .icon {
        display: inline-block;
        vertical-align: middle;
    }

    .icon {
        height: 24px;
        line-height: 24px;
        color: $sea-foam-icon;
        font-size: 22px;
        margin-right: 15px;
        margin-left: -7px;
    }

    .contact-text {
        display: block;
        color: $chalkboard-dark;
        font-size: 16px;
        line-height: 22px;
    }
}

@include bp(medium) {
    label,
    .label,
    .section-user-info {
        font-size: 14px;
        line-height: 18px;

        .client-site &.client {
            padding-top: 29px;
            padding-bottom: 28px;
        }
    }
}
