@mixin section-top-info() {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#003a58+0,006489+100 */
    background: #003a58; /* Old browsers */
    background: -moz-linear-gradient(top, #003a58 0%, #006489 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #003a58), color-stop(100%, #006489)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #003a58 0%, #006489 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #003a58 0%, #006489 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #003a58 0%, #006489 100%); /* IE10+ */
    background: linear-gradient(to bottom, #003a58 0%, #006489 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#003a58', endColorstr='#006489', GradientType=0); /* IE6-9 */
}

@mixin linear-gradient($top-color: $white, $bottom-color: $black, $size-height: 0) {
    background: -moz-linear-gradient(top, $top-color 0%, $top-color $size-height, $bottom-color $size-height, $bottom-color 100%);
    background: -webkit-linear-gradient(top, $top-color 0%, $top-color $size-height, $bottom-color $size-height, $bottom-color 100%);
    background: -o-linear-gradient(top, $top-color 0%, $top-color $size-height, $bottom-color $size-height, $bottom-color 100%);
    background: -ms-linear-gradient(top, $top-color 0%, $top-color $size-height, $bottom-color $size-height, $bottom-color 100%);
    background: linear-gradient(to bottom, $top-color 0%, $top-color $size-height, $bottom-color $size-height, $bottom-color 100%);
}

@mixin background-size($bg-size: cover) {
    -webkit-background-size: $bg-size;
    background-size: $bg-size;
}

@mixin box-sizing($box-sizing: border-box) {
    -webkit-box-sizing: $box-sizing;
    -moz-box-sizing: $box-sizing;
    box-sizing: $box-sizing;
}

@mixin border-radius($radio) {
    -webkit-border-radius: $radio;
    -moz-border-radius: $radio;
    border-radius: $radio;
}

@mixin transform-translate3d($translateX: 0, $translateY: 0, $translateZ: 0) {
    -webkit-transform: translate3d($translateX, $translateY, $translateZ);
    -moz-transform: translate3d($translateX, $translateY, $translateZ);
    -ms-transform: translate3d($translateX, $translateY, $translateZ);
    -o-transform: translate3d($translateX, $translateY, $translateZ);
    transform: translate3d($translateX, $translateY, $translateZ);
}

@mixin transform($args) {
    -webkit-transform: $args;
    -moz-transform: $args;
    -ms-transform: $args;
    -o-transform: $args;
    transform: $args;
}

@mixin animation($args) {
    -webkit-animation: $args;
    -o-animation: $args;
    animation: $args;
}

@mixin clear() {
    &:before,
    &:after {
        content: '';
        display: block;
    }

    &:after {
        clear: both;
    }
}

@mixin policy-left-border() {
    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 5px;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
    }
}

@mixin policy-border-color($color) {
    &::before {
        background-color: $color;
    }
}

@mixin bp($breakpoint) {
    @if $breakpoint == large {
        @media (min-width: $large) {
            @content;
        }
    }

    @elseif $breakpoint == medium {
        @media (min-width: $small + 1px) {
            @content;
        }
    }

    @elseif $breakpoint == small {
        @media (max-width: $small) {
            @content;
        }
    }

    @elseif $breakpoint == smaller {
        @media (max-width: $smaller) {
            @content;
        }
    }

    @elseif $breakpoint == xsmall {
        @media (max-width: $extraSmall) {
            @content;
        }
    } @else {
        @media (max-width: $breakpoint) {
            @content;
        }
    }
}

@mixin policy-relatives-border-bottom($color) {
    border: none;
    border-bottom: solid 5px $color;
}

@mixin policy-relatives-border-left($color) {
    border: none;

    @include bp(medium) {
        &:first-child {
            border-right: 1px solid $silver-medium;
        }

        &:last-child {
            border-left: 1px solid $silver-medium;
        }
    }
    @include bp(small) {
        border-left: solid 5px $color;
    }
}

@mixin input-placeholder {
    &.placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &::-webkit-input-placeholder {
        @content;
    }
}

@mixin general-details-table-header-footer { // A.K.A. general-details-table-sandwich-bread
    background-color: $white;
    border-bottom: 1px solid $natural-dark;
    border-top: 1px solid $natural-dark;
    padding: 13px 8px; // To match general details table styling for th elements in tfoots.
    text-align: right;
}
