.popup-container .popup-content {
    max-width: $max-width;
}

.addresses-container .popup-content {
    max-width: 800px;
}

.modal {
    &.scrollable {
        overflow-y: auto;
        /* Override bootstrap's .modal rule which overrides the .scrollable rule! */
    }

    &.bank-payment-form,
    &.warning-modal {
        .modal-body {
            border-radius: 0;
        }

        .modal-dialog {
            button {
                &.close {
                    top: 24px;
                    right: 20px;
                }
            }
        }
    }

    &-xl {
        width: 1170px;
    }
}

.modal-header {
    padding: 18px 15px;
    border-bottom: none;
}

.modal-dialog {
    .modal-footer {
        background: $natural-medium;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .modal-body {
        padding: 30px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        overflow: hidden; // Fix for when all elements in the body are floated
    }

    .modal-content-title {
        color: $chalkboard-dark;
        margin-top: 0;
        font-weight: normal;
    }

    .modal-details-date {
        margin-top: 0;
    }
}

.modal-dialog {
    button {
        &.close {
            top: 8px;
            right: 12px;
        }
    }
}

.popup-container,
.product-menu {
    button {
        &.close,
        &.print {
            position: absolute;
            font-size: 18px;
            line-height: 28px;
            font-weight: 300;
            outline: none;
            color: $cement-medium;
            top: 4px;
            right: 12px;
            opacity: 1;

            &:hover {
                color: $chalkboard-dark;
            }
        }

        &.print {
            top: 24px;
            right: 27px;
            z-index: 1;

            &.transactions-print {
                top: inherit;
            }
        }

        .sr-only {
            display: none;
        }
    }
}

.popup-container {
    width: 100%;
    min-height: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    padding: 20px;
    background: rgba(0, 0, 0, .8);
    z-index: 10003;
    left: 0;
    right: 0;

    @include bp(small) {
        margin-top: 63px;
    }

    .secondary-value {
        font-weight: 600;
        color: $chalkboard-dark;
        padding-top: 55px;
    }

    .label {
        font-weight: 300;
    }

    .row.margin-bottom {
        margin-bottom: 40px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    button.close:hover,
    button.print:hover {
        outline: none;
        color: $chalkboard-dark;
    }

    &.modal-container {
        padding: 108px 20px 20px;
        z-index: 1003;
    }

    .popup-content {
        background: $white;
        margin: auto;
        padding-bottom: 50px;
        border-radius: 5px;
        padding: 0 0 25px;

        .wg-modal-body {
            padding-left: 15px;
            padding-right: 15px;
            position: relative;
        }

        &.modal-popup {
            max-width: none;
            position: absolute;
            min-height: calc(100% - 62px);
            width: 100%;
            padding: 0;

            .main-title {
                background-color: #123d50;
                color: $white;

                .title-text {
                    text-align: left;
                    font-size: 16px;
                    line-height: 18px;
                    margin: 16px 0;
                    font-weight: 300;
                }

                .title-text {
                    color: $white;
                }
            }

            .modal-popup-content {
                .modal-content-title {
                    font-size: 26px;
                    color: $chalkboard-dark;
                    margin: 15px 0 2px;
                    text-align: center;
                    font-weight: 400;
                }

                hr {
                    margin-top: 15px;
                    margin-bottom: 14px;
                }

                .modal-content-owner-info {
                    .owner-name {
                        display: inline-block;
                        vertical-align: middle;
                        font-size: 16px;
                        line-height: 20px;
                        color: $chalkboard-dark;
                        margin-right: 8px;
                        text-transform: capitalize;
                    }

                    .owner-info {
                        color: $cement-medium;
                        font-size: 16px;
                        display: block;
                        line-height: 20px;
                        padding-top: 2px;
                    }

                    .separator-vertical-line {
                        background-color: $silver;
                        margin: 0 5px;
                        height: 14px;
                    }
                }

                .label {
                    &.column-content {
                        display: block;
                        font-size: 16px;
                        line-height: 22px;
                        color: $chalkboard-dark;
                        font-weight: 400;
                        padding: 0;
                        margin: 0;
                    }
                }

                .modal-details-date {
                    margin-top: 15px;
                }

                .buttons-container {
                    margin-top: 25px;
                    margin-bottom: 30px;

                    .btn {
                        margin-top: 15px;
                    }
                }
            }
        }
    }

    .alert {
        button {
            &.close {
                &:hover {
                    color: $white;
                }
            }
        }
    }
}

.modal-first-section-margin {
    margin-top: 25px;
}

.lightbox-quote-date-row {
    padding-top: 25px;
    padding-bottom: 25px;
}