.single-input-fields {
    width: 60%;
    margin: 20px auto;
    text-align: center;

    input {
        text-align: center;
        width: 60px;
        height: 90px;
        font-size: 30px;
        font-weight: bold;
        margin: 0 10px;
    }
}