.advisor-contact-information {
    .advisor-address {
        margin-bottom: 0;
    }
    .advisor-address-line {
        margin-bottom: 0.125em;
        margin-top: 0;
    }
    .advisor-address-line:last-child {
        margin-bottom: 0;
    }
    .contact-item-row {
        margin-top: 16px;
        padding-left: 39px;
        position: relative;

        i {
            &.icon-mail-heavy { font-size: 14px; padding: 0 1px; }
            &.icon-map-location-circle { font-size: 20px; }
            font-size: 18px;
            color: $sea-foam;
            margin-right: 10px;
            vertical-align: middle;
            line-height: 18px;
        }
    }

    .link {
        color: $bright-link;
    }

    .icon-container {
        position: absolute;
        left: -24px;
        top: 3px;
    }

    .modal-dialog {
        position: absolute;
        width: 320px;
        height: auto;
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 40px;
        margin-right: 0;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);

        @include bp(medium) {
            width: 530px;
        }

        .modal-content{
            padding-bottom: 20px;
        }

        .modal-body {

            label{
                color: $cement-medium;

                &,
                & + div{
                    font-size: 14px;
                    line-height: 18px;
                    vertical-align: middle;
                }

                & + div{
                    color: $chalkboard-dark;
                }
            }

            .agent-info{
                margin-bottom: 18px;

                .owner-name {
                    display: inline-block;
                    margin-top: 10px;
                    padding: 0;
                    font-size: 20px;
                    color: $chalkboard-dark;
                    font-weight: 400;
                }

                .office-id {
                    @include clear;

                    & > div,
                    & > label{
                        float: left;
                    }

                    & > label{
                        margin-right: 4px;
                    }

                }
            }

            hr{
                margin-top: 25px;
                margin-bottom: 20px;
            }
        }

        .modal-footer {
            display: none;
        }
    }

    .text-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }
}
