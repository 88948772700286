.address-modal,
.client-modal {
    .owner-link,
    .insured-link,
    .annuitant-link,
    .beneficiary-link,
    .assignee-link,
    .payor-link {
        border: none;

        .icon {
            width: 26px;
            height: 26px;
            font-size: 15px;
            position: absolute;
            top: 0;
            left: 0;

            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .single-user-header {
        padding-bottom: 20px;
        padding-left: 30px;
        position: relative;
    }

    .modal-body {
        padding: 30px;
    }

    .name-role-container,
    .icon.user,
    .col-arrow {
        display: inline-block;
        vertical-align: middle;
    }

    .name-role-container {
        padding-left: 15px;

        h3 {
            font-size: 20px;
            margin: 0;
            font-weight: normal;
            -webkit-font-smoothing: antialiased;
        }
    }

    .client-info {
        margin-top: 30px;
        border-bottom: solid 1px $silver;
        padding-bottom: 35px;

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 5px;
        }
    }

    .icon {
        border-radius: 50%;
        color: white;
        width: 34px;
        font-size: 17px;
        text-align: center;
        line-height: 34px;

        &.arrow,
        &.icon-eye {
            color: $cement;
            font-weight: 500;
            font-size: 18px;
            line-height: 18px;
            padding-top: 7px;
        }

        &.icon-eye {
            font-size: 24px;
            font-weight: normal;
        }
    }

    .icon-multiple-users {
        font-size: 22px;
    }

    .client-widget {
        padding: 15px;
        height: 76px;
        cursor: pointer;
        background-color: $natural-medium;

        &:hover,
        &.active {
            background-color: $natural-medium;
        }

        &.unclickable {
            cursor: default;

            .icon-arrow-right {
                display: none;
            }

            &:hover,
            &.active {
                background-color: $white;
            }
        }

        &.disabled {
            .name-role-container {
                .name {
                    color: $silver-dark;
                }
            }

            .dropdown-menu {
                & > li > .relatives-menu-link {
                    color: $silver-dark;
                    text-decoration: none;
                }
            }
        }

        .name-role-container {
            padding: 0 30px 0 15px;
            width: calc(100% - 40px);

            .role {
                padding: 0;
                margin: 0;
            }

            .name {
                padding: 0;
                margin: 0;
                font-size: 18px;
                line-height: 26px;
                color: $chalkboard-dark;
                -ms-text-overflow: ellipsis;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .col-arrow {
            position: absolute;
            height: 18px;
            right: 12px;
            top: 50%;
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
}

.client-modal {
    .bene-modal-ineligible {
        width: 75%;
        margin: 0 auto;

        p,
        ul {
            margin: 0;
        }

        ul {
            padding-left: 15px;
            margin: 5px 0;
        }
    }
}
