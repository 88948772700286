.summary-container {
    .beneficiary-change {
        .editor-box {
            filter: drop-shadow(0 0 4px $silver-medium);
            border-left: 1px solid $silver-medium;
        }

        label {
            color: black;

            &.text-muted {
                color: $silver-medium;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0;
            /* <-- Apparently some margin are still there even though it's hidden */
        }

        input[type=number] {
            -moz-appearance: textfield;
            /* Firefox */
        }

        input::-webkit-calendar-picker-indicator {
            display: none;
        }

        input[type="date"]::-webkit-input-placeholder {
            visibility: hidden !important;
        }

        // input {
        //     &.share {
        //         width: 3.5em;
        //     }

        //     &.party-type {
        //         width: 2em;
        //     }
        // }

        table {
            .action-column {
                width: 7em;
                text-align: center;
                position: relative;

                span {
                    cursor: pointer;
                }

                .editor-buttons {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                }

                .icon {
                    font-size: 22px;
                    margin-right: 5px;
                }
            }

            > thead {
                > tr {
                    > th {
                        &.share-column {
                            width: 15%;
                            text-align: center;
                        }

                        &.relationship-column {
                            width: 23%;
                        }

                        &.share-percent-column,
                        &.share-dollar-amount-column {
                            width: 15%;
                            text-align: center;
                        }
                    }
                }
            }

            > tbody {
                > tr {
                    &[new-beneficiary] {
                        background-color: $natural-light;

                        button {
                            margin-right: 5px;
                        }

                        .form-control {
                            text-align: left;
                        }
                    }

                    > td {
                        border-top: 1px solid $silver;

                        &.editor-open {
                            padding-top: 10px;
                            vertical-align: top;

                            &.estate,
                            &.trust,
                            &.business,
                            &.person {
                                role-relationships {
                                    .label {
                                        color: $black;
                                    }
                                }
                            }

                            &.estate {
                                padding-top: 57px;

                                .settlement-distribution-container {
                                    margin-top: -7px;
                                }
                            }

                            &.trust,
                            &.business {
                                .settlement-distribution-container {
                                    margin-top: -9px;
                                }
                            }
                        }

                        div.editor-column {
                            min-height: 150px;

                            .name-additions {
                                .form-control {
                                    background-color: transparent;
                                }
                            }
                        }

                        &.share-action {
                            &-cell {
                                position: relative;

                                .share-action-container {
                                    position: absolute;
                                    top: 8px;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    justify-content: space-between;

                                    .input-group {
                                        width: 83%;
                                        left: 8px;

                                        &-addon {
                                            padding: 24px 0 0 5px;
                                        }

                                        // .form-control {
                                        //     text-align: center;
                                        // }
                                    }
                                }
                            }
                        }

                        &.new-bene-actions {
                            .icon {
                                font-size: 12px;
                                width: 20px;
                                height: 20px;
                                color: $white;
                                border-radius: 50%;
                                font-weight: bold;
                                position: relative;
                                cursor: pointer;
                                margin-bottom: 10px;
                                display: inline-block;

                                &-check {
                                    background-color: $kelly-medium;
                                }

                                &-close {
                                    background-color: $cherry;
                                }

                                &:before {
                                    position: absolute;
                                    top: 25%;
                                    left: 24%;
                                }
                            }
                        }

                        role-address,
                        phone-number-input {
                            .select-wrapper {
                                .form-control {
                                    background-color: transparent;
                                }
                            }
                        }

                        role-address {
                            .select-wrapper {
                                .form-control {
                                    &:disabled {
                                        background-color: $natural-light;
                                    }
                                }
                            }
                        }

                        .form-control {
                            background-color: $white;
                            padding: 6px;
                            // text-align: center;
                            color: black;

                            &.disabled {
                                border-color: $silver;

                                &.ng-invalid {
                                    border-color: $silver;
                                }
                            }

                            &:disabled {
                                background-color: $natural-light;
                            }
                        }

                        .select-wrapper {
                            input {
                                background-color: transparent;
                            }
                        }

                        .zip {
                            &:after {
                                content: '\2013';
                                position: absolute;
                                right: -3px;
                                top: 43px;
                            }
                        }

                        span {
                            &.dollar-amount-input,
                            &.percent-amount-input {
                                &-container {
                                    position: relative;

                                    &:before {
                                        position: absolute;
                                    }
                                }
                            }

                            &.dollar-amount-input-container {
                                .form-control {
                                    float: right;
                                    padding: 6px 6px 6px 14px;
                                }

                                &:before {
                                    content: '$';
                                    left: 5px;
                                    top: 11px;
                                }
                            }

                            &.percent-amount-input-container {
                                .form-control {
                                    float: left;
                                }

                                &:before {
                                    content: '%';
                                    right: 7px;
                                    top: 11px;
                                }
                            }
                        }
                    }
                }
            }

            > tfoot {
                > tr {
                    > td {
                        border-top: none;
                    }
                }
            }

            .input-group {
                &-addon {
                    padding: 0 0 0 5px;
                    background-color: transparent;
                    border: none;
                    margin-left: 5px;
                }
            }
        }

        .section-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        change-buttons {
            display: block;

            &.show-border {
                padding-top: 8px;
                border-top: 1px solid $silver;
            }
        }

        new-beneficiary {
            // position: fixed;
            // top: 0;
            // left: 0;
            // right: 0;
            // bottom: 0;
            // z-index: 10000;
            // padding: 20px;
            // background: rgba(0, 0, 0, .8);
            // margin: auto;
            // @include bp(small) {
            //     margin-top: 63px;
            // }
            display: flex;
            align-items: center;

            .row1 {
                display: flex;
                justify-content: space-between;

                .name {
                    flex: 1 auto;
                }
            }
        }

        // .form-control {
        //     width: auto;
        // }

        .table {
            > thead {
                > tr {
                    > th:first-of-type {
                        width: 50%;
                    }
                }
            }

            > tbody {
                > tr {
                    > td {
                        // vertical-align: middle;
                        padding: 10px 8px;

                        &:first-of-type {
                            min-width: 450px;
                        }
                    }
                }
            }
        }

        .bene.select-wrapper {
            background-color: $white;

            // Firefox only fix
            select {
                &.ng-invalid {
                    color: $form-placeholder-text;
                    border-color: $cherry-dark;
                }

                option {
                    &:not(:first-of-type) {
                        color: $black;
                    }
                }
            }
        }

        .total {
            &-message {
                color: $chalkboard-dark;
                font-size: .85em;
                font-weight: 400;
                line-height: 14px;
                position: absolute;
                right: 8px;
                top: 30px;
            }

            &-header {
                background-color: transparent;

                .split-label {
                    font-weight: 400;
                }
            }
        }

        .bottom-totals {
            .total {
                &-message {
                    top: inherit;
                    bottom: 66px;
                    right: 22px;
                }
            }
        }

        .datepicker {
            width: 15%;

            label {
                margin-bottom: 5px;
            }

            &-container {
                position: relative;
                background: $white;

                .icon {
                    position: absolute;
                    top: 30%;
                    font-size: 18px;
                    left: 12px;
                }
            }
        }

        .ssn-verified {
            float: right;

            small {
                margin-left: 5px;
            }
        }

        phone-number-input {
            margin: 5px 0;
        }

        .distribution {
            &-option {
                margin-top: 19px;
            }
        }

        .settlement-option {
            margin-top: 14px;
        }

        .distribution-option {
            .form-control {
                background-color: transparent;
            }
        }

        small {
            &.additional-details {
                display: block;
            }
        }

        .address,
        .phone-number {
            p {
                margin: 0;
                font-size: 92%;
            }
        }

        .share-amount-input-container {
            .input-group {
                &-addon {
                    padding: 12px;
                    background-color: $sea-foam;
                    color: $silver;

                    &.active {
                        background-color: $sky-button;
                        color: $white;
                        font-weight: bold;
                    }

                    &:first-child {
                        border-top-left-radius: 15%;
                        border-bottom-left-radius: 15%;
                    }
                }
            }

            input {
                &.share-amount {
                    width: 90px;
                }
            }

            label:last-of-type {
                border-top-right-radius: 15%;
                border-bottom-right-radius: 15%;
            }
        }

        .share-amount-container {
            .btn {
                &.client-equal,
                &.equal-share {
                    width: 100%;
                    border-radius: 5px;
                    height: 46px;
                    line-height: 25px;
                }

                &.equal-share {
                    display: inline-block;
                    font-weight: bold;
                    position: relative;
                    overflow: hidden;

                    .equal,
                    .flat-amount {
                        position: absolute;
                    }

                    .equal {
                        left: 39%;
                        top: 10px;
                        @include transition(top .3s ease-in-out);
                    }

                    .flat-amount {
                        left: 15%;
                        bottom: -45px;
                        @include transition(bottom .3s ease-in-out);
                    }

                    &:hover {
                        .equal {
                            transition-delay: .25s;
                            top: -25px;
                        }

                        .flat-amount {
                            transition-delay: .25s;
                            bottom: 10px;
                        }
                    }
                }
            }

            &.client {
                .btn {
                    &.equal-share {
                        &.equal,
                        &.flat-amount {
                            transition: none;
                        }
                    }
                }
            }
        }

        .pml-list-view {
            &-title {
                label {
                    display: block;
                }
            }
        }

        .phone-container {
            margin-top: 26px;
        }

        .row {
            &.phone-input {
                margin-left: -1px;
                margin-right: -17px;
            }
        }

        dropdown {
            &.dist-dropdown {
                ul {
                    padding: 0 15px;
                }

                li {
                    border-top: 1px solid $silver;

                    &:first-of-type {
                        border: 0;
                    }
                }
            }
        }

        .client-final-bene-edit {
            .final-bene-options {
                input[type="radio"] {
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    float: none;
                    margin-top: 0;
                    vertical-align: middle;
                }

                label {
                    display: inline-block;
                }

                li {
                    margin: 10px 0;
                }
            }
        }
    }

    .beneficiary-header {
        &-controls {
            .slider-container,
            .datepicker {
                display: inline-block;
            }

            .slider-container {
                vertical-align: middle;
                margin-right: 10px;
            }
        }

        &-summary {
            p {
                margin: 0;
            }
        }
    }

    .beneficiary-details {
        .detail {
            display: inline-block;
            border-right: 1px solid $silver;
            padding-right: 15px;
            margin-right: 15px;
            vertical-align: top;
        }

        .beneficiary-name {
            display: block;
        }

        div:last-of-type {
            border-right: 0;
        }

        .phone-numbers-accordian {
            &.panel {
                background-color: transparent;
            }

            .panel {
                &-heading {
                    padding: 0;
                }

                &-title {
                    padding-bottom: 0;
                    border-bottom: 0;

                    a.accordion-toggle {
                        small {
                            color: $bright-link;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                &-body {
                    border-top: 0;
                }
            }
        }
    }

    btn.btn-default.bg-white {
        background-color: white;
    }
}

// .disabled {
//     background-color: $natural-light;
//     border-color: $silver;
//     cursor: pointer;

//     &.ng-invalid {
//         border-color: $silver;
//     }
// }