.replyable-requirements {
    .requirement {
        &-policies {
            .policy-row {
                font-weight: bold;
            }

            td {
                &.requirement {
                    padding: 0;

                    .requirement {
                        &-detail {
                            border-bottom: 1px solid $silver;
                            margin-bottom: 0;
                            width: 98%;
                            margin-left: 2%;

                            > thead {
                                &:first-child {
                                    > tr {
                                        border-bottom: 1px solid $silver;

                                        &:first-child {
                                            th {
                                                padding: 2px 8px;
                                                border: 0;
                                                // font-weight: normal;
                                                text-transform: uppercase;
                                                font-size: 12px;
                                            }
                                        }
                                    }
                                }

                                > tr {
                                    > th {
                                        background-color: $natural-medium;
                                    }
                                }
                            }

                            > tbody {
                                > tr {
                                    > td {
                                        padding: 8px;
                                        vertical-align: baseline;
                                        background-color: $natural-medium;
                                        width: 22%;
                                    }
                                }
                            }

                            .icon-column {
                                .icon-sort {
                                    &-up-arrow,
                                    &-down-arrow {
                                        font-size: 10px;
                                        font-weight: bold;
                                        cursor: pointer;
                                        transition: all .15s ease;
                                    }
                                }

                                .small-icon {
                                    &.open-note {
                                        transform: rotate(180deg);
                                    }
                                }
                            }

                            .req-row-note {
                                .note-body {
                                    padding: 5px 0;
                                    font-size: 12px;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .requirements {
        &-widget {
            cursor: default;
        }
    }
}