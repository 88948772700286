.client-list {
    .product-type-filter {
        // padding-right: 0;
    }

    &-container {
        .table {
            &.desktop {
                @include bp(1060px) {
                    display: none;
                }
            }

            &-mobile {
                > tbody {
                    > tr {
                        > td {
                            height: 70px;
                            padding: 0 15px;
                            vertical-align: middle;

                            &.fixed-header {
                                padding: 20px;
                            }

                            .filter-arrows-container {
                                float: right;
                            }
                        }
                    }
                }
            }

            > thead {
                &:first-child {
                    > tr {
                        &:first-child {
                            th {
                                &.owner {
                                    &-header {
                                        width: 25%;

                                        .th-contents {
                                            padding-left: 5px;
                                        }
                                    }
                                }

                                &.anniversary {
                                    &-header {
                                        width: 18%;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            > tbody {
                > tr.firstLine {
                    td {
                        border-top: 1px solid $natural-dark;
                    }
                }
            }

            &.general-details-table {
                tbody {
                    tr {
                        td {
                            border-bottom: 1px solid $silver;
                        }
                    }
                }
            }
        }
    }

    filter-bar {
        display: flex;
        padding-top: .50em;
        padding-bottom: .50em;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        @include bp($medium) {
            flex-direction: column;
            justify-content: normal;
            align-items: inherit;
        }

        @include bp(1070px) {
            align-items: stretch;
        }

        > filter {
            flex-basis: 25%;
            align-items: center;
            padding-right: 2em;
            @include bp($medium) {
                flex-basis: 100%;
                padding-right: 0;
            }

            @include bp(1070px) {
                padding-right: 0;
            }

            @include bp(620px) {
                padding: 0;
            }


            // > label {
            //     margin-right: .5em;
            // }

            > select {
                padding: .25em;
                // width: 3em;
            }

            &.holding-status {
                &-filter {
                    flex-basis: 34%;
                    @include bp(1199px) {
                        flex-basis: 45%;
                    }
                }
            }
        }

        .btn {
            margin-top: 7px;
            height: 100%;
        }
    }

    &.all-policies {
        .table {
            > thead {
                > tr {
                    > th {
                        &.value {
                            .th-contents {
                                left: 55%;
                            }
                        }
                    }
                }
            }

            > tbody {
                > tr {
                    &.highlight {
                        border-top: 1px solid $silver;
                    }

                    > td {
                        &.owner {
                            width: 23%;
                        }

                        &.pol-number {
                            width: 10%;
                        }

                        &.value {
                            width: 12%;
                        }

                        &.db {
                            width: 12%;
                        }

                        &.insured-annuitant {
                            width: 23%;
                        }

                        &.lob {
                            width: 5%;
                        }

                        &.product {
                            width: 5%;
                        }

                        &.anniversary {
                            width: 10%;
                        }
                    }
                }
            }

            &-mobile {
                @include bp(1160px) {
                    display: block;
                }
            }
        }

        filter-bar {
            padding-top: 10px;
            @include bp(1070px) {
                flex-direction: column;
            }

            > filter {
                flex-basis: 50%;
                @include bp(1070px) {
                    flex-basis: 100%;
                }

                &.product-type {
                    &-filter {
                        @include bp(1070px) {
                            flex-basis: 100%;
                        }
                    }
                }

                &.issue-state-filter {
                    flex-basis: 56%;
                }
            }

            .form-control {
                height: 30px;
                padding: 0 5px;
                font-size: 13px;
            }

            .select-wrapper {
                &:before {
                    top: 4px;
                }
            }
        }

        paging-bar {
            .form-control {
                height: 30px;
                padding: 0;
                font-size: 13px;
            }

            .select-wrapper {
                width: 60%;
            }

            pml-list-view-page-sizes {
                width: 35%;

                label,
                .select-wrapper {
                    display: inline-block;
                }

                .select-wrapper {
                    width: 40%;
                }
            }

            .pager-jump-to {
                width: 35%;
                @include bp($small) {
                    width: 100%;
                }

                .form-control {
                    text-align: center;
                }

                label {
                    display: inline-block;
                }

                input {
                    width: 30%;
                }
            }

            .pager-buttons-wrap {
                padding-top: 0;

                .btn {
                    &-default {
                        width: 30px;
                        height: 30px;
                        font-size: 11px;
                        padding: 0;
                        margin-left: 10px;
                    }
                }
            }
        }

        filter-bar,
        paging-bar {
            .select-wrapper {
                &:before {
                    top: 4px;
                }
            }
        }

        .e-app-link {
            a {
                &:after {
                    content: '\e9023';
                    font-family: 'icomoon';
                    margin-left: 10px;
                    font-size: 16px;
                }
            }

            &:before {
                content: '|';
            }
        }
    }

    .view-select-list {
        display: inline-block;

        li {
            width: inherit;
            font-size: 18px;
            padding-left: 10px;
            padding-right: 10px;
            cursor: pointer;

            &:before {
                display: block;
            }
        }
    }

    .download-link {
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
        color: $bright-link;
        text-decoration: underline;
        padding-top: 14px;

        &:hover {
            text-decoration: none;
        }

        // &:before {
        //     content: '\e66d';
        //     font-family: 'icomoon';
        //     font-size: 24px;
        //     margin-right: 10px;
        // }
    }

    .mobile-table-container {
        @include bp(1060px) {
            display: block;
        }
    }

    .pending-filters,
    .notpaid-filters {
        .product-type-filter {
            padding-right: 2em;
            @include bp(1070px) {
                padding-right: 0;
            }
        }

        .issue-state-filter {
            margin-left: 0;
        }

        filter-bar {
            .download-link {
                margin-left: 10px;
                padding-top: 0;
                @include bp(1070px) {
                    text-align: left;
                    margin-left: 0;
                }
            }
        }
    }

    .notpaid-filters {
        .delivery-status-filter {
            flex-basis: 63%;
        }

        .holding-status-filter {
            flex-basis: 37%;
        }

        .issue-state-filter {
            padding-right: 0;
        }

        .app-method-filter {
            flex-basis: 35%;
        }

        .product-type-filter {
            flex-basis: 55%;
        }
    }

    .unsubmitted-filters {
        filter-bar {
            > filter {
                &.status-filter {
                    flex-basis: 77%;
                }

                &.app-types-filter {
                    flex-basis: 57%;
                }
            }
        }
    }

    .inforce-filters {
        .product-type-filter {
            flex-basis: 60%;
        }
    }

    .pending-filters {
        .issue-state-filter {
            padding-right: 0;
        }
    }
}