.user-settings {
    .fp-dropdown {
        margin-top: 15px;
        margin-bottom: 8px;

        > label {
            margin-right: 4px;
        }
    }

    .time-dropdown {
        margin-bottom: 15px;

        label {
            margin-right: 4px;
        }

        small {
            margin-left: 8px;
        }
    }

    .alert {
        a {
            color: $white;
            text-decoration: underline;
            font-weight: bold;
        }

        button.close {
            position: absolute;
            right: 10px;
            top: 15px;
            opacity: 1;
        }
    }
}