.dashboard-container {
    background: $natural;
    padding-bottom: 50px;

    .error-msg {
        min-height: 212px;
        display: table;
        width: 100%;

        .title {
            display: table-cell;
            vertical-align: middle;
            color: $white;
        }
    }

    .spinner-small-container {
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        .spinner {
            bottom: 0;
            top: 0;
            margin: auto;
            left: 0;
            right: 0;
            -webkit-transform: none;
            -moz-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none;
        }
    }

    [class*='dashboard-section-'] {
        h2 {
            color: $chalkboard-dark;
            font-family: "Open Sans", sans-serif;
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
            margin: 0 0 20px;
        }

        .char-bar-details,
        .char-donut-details, .char-funds-details, .char-map-details {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 60;
            text-decoration: none;

            .icon {
                color: rgba($white, 0.5);

                &:before {
                    font-size: 24px;
                }

            }
        }

        .char-map-details, .char-funds-details {
            right: 15px;
            top: 10px;
            .icon {
                color: $cement;
            }
        }

    }

    .chart-box {
        background-color: $white;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07);
        border: solid 1px #e7e7e7;
        margin-left: -7.5px;
        margin-right: -7.5px;
        padding: 30px;
        overflow: hidden;
    }

    .dashboard-section-1 {
        @include section-top-info;
        padding: 35px 0 0;

        .chart-donut-container {
            padding: 0 20px;
            position: relative;
            min-height: 277px;

            &:after {
                border-right: 1px solid rgba(#e7e7e7, 0.2);
                content: '';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                right: -15px;
            }

            .char-donut-details {
                color: rgba($white, 0.5);
                padding: 0;
            }

            .chart-donut-content {
                padding: 0 0 25px;
                position: relative;
                //max-width: 300px;
                //margin: 0 auto;
                min-height: 250px;
                display: inline-block;

                .total-label {
                    position: absolute;
                    top: 60%;
                    left: 31.66666666666667%;
                    z-index: 50;
                    right: auto;

                    span {
                        color: rgba($white, 0.5);
                        font-family: "Open Sans", sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }

                .c3-chart-arcs-title {
                    fill: $white;
                    font-size: 30px;
                    font-family: 'Open Sans', sans-serif;
                    font-weight: 100;

                    &:before {
                        content: 'Total';
                        display: block;
                    }
                }

                .c3-chart-arc {
                    path {
                        stroke: none;
                        stroke-linejoin: $white;
                    }

                    text {
                        font-size: 12px;
                    }
                }

                .c3-legend-item {
                    fill: $white;
                    font-family: 'Open Sans', sans-serif;
                    font-weight: 100;
                }
            }

            h2 {
                color: $white;
                font-size: 18px;
                line-height: 24px;
                margin: 0 0 20px;
                //width: 75%;
                text-align: left;
            }

            &.chart-donut-1 {
                .chart-donut-content {
                    .total-label {
                        left: 31.66666666666667%;
                    }
                }
            }
            &.chart-donut-2 {
            }
        }

        .progress {
            width: 135px;
            background: rgba($white, 0.2);

            &, .progress-bar {
                height: 4px;
            }

            .progress-bar {
                background: $kelly;
            }
        }

        .total-disclosure {
            padding: 0 20px 0;
            margin-top: 0px;
            opacity: 0.5;
            font-size: 9px;
            line-height: 1.2;
            color: $white;
        }

        .total-number {
            padding: 0 20px 0;

            hr {
                margin: 0;
                opacity: 0.2;
            }

            h2,
            small {
                font-family: "Open Sans", sans-serif;
            }

            h2 {
                color: $white;
                font-size: 34px;
                font-weight: 100;
                line-height: 100%;
                margin-bottom: 5px;
            }

            &.total-policies {
                h2 {
                    font-size: 54px;
                    margin-top: 20px;
                }
            }

            small {
                color: rgba($white, 0.5);
                font-size: 14px;
                line-height: 19px;
                padding: 0px 0 26px;
                display: inline-block;
                vertical-align: top;
            }

            .error-msg {
                min-height: 106px;
            }
        }
    }

    .dashboard-section-2 {
        padding: 15px 0;

        .error-msg {
            min-height: 160px;

            .title {
                color: $cherry;
            }
        }

        .chart-pie-container {
            position: relative;
            height: 300px;
            text-align: center;

            h2 {
                text-align: left;
            }

            .chart-pie-content {
                display: inline-block;
            }

            .legend-title {
                position: absolute;
                top: 31%;
                right: 24%;
            }
        }
    }

    .dashboard-section-3 {
        .chart-bar-container {
            height: 480px;

            .char-bar-details {
                color: $cement-medium;
            }

            #funds-policies-bar-chart,
            #funds-policies-bar-chart-mobil {
                svg {
                    display: inherit;
                    margin: 0 auto;
                }

            }
        }

        .chart-map-container {
            height: 480px;

            h2 {
                margin: 0;
            }

            .dropdown {

                & > a {
                    display: inline-block;
                    text-decoration: none;
                    vertical-align: top;
                    height: 24px;
                }

                .glyphicon {
                    font-size: 1.2rem;
                    font-weight: bold;
                }

                .dropdown-menu {
                    left: 0;
                    right: auto;
                    top: 30px;
                    width: 200px;
                    height: 300px;
                    overflow: auto;
                    position: absolute;

                    -webkit-box-shadow: $box-shadow;
                    -moz-box-shadow: $box-shadow;
                    box-shadow: $box-shadow;

                    a {
                        text-decoration: none;
                    }
                }
            }

            .chart-map-filters {
                float: right;

                .char-map-filter {
                    color: $chalkboard-dark;
                    font-family: "Open Sans", sans-serif;
                    font-size: 14px;
                    font-weight: 100;
                    padding: 7px 10px;
                    line-height: 1;

                    &.badge {
                        color: $white;
                        background: $sky;
                    }
                }
            }

            .chart-map-content {
                padding: 20px 0 0;
                min-height: 120px;
                position: relative;

                .background {
                    fill: none;
                    pointer-events: all;
                }

                .states {
                    fill: #aaa;

                    .state {
                        stroke: $white;

                        &.hover {
                            stroke: #38b8ff;
                            stroke-width: 1.5px;
                        }

                        &.selected {
                            fill: $cherry;
                            stroke: $white;
                        }
                    }
                }
            }

            #state-borders {
                fill: none;
                stroke: rgba($white, 0);
                stroke-width: 1px;
                stroke-linejoin: round;
                stroke-linecap: round;
                pointer-events: none;

            }

            #canvas {
                position: relative;
            }

            #state-policies-map,
            #state-policies-map-mobil {
                svg {
                    display: inherit;
                    margin: 0 auto;
                }
            }

            #state-policies {
                padding: 15px 0 0;
                text-align: center;
                min-height: 70px;

                .state-policies-content {
                    display: inline-block;

                    .state-police {
                        display: inline-block;
                        margin: 0 15px;

                        h2 {
                            color: $sky;
                            float: none;
                            font-family: "Open Sans", sans-serif;
                            font-size: 34px;
                            line-height: normal;
                            margin: 0;
                        }

                        p {
                            color: $cement-medium;
                            font-family: "Open Sans", sans-serif;
                            font-size: 14px;
                            line-height: 20px;
                            margin: 0;
                        }
                    }
                }
            }

        }
    }
}

@media only screen and (max-width: 991px) {
    .dashboard-container {
        padding-bottom: 0;

        .separator-horizontal-line {
            hr {
                border: 0;
                border-top: 1px solid rgba(231, 231, 231, 0.2);
                margin: 0;
            }
        }

        [class*='-carousel-container'] {
            padding-bottom: 50px;

            .carousel {
                height: 255px;
            }

            .carousel-control {
                display: none;
            }

            .carousel-indicators {
                bottom: -35px;
                margin-bottom: 0;

                li {
                    margin: 0 2.5px;

                    &.active {
                        margin: 0 2.5px;
                        width: 10px;
                        height: 10px;
                    }
                }

            }
        }

        .total-number-carousel-container {
            .carousel {
                height: 140px;
            }
        }

        .chart-pie-carousel-container {
            &.chart-box {
                padding: 30px 0 60px;
            }
        }

        .dashboard-section-1 {
            padding: 30px 0 20px;

            .chart-donut-container {
                padding: 0;

                &:after {
                    display: none;
                }

                .char-donut-details {

                }

                .chart-donut-content {
                    padding: 0;
                }

                h2 {
                    margin: 0 15px 20px;
                    text-align: center;
                }

                &.chart-donut-1,
                &.chart-donut-2 {
                    .chart-donut-content {
                        .total-label {
                            left: 0;
                            top: 50.5%;
                            display: inline-block;
                            right: 0;
                            margin: auto;
                        }
                    }
                }
            }

            .progress {
                width: 135px;
                margin: 0 auto 8px;
            }

            .total-disclosure {
                margin-top: 5px;
            }

            .total-number {
                padding: 0;

                &.total-face {
                    padding-top: 18px;
                }

                h2,
                small {
                    font-family: "Open Sans", sans-serif;
                }

                h2 {
                    color: $white;
                    font-size: 30px;
                    font-weight: 400;
                    line-height: 100%;
                    margin: 20px 0 5px;
                }

                &.total-policies {
                    padding-top: 0;

                    h2 {
                        margin: 20px 0 5px;
                    }

                    small {
                        padding: 0;
                    }
                }

                small {
                    color: rgba($white, 0.5);
                    font-size: 14px;
                    line-height: 20px;
                    display: inline-block;
                    vertical-align: top;
                }
            }

        }

        .dashboard-section-2 {
            padding: 0;
            margin-top: 15px;
            margin-bottom: 15px;

            .chart-pie-container {

                h2 {
                    margin: 0 15px 20px 15px;
                    text-align: center;
                }

                .chart-pie-content {
                    display: inline-block;
                    margin: 0 auto;
                    position: relative;

                    .legend-title {
                        left: 6.600660066006601%;
                        right: auto;
                        top: auto;
                        bottom: 35px;
                    }
                }

            }

            .carousel-indicators {
                bottom: -40px;

                li {
                    background: $silver-medium;
                    border-color: $silver-medium;

                    &.active {
                        background: $cement-medium;
                        border-color: $cement-medium;
                    }
                }
            }
        }

        .dashboard-section-3 {
            padding: 0;
            margin-top: 15px;
            margin-bottom: 15px;

            h2 {
                margin: 0 15px 20px 15px;
                text-align: center;
            }

            .chart-box {
                margin-bottom: 15px;
                padding: 30px 15px;
                height: auto;
                min-height: 200px;
            }

            .chart-bar-container {
                &.chart-box {
                    padding: 30px 0 25px;
                }

                .chart-bar-content {
                    max-width: 300px;
                    margin: 0 auto;
                }
            }

            .chart-map-container {
                h2, .chart-map-filters {
                    text-align: center;
                }

                .chart-map-content {
                    #state-policies {
                        .state-policies-content {
                            .state-police {
                                display: block;
                            }
                        }
                    }
                }

            }

        }

    }
}
