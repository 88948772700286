html {
    height: 100%;
    font-family: 'Open Sans', sans-serif;

    body {
        -ms-overflow-style: scrollbar;
        background-repeat: no-repeat;
        height: 100%;
        min-width: 320px;
        min-height: 320px;
        position: relative;

        .fix-fixed-bg {
            position: absolute;
            background-color: #012735;
            width: 100%;
            height: 62px;
        }

        &.login .fix-fixed-bg {
            display: none;
        }

        .fixfixed {
            position: absolute;
            -webkit-transition: all linear 2s;
            transition: all linear 2s;
        }

        .fixfixed {
            position: absolute;
        }

        .main-view-container {
            padding-top: 62px;
            @include bp(small) {
                padding-top: 80px;
            }

            &.client {
                padding-top: 62px;
            }

            &.global-nav {
                padding-top: 105px;
            }
        }

        .app-switcher {
            &.mobile {
                position: fixed;
                display: block;
                width: 50px;
                height: 50px;
                background-color: $common-nav-underline;
                border-radius: 50%;
                right: 20px;
                bottom: 20px;

                &:before {
                    font-size: 30px;
                    position: absolute;
                    left: 10px;
                    top: 10px;
                }
            }

            &-container {
                &.mobile {
                    &.open {
                        & > .dropdown-menu {
                            opacity: 1;
                            visibility: visible;
                        }

                        .icon {
                            &:before {
                                content: '\e615';
                            }
                        }
                    }

                    .dropdown {
                        &-menu {
                            position: fixed;
                            width: 55%;
                            top: auto;
                            right: 15px;
                            bottom: 10%;
                            visibility: hidden;
                            opacity: 0;
                            display: block;
                            padding: 36px;
                            @include transition(opacity .25s ease);

                            h2 {
                                font-family: 'TiemposHeadline';
                                font-size: 18px;
                                font-weight: 500;
                                letter-spacing: 0;
                                line-height: 20px;
                                display: inline-block;
                                padding-bottom: 2px;
                                cursor: pointer;
                            }

                            p {
                                font-family: 'Pragmatica';
                                font-size: 14px;
                                font-weight: 300;
                                letter-spacing: 0;
                                line-height: 20px;
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

a {
    &:hover {
        cursor: pointer;
    }
}

iframe {
    border: none;
}

.menu-listener {
    position: fixed;
    height: 100%;
    width: 10px;
    z-index: 1005;
    right: 0;
}

#mfPreviewBarShow,
#mfPreviewBar {
    display: none !important;
}

.nav,
.pagination,
.carousel,
.panel-title a {
    cursor: pointer;
}

.main-container {
    // position: relative;
    // min-height: calc(100% - 108px);

    @include bp(xsmall) {
        min-height: calc(100% - 128px);
    }

    // &.expand {
    //     min-height: 100%;
    // }
}

.conn-status {
    background-color: #015d82;
    color: $white;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    height: 30px;
    width: 100%;
    top: 0;
    position: fixed;
    z-index: 2000;

    & + .main-container {
        margin-top: 30px;
    }
}

.gutter {
    &-none {
        padding-left: 0;
        padding-right: 0;
    }

    &-left-none {
        padding-left: 0;
    }

    &-right-none {
        padding-right: 0;
    }
}
