.requirements-widget {
    line-height: 20px;
    cursor: pointer;

    @include bp(medium) {
        line-height: 16px;
    }

    label {
        cursor: pointer;
    }

    .req-text,
    .widget-info,
    .widget-text,
    .widget-content {
        display: inline-block;
        height: 20px;
    }

    .widget-text {
        width: 41px;
        height: 20px;
        margin-left: 10px;
        font-weight: 600;
        color: $white;
        font-size: 12px;
        text-align: center;
        border-radius: 20px;
        line-height: 20px;
        position: relative;
        top: -1px;

        &.req-alerts {
            background-color: $cherry;
        }

        &.req-completed {
            background-color: $kelly;
        }

        &.req-progress {
            background-color: #f5a623;

            &.req-initial {
                background-color: #c2c2c2;
            }
        }
    }
}

.requirements-alerts {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: $natural-medium;

    @include bp(medium) {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.requirements {
    &-view-container {
        .pending-requirement-box {
            label {
                display: block;
            }

            .status {
                padding: 2px 10px;
                margin-left: -10px;
                display: inline-block;

                &-ordered
                &-pending,
                &-received {
                    background-color: $apricot;
                    color: $white;
                    border-radius: 11px;
                }

                &-replied {
                    background-color: $kelly;
                    color: $white;
                    border-radius: 11px;
                }
            }

            textarea {
                width: 100%;
                height: 8em;
                margin: 15px 0;
                resize: none;
            }

            .btn {
                &-send {
                    margin-left: .5em;
                }

                &-reply {
                    margin: 15px 0;
                }
            }

            .reply-success-message {
                display: block;
                margin-top: 15px;
                font-size: 20px;
            }
        }
    }
}
