.tooltip {
    z-index: 1000;

    &-inner {
        max-width: 232px;
        background: $white;
        color: $chalkboard-dark;
        border: 1px solid $sky;
        padding: 10px 15px;
        text-align: left;
    }

    &.in {
        opacity: 1;
    }

    &.top {
        .tooltip {
            &-arrow {
                border-top-color: $sky;
            }
        }
    }

    &-icon {
        position: relative;
        color: $sky;
        width: 14px;
        display: inline-block;

        &:hover {
            cursor: pointer;
        }
    }

    p {
        margin-top: 0;
    }
}

label {
    & + .tooltip-icon {
        position: absolute;
        right: 16px;
        top: 5px;
    }
}