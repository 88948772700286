client-card {
    // cursor: pointer;
    position: relative;

    p {
        margin: 0;
    }

    .panel {
        background-color: transparent;
        margin-top: 42px;

        &-container {
            background-color: $white;
            border: 1px solid $silver;
            min-height: 350px;
            box-shadow: none;
            // @include transition(box-shadow .25s ease);
            // @include transition(transform .15s ease);

            // &:hover {
            //     box-shadow: $box-shadow;
            //     transform: scale(1.01);
            // }
        }

        &-heading {
            padding: 10px 5px;
            position: relative;
            margin-bottom: 75px;

            .dropdown {
                text-align: right;

                &-toggle {
                    transform: rotate(90deg);
                    display: inline-block;
                }
            }
        }

        &-body {
            padding-top: 0;
            margin-bottom: 45px;

            label {
                margin-top: 15px;
            }

            .zip-four {
                &:before {
                    content: '\2013';
                    margin-right: 2px;
                }
            }
        }

        &-footer {
            bottom: 0;
            position: absolute;
            width: 100%;
            left: 0;
        }
    }

    .name-details {
        text-align: center;
        width: 100%;
        position: absolute;
        top: -40px;

        .icon {
            width: 75px;
            height: 75px;
            border-radius: 50%;
            display: block;
            margin: 0 auto;
            background: $sky;
            color: $white;
            font-size: 35px;
            position: relative;

            &:before {
                position: absolute;
                top: 15px;
                right: 0;
                left: 0;
            }

            &.role-edit {
                background: transparent;
                width: inherit;
                height: inherit;
                border-radius: inherit;
                color: $bright-link;
                display: inline-block;
                font-size: 17px;
                cursor: pointer;

                &:before {
                    top: 0;
                    right: -15px;
                    left: inherit;
                    position: absolute;
                }
            }
        }

        h5 {
            margin-bottom: 0;
        }
    }

    .address {
        margin-bottom: 12px;

        span {
            display: block;

            &.zip-four {
                display: inline-block;
            }
        }
    }

    .phone-numbers {
        margin-bottom: 12px;

        p {
            margin-bottom: 0;
        }

        small {
            &.more-link {
                color: $sky;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .client-card {
        &.green {
            .name-details {
                .icon {
                    background: $kelly;
                }
            }
        }

        &.orange {
            .name-details {
                .icon {
                    background: $apricot-dark;
                }
            }
        }

        &.red {
            .name-details {
                .icon {
                    background: $cherry;
                }
            }
        }

        &.blue {
            .name-details {
                .icon {
                    background: $sky;
                }
            }
        }
    }

    .ssn {
        display: inline-block;
        position: relative;

        &.verified {
            &:after {
                content: '\e907';
                font-family: 'icomoon';
                color: $kelly;
                right: -20px;
                position: absolute;
            }
        }
    }
}