dropdown {
    position: relative;
    border: 1px solid $silver;
    display: inline-block;
    width: 100%;

    &:focus,
    &:focus-visible {
        border: 1px solid $sky;
        outline: none;
    }

    &.ng-touched.ng-invalid {
        border-color: $cherry;
    }

    &.ng-touched.ng-valid {
        border-color: $kelly;
    }

    &.ng-touched.ng-invalid,
    &.ng-touched.ng-valid {
        &:focus,
        &:focus-visible {
            border: 1px solid $sky;
            outline: none;
        }
    }

    .dropdown {
        &:focus-visible {
            outline: none;
        }

        &-selection {
            width: 100%;
            vertical-align: middle;
            overflow: hidden;
            text-overflow: ellipsis;
            background: $white;
            cursor: pointer;
            padding: 8px 15px;

            &.disabled {
                opacity: 50%;

                .dropdown-selection {
                    cursor: not-allowed;
                }
            }
        }

        &-button {
            border-width: 0;
            flex: none;
            aspect-ratio: auto;
            padding: 13px 15px;
            color: $chalkboard-dark;
            background-color: $white;

            &:hover,
            &:focus {
                color: $chalkboard-dark;
            }

            &:focus {
                box-shadow: inset 0 0 8px rgba(0, 0, 0, .15);
            }

            .icon {
                display: block;
                transition: all .15s linear;
            }
        }

        &-list {
            position: absolute;
            width: 100%;
            background: $white;
            transition: all linear .5s;
            opacity: 1;
            z-index: 20000;
            box-shadow: 0 0 15px 0 $shadow;
            top: 50px;

            &.ng-hide {
                opacity: 0;
            }

            li {
                padding: 10px;
                cursor: pointer;
                transition: all .25s linear;

                &:hover,
                &:focus {
                    background: $natural-medium;
                }

                &:focus-visible {
                    outline: 0;
                }
            }
        }

        &.disabled {
            opacity: 50%;

            .dropdown-selection {
                cursor: not-allowed;
            }
        }
    }

    &.list-box {
        border: 0;

        .dropdown-list {
            position: relative;
            top: 0;
            background-color: transparent;
            box-shadow: none;
            z-index: auto;

            li {
                padding: 5px 10px;
                border: 1px solid $silver;
                margin: 5px 0;

                &.active {
                    background: $silver;
                }
            }
        }
    }
}

select.dropdown {
    -moz-appearance: none;

    &::-ms-expand {
        display: none;
    }
}

.dropdown {
    cursor: pointer;
}

.dropdown-menu {
    padding: 0;
    border: none;
    cursor: default;

    & > li {
        & > a,
        & > button {
            padding: 10px 20px;
            transition: background-color .20s linear;

            &:hover {
                background-image: none;
                background-color: $natural-medium;
            }
        }
    }
}

.dropdown-title {
    background-color: $natural-medium;
    padding: 15px 20px;
    line-height: 26px;
    font-size: 16px;
    font-weight: bold;
}

.small-icon {
    display: inline-block;
}

.dropdown {
    .circle {
        position: absolute;
        right: 11px;
        top: -45px;
        width: 8px;
        height: 8px;
        background-color: $cherry;
        border-radius: 4px;

        &.mobile {
            padding: 0;
            right: 0;
        }
    }

    &.open {
        display: block;

        .small-icon {
            @include transform(rotate(180deg));
        }
    }

    .small-icon {
        padding: 0;
        margin-left: 5px;
        @include transition(all .15s ease);
    }

    .dropdown-menu {
        width: 320px;
        left: auto;
        right: 0;
        top: 50px;
        padding: 0;
        margin: 0;
        background-color: rgba(255, 255, 255, .99);
        text-align: left;
        -webkit-box-shadow: $box-shadow;
        box-shadow: $box-shadow;
        z-index: 2;

        &.left-menu {
            right: inherit;
            left: 0;
        }

        .title {
            font-size: 16px;
            line-height: 26px;
            color: $chalkboard-dark;
            padding: 15px 20px;
            background-color: #e5e5e5;
            font-weight: 600;
        }

        .item-alerts {
            background-color: $natural-medium;
            text-transform: none;

            .count {
                color: $cement-medium;
                line-height: 18px;
            }
        }

        ul {
            list-style: none;
            padding: 0;
            margin-bottom: 0;

            li {
                padding: 14px 20px;
                color: $chalkboard-dark;
                border-top: solid 1px $natural-medium;
                font-size: 14px;
                min-height: 30px;
                line-height: 20px;
                cursor: pointer;
                text-transform: capitalize;
                overflow: hidden;
                @include transition(all .25s ease);

                &:first-child {
                    border-top: none;
                }

                &.li-with-btn {
                    padding-top: 14px;
                    padding-bottom: 14px;

                    &:hover {
                        background-color: inherit;
                    }
                }

                &.li-with-btn-primary {
                    padding: 20px;

                    .btn {
                        height: 46px;
                        font-size: 14px;
                    }
                }

                @include bp(medium) {
                    &:hover {
                        background-color: $natural-medium;
                    }

                    &.product-menu {
                        left: -10px;
                        top: 30px;
                    }

                    &.li-with-btn {
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                }

                .search-box {
                    margin: 0;
                    border-radius: 0;
                }

                .search-list {
                    height: 343px;
                }

                .cat-name {
                    display: inline-block;
                    float: left;
                    width: calc(100% - 80px);
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    padding-left: 10px;

                    @include bp(small) {
                        width: auto;
                    }

                    @include bp(xsmall) {
                        width: 80%;
                    }
                }

                .count {
                    float: right;
                    color: $chalkboard-dark;
                    max-width: 60px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    text-align: right;
                }

                &.text-danger {
                    color: $cherry;
                }
            }
        }

        .filter-list {
            max-height: 350px;
            overflow: scroll;
            @include bp(medium) {
                max-height: inherit;
                overflow: visible;
                display: table;
                table-layout: fixed;
                width: 100%;
            }

            ul {
                vertical-align: top;
                display: table-cell;
            }

            @include bp(small) {
                ul {
                    display: block;
                }
            }
        }

        .filter-column {
            &:first-child {
                border-bottom: 1px solid $natural-medium;
                @include bp(medium) {
                    border-bottom: 0;
                }
            }
        }

        .btn-group {
            float: right;
            padding: 20px;

            .btn {
                &:first-child {
                    margin-right: 10px;
                }
            }
        }

        .close {
            top: 15px;
        }

        &.columned {
            min-width: 650px;

            @include bp(small) {
                min-width: auto;
            }
        }
    }
}

@include bp(small) {
    .dropdown .dropdown-menu,
    .policy-relatives-menu .dropdown .dropdown-menu {
        top: 0;
        right: auto;
        width: calc(100% - 50px);
        z-index: 1;
    }

    .dropdown .dropdown-menu.product-menu {
        width: 100%;

        .dropdown-title button.close {
            top: 15px;
            right: 20px;
        }
    }

    .agent-menu-container .agent-menu {
        ul li .cat-name {
            width: 100%;
            padding-right: 20px;
        }
    }

    .dropdown-fw-mobile .dropdown-fw-mobile-menu {
        left: 0;
        top: 0;
        position: fixed;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        min-width: inherit;
        overflow-y: auto;
        z-index: 10000;
        margin-left: 0;

        .close {
            font-size: 24px;
            line-height: 50px;
            color: $cement-medium;
            opacity: 1;
            position: absolute;
            top: 16px;
            right: 20px;
            font-weight: 300;

            &:focus,
            .icon-close:focus {
                outline: none;
            }
        }

        .dropdown-title,
        .title {
            font-size: 18px;
            line-height: 24px;
            padding: 28px 0 28px 20px;
            text-align: left;
        }

        ul {
            li {
                padding: 21px 20px;
                font-size: 16px;
                line-height: 22px;
                overflow: hidden;
                min-height: 48px;

                &:first-child {
                    border-top: solid 1px $natural-medium;
                }

                &.item-alerts {
                    .count {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }

                .cat-name {
                    display: inline-block;
                    width: calc(100% - 90px);
                }
            }
        }
    }
}

.compensation-container {
    .filter-section-dropdown {
        padding-left: 15px;
    }

    .dropdown {
        .dropdown-menu {
            width: 400px;

            ul {
                li {
                    .cat-name {
                        width: auto;
                    }
                }
            }

            &.left-menu {
                left: 15px;
            }
        }

        .small-icon {
            display: inline-block;
            padding: 0;
            margin-left: 5px;
            @include transition(all .15s ease);
        }

        &.open {
            .small-icon {
                @include transform(rotate(180deg));
            }
        }
    }
}

.correspondence-container {
    .filter-section-dropdown {
        /* TODO do the same for compensation when that's markup has changed too. */
        margin-top: .5em;
    }
}

.filter-section-dropdown + .filter-section-dropdown {
    margin-left: 2em;
}