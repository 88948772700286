.alert {
    padding: 14px 54px 14px 20px;
    position: relative;

    @include bp(small) {
        text-align: left;
    }

    &-warning {
        background-color: $apricot-dark;
        border-color: $apricot-dark;
    }

    &-danger {
        background-color: $cherry;
        border-color: $cherry;
    }

    &-danger,
    &-warning,
    &-success,
    &-info {
        &.alert-dismissible {
            .close {
                /* all the specificity to override bootstrap */
                font-size: 18px;
                line-height: 0;
                top: 23px;
            }
        }
    }

    &-dropdown {
        .cat-name {
            text-transform: none;

            &:after {
                content: '\e61d';
                font-family: 'icomoon';
                position: relative;
                right: 0;
                font-size: 11px;
                @include transition(all .15s ease);
            }
        }

        .all-alerts {
            .cat-name {
                font-weight: bold;

                &:after {
                    content: '';
                }
            }
        }

        .alert-item {
            &:hover {
                .cat-name {
                    &:after {
                        right: -5px;
                    }
                }
            }
        }
    }
}

alert-nav-menu {
    .close {
        color: black;
    }
}