wg-swipe-container {
    position: relative;
    display: block;

    &:last-child .alert-content-box {
        border-bottom: none;
    }
}

wg-swipe-content {
    display: block;
    transform: translate3d(0px, 0px, 0px);
}

wg-swipe-option {
    position: absolute;
    width: 104px;
    top: 0;
    height: 100%;
    text-align: center;
    background-color: #BDBDBD;
    color: $white;

    &.red {
        background-color: $cherry;
    }

    > * {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
    }
}
