.addresses-container {
    table {
        margin-top: 30px;

        > tbody {
            > tr {
                > td {
                    &.address {
                        span {
                            display: block;
                            text-transform: capitalize;

                            &.zip-four {
                                display: inline-block;

                                &:before {
                                    content: '\2013';
                                    margin-right: 2px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .table > tbody > tr > td {
        padding-bottom: 10px;
    }
}

.addresses-policy-container {
    table {
        margin-top: 0;
    }

    .table > tbody > tr > td {
        padding: 0;
    }
}
