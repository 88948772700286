.datepicker {
    width: 100%;

    button.uib-title {
        width: 100%;
    }

    .form-control {
        background-color: transparent;
        padding-left: 40px;
        max-width: none;

        &.btn-container {
            vertical-align: bottom;
        }
    }

    .datepicker-container {
        background: $white;

        &.from,
        &.to {
            overflow: hidden;
            background-size: 33px;

            > i {
                position: absolute;
                top: 50%;
                left: 10px;
                transform: translateY(-50%);
                font-size: 18px;
            }
        }

        &.to {
            &.mobile {
                .dropdown-menu {
                    right: 0 !important;
                    left: initial !important;
                }
            }
        }
    }

    .dropdown-menu {
        outline: none;
        border: none !important;
        -webkit-box-shadow: $box-shadow !important;
        -moz-box-shadow: $box-shadow !important;
        box-shadow: $box-shadow !important;
        margin-top: 10px;
        padding: 20px;

        &:focus,
        li,
        li:focus,
        table {
            outline: none;
            border: none !important;
            -webkit-box-shadow: none !important;
            -moz-box-shadow: none !important;
            box-shadow: none !important;
            width: 100%;
        }

        table {
            th {
                &.text-center {
                    padding: 10px 0 5px;

                    small {
                        color: $sky;
                        font-weight: bold;
                        font-size: 14px;
                    }
                }
            }

            &.month {
                th {
                    padding-bottom: 15px;
                }
            }
        }
    }

    .btn-default {
        background-color: $white;
        color: $chalkboard-dark;
        border: 1px solid transparent;
        position: relative;
        box-shadow: none;
        width: 40px;
        height: 35px;
        padding: 0;

        span {
            &.text-info {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &.text-muted {
                color: #c4c4c4;
            }
        }

        &:hover {
            background-color: $natural-medium;
            color: $sky;
        }

        &.active,
        &:active {
            background-color: $sky;
            color: $white;

            .text-info {
                color: $white;
            }
        }

        &.btn-info.active,
        &.btn-info:active {
            background-color: $sky;
            color: $white;
        }

        &.pull-left {
            i {
                &.icon-arrow-right {
                    transform: rotate(-180deg);
                    display: block;
                }
            }
        }

        i {
            &.icon-arrow-right {
                font-size: 16px;
                font-weight: bold;
                color: $cement-medium;
            }
        }
    }
}

[uib-monthpicker] {
    width: 250px;
}