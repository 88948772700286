paging-bar {
    display: flex;
    padding-top: .50em;
    padding-bottom: .50em;

    > pml-list-view-page-sizes {
        display: flex;
        align-items: center;
        padding-right: 2em;
        flex: 1 auto;

        > label {
            margin-right: .25em;
        }

        > div.select-wrapper {
            width: 6.5em;

            > select {
                width: 6em;

                &.form-control {
                    padding-left: .8em;
                }
            }
        }

        > button.form-control {
            width: 2em;
        }
    }

    > pml-ng-list-view-pager {
        display: flex;

        .pagination {
            margin: 0;
        }
    }

    pml-list-view-page-sizes,
    .pager-jump-to {
        width: 25%;
        display: inline-block;
        vertical-align: text-top;

        label {
            display: block;
        }
    }

    .pager {
        &-jump-to {
            input {
                width: 50%;
                display: inline-block;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                /* display: none; <- Crashes Chrome on hover */
                -webkit-appearance: none;
                margin: 0;
                /* <-- Apparently some margin are still there even though it's hidden */
            }

            input[type=number] {
                -moz-appearance: textfield;
                /* Firefox */
            }
        }

        &-page-breakdown {
            display: inline-block;
        }

        &-buttons-wrap {
            padding-top: 24px;
        }
    }

    .form-control.ng-touched.ng-valid {
        border-color: silver;
    }
}