sort-dropdown {
    .form-group {
        display: inline-block;

        .select-wrapper {
            display: inline-block;
        }

        label {
            display: block;
            text-align: left;
        }
    }
}