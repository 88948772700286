.account {
    &-row {
        margin-bottom: 10px;

        .panel {
            &-heading {
                padding: 0;
                background-color: $cement-medium;
                @include transition(all .5s ease);

                &:hover {
                    background-color: darken($cement-medium, 5%);
                }
            }

            &-group {
                margin-bottom: 10px;
            }
        }

        .accordion {
            &-toggle {
                display: block;
                color: $white;
            }
        }

        .account {
            &-actions {
                .icon {
                    &-edit {
                        position: absolute;
                        right: 24px;
                        font-size: 22px;
                        @include bp(small) {
                            right: 0;
                            top: -85px;
                        }

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }

            &-nickname {
                font-size: 24px;
                font-weight: 100;
                margin-bottom: 5px;
                margin-top: 0;
            }

            &-ending {
                margin: 0;
                font-size: 14px;
                font-weight: normal;
            }
        }

        .spinner {
            &.spinner-small {
                top: 50%;
            }
        }
    }

    &-details {
        padding: 10px 10px 10px 20px;

        &.default {
            border: 2px solid $sky;
        }
    }

    &-actions {
        &-btn-group {
            position: relative;
            overflow: hidden;
            height: 40px;

            .btn {
                position: absolute;
                top: 0;
            }

            .default {
                left: 0;
            }

            .init-delete,
            .delete {
                left: 142px;
            }

            .cancel {
                left: 234px;
            }

            &.confirm-opened {
                .cancel {
                    left: 295px;
                }
            }

            &.default-account {
                .init-delete {
                    left: 0;
                }

                .delete {
                    left: 0;
                }

                .cancel {
                    left: 92px;
                }

                &.confirm-opened {
                    .cancel {
                        left: 153px;
                    }
                }
            }
        }
    }
}