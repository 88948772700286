.client-accounts-container {
    .alert {
        position: relative;
        margin: 10px 0 25px;

        .close {
            top: 24px;
        }
    }
}

.accounts-container {
    &.popup-container {
        .popup-content {
            max-width: 800px;
            padding: 0;
        }
    }

    .title-holder {
        display: flex;
        justify-content: space-between;
    }

    .title-message {
        padding: 1.25em;
    }

    .close {
        position: relative;
        padding: 0;
        cursor: pointer;
        background: transparent;
        border: 0;
        -webkit-appearance: none;
    }
}