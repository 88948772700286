.roles-container {
    margin-top: 15px;

    table {
        margin-top: 30px;

        > tbody {
            > tr {
                border-bottom: 1px solid $silver;

                &:last-child {
                    border-bottom: none;
                }

                > td {
                    &.address {
                        span {
                            display: block;
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }

        &.table {
            &-striped {
                > tbody {
                    > tr {
                        &:nth-of-type(odd) {
                            background-color: $silver-medium;
                        }
                    }
                }
            }
        }
    }

    .section-heading {
        // border-bottom: 1px solid $silver;
        background: $natural-medium;
        margin: 30px 0;

        h1 {
            display: inline-block;
            font-weight: 100;
            margin-top: 13px;
        }

        button {
            margin-top: 10px;
            vertical-align: top;
        }

        &.beneficiaries {
            height: 90px;

            .restate-container {
                padding: 10px 0;

                .datepicker {
                    width: 64%;

                    &-container {
                        position: relative;

                        i {
                            &.icon-calendar {
                                position: absolute;
                                left: 12px;
                                top: 15px;
                            }
                        }
                    }
                }

                .btn {
                    margin: 26px 5px 0;
                }
            }
        }
    }

    .beneficiary-class {
        h1 {
            font-size: 24px;
            font-weight: 100;
            text-align: center;
            padding-bottom: 20px;
        }

        .share-button-container {
            button {
                margin-top: 16px;
                margin-bottom: 4px;
            }
        }

        .heading-row {
            border-bottom: 1px solid $silver;
            margin-bottom: 30px;
        }
    }
}
