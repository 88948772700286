.mfa {
    &.container {
        width: 960px;

        @include bp($medium) {
            width: 100%;
        }
    }
    margin-top: 60px;

    .poc-code-container {
        .poc {
            &-item {
                margin: 15px 0;
            }
        }

        input {
            display: inline-block;
            vertical-align: middle;
            margin-top: -1px;
            margin-right: 10px;

            &.add-new-poc {
                width: 35%;
            }
        }

        uib-accordion {
            .panel {
                background: transparent;

                &-heading {
                    padding: 0;
                }

                h4 {
                    small {
                        color: $bright-link;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                &-group {
                    .panel-heading {
                        + .panel-collapse {
                            > .panel-body {
                                border: 0;
                            }
                        }
                    }
                }
            }
        }

        .panel {
            &-heading {
                h3 {
                    margin: 0;
                    font-weight: normal;
                }
            }
        }

        .buttons-container {
            text-align: right;

            @include bp(smaller) {
                text-align: left;
            }

            button {
                @include bp(smaller) {
                    display: block;
                    margin-bottom: 15px;
                }
            }
        }
    }

    .alert {
        text-align: left;
        font-size: 16px;
    }

    .logo-header {
        border-bottom: 1px solid $silver-dark;

        img {
            width: 200px;
            height: auto;
            margin-bottom: 30px;
        }
    }

    .section-header {
        position: relative;

        h1 {
            font-weight: 100;
        }
    }

    .poc-submit-code-container {
        .resend-link {
            color: $white;
            text-decoration: underline;
        }

        .single-input-fields {
            @include bp(969px) {
                width: 100%;
            }

            input {
                @include bp(smaller) {
                    width: 40px;
                    height: 60px;
                    margin: 0 5px;
                }

                @include bp(xsmall) {
                    margin: 0;
                }

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    /* display: none; <- Crashes Chrome on hover */
                    -webkit-appearance: none;
                    margin: 0;
                    /* <-- Apparently some margin are still there even though it's hidden */
                }
            }

            input[type=number] {
                -moz-appearance: textfield;
                /* Firefox */
            }
        }

        .alert {
            margin-bottom: 10px;
        }

        .alerts-container {
            width: 100%;
            position: absolute;
        }

        .inputs-container {
            position: relative;
            top: 60px;

            @include bp(770px) {
                top: 90px;
            }

            @include bp(smaller) {
                top: 110px;
            }
        }

        .btn {
            margin-top: 15px;
        }
    }

    .trust-device-container {
        margin-bottom: 10px;
    }
}