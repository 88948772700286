.wg-empty-state {
    width: 260px;
    margin: 80px auto 0;
    text-align: center;

    .icon {
        color: $sky;
        font-size: 30px;
    }

    .empty-state-title {
        margin-top: 15px;
        font-size: 20px;
        font-weight: bold;
        line-height: 30px;
    }

    .empty-state-text {
        color: $cement-medium;
        font-size: 16px;
    }
}
