notifications-component {
    display: block;
    margin-top: 70px;

    .contact-preference {
        margin-bottom: 15px;

        p {
            display: inline-block;
            margin-top: 7px;
        }

        .btn-group {
            .btn {
                display: inline-block;
                margin-left: 20px;
            }
        }
    }

    .notification-type {
        padding-left: 50px;
        margin-bottom: 35px;
        @include bp(969px) {
            padding-left: 15px;
        }

        h2 {
            font-size: 16px;
            border-bottom: 1px solid $silver;
            padding-bottom: 15px;
        }

        &:first-of-type {
            padding-right: 50px;
            padding-left: 15px;
            @include bp(969px) {
                padding-right: 15px;
            }
        }

        p {
            wg-checkbox {
                display: inline-block;
                vertical-align: sub;
                margin-right: 5px;
            }
        }
    }

    .subscribe-option {
        margin-bottom: 20px;
    }

    h2 {
        font-size: 18px;
        border-bottom: 1px solid $silver;
        padding-bottom: 15px;
    }

    combo-box {
        display: block;

        label {
            font-weight: bold;
        }
    }
}