.ui-view-transition-container {
    position: relative;

    [ui-view] {
        &.vertical-transition {
            height: 500px;
            width: 100%;
            margin: 0 auto;

            &.ng-enter,
            &.ng-leave {
                position: absolute;
                transition: all .25s cubic-bezier(.600, .040, .980, .335);
            }

            &.ng-enter {
                opacity: 0;
                top: 30px;
            }

            &.ng-enter-active {
                opacity: 1;
                top: 0;
            }

            &.ng-leave {
                opacity: 1;
                top: 0;
            }

            &.ng-leave-active {
                top: 30px;
                opacity: 0;
            }
        }
    }
}