.correspondence-container,
.compensation-container {
    .section-top-info {
        padding-top: 20px;
        padding-bottom: 20px;
        color: $white;
        font-size: 32px;
        line-height: 44px;
        font-weight: 300;
        margin-bottom: 0;
        text-transform: capitalize;
    }

    .correspondence-links {
        padding-bottom: 20px;
        padding-top: 20px;
        border-bottom: 1px solid $silver-medium;

        span:not(:last-child)::after {
            content: ' | ';
        }
    }

    .tabs-container {
        margin-left: -15px;
        margin-right: -15px;

        .nav {
            &.nav-tabs {
                text-align: center;
                margin-bottom: 10px;
                border-bottom: 1px solid $silver-medium;
                position: relative;

                li {
                    border-bottom: 2px solid transparent;
                    width: auto;
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 10px;
                    outline: none;

                    a {
                        border: 0;
                        padding: 0;
                        color: $cement-medium;
                        font-size: 14px;
                        line-height: 16px;
                        outline: none;
                        background-color: transparent;
                        padding-top: 15px;
                        padding-bottom: 15px;

                        &:hover,
                        &:focus {
                            border: 0;
                            color: inherit;
                            outline: none;
                        }
                    }

                    &.active {
                        border-bottom: 2px solid $bright-link;

                        a {
                            color: $chalkboard-dark;
                        }
                    }
                }
            }
        }

        .tab-content {
            .error-message {
                font-size: 16px;
                margin-top: 35px;
            }

            .monthly-selection {
                text-align: center;
                margin-top: 20px;
                margin-bottom: 20px;
                width: 200px;

                .month-selection {
                    font-size: 20px;
                    color: $chalkboard-dark;
                    text-align: center;
                    margin-bottom: 22px;
                    cursor: pointer;

                    .icon.icon-arrow-right {
                        padding: 0;
                        font-size: 10px;
                        vertical-align: middle;
                        font-weight: 600;
                        display: inline-block;
                        margin-left: 4px;
                        @include transform(rotate(90deg));
                        @include transition(all .15s ease);
                    }

                    &.open {
                        .icon.icon-arrow-right {
                            @include transform(rotate(270deg));
                        }
                    }
                }

                .month-relative-container {
                    position: relative;

                    .month-menu {
                        position: absolute;
                        top: -15px;
                        width: 160px;
                        background-color: rgba(255, 255, 255, .99);
                        z-index: 100;
                        text-align: center;
                        -webkit-box-shadow: $box-shadow;
                        box-shadow: $box-shadow;

                        ul {
                            list-style: none;
                            padding: 0;
                            margin: 0;

                            li {
                                padding: 14px 20px;
                                color: $chalkboard-dark;
                                border-top: solid 1px $natural-medium;
                                font-size: 14px;
                                text-align: left;
                                min-height: 30px;
                                line-height: 20px;
                                cursor: pointer;
                                text-transform: capitalize;
                                overflow: hidden;

                                &:first-child {
                                    border-top: solid 1px #e5e5e5;
                                }

                                &:hover {
                                    background-color: $natural-medium;
                                }
                            }
                        }
                    }
                }
            }

            .monthly-section {
                padding-top: 10px;

                &:first-child {
                    border: 0;
                }

                .correspondence-row,
                .compensation-row {
                    margin-bottom: 15px;
                }
            }

            .tab-title {
                font-size: 20px;
                color: $chalkboard-dark;
                text-align: center;
                margin-bottom: 20px;
            }

            .no-correspondence-message,
            .no-compensation-message {
                text-align: center;
                color: $cement-medium;
                font-size: 16px;
                margin-bottom: 30px;
            }

            .correspondence-item,
            .compensation-item {
                min-width: auto;
                max-width: 500px;
                cursor: pointer;

                &.latest {
                    position: relative;
                    margin-top: 15px;

                    &:before {
                        content: 'LATEST';
                        width: 62px;
                        height: 18px;
                        line-height: 18px;
                        font-weight: 600;
                        position: absolute;
                        color: $chalkboard-dark;
                        text-align: center;
                        font-size: 11px;
                        text-transform: capitalize;
                        top: -16px;
                        left: 0;
                        background-color: $apricot;
                        letter-spacing: 1px;
                    }

                    .item-top,
                    .item-bottom {
                        border-color: $apricot;
                    }

                    .item-top {
                        border-bottom-width: 0;
                        border-top-width: 3px;
                    }

                    .item-bottom {
                        padding-top: 13px;
                        border-top-width: 0;
                        border-bottom-width: 3px;
                    }
                }

                .item-top,
                .item-bottom {
                    border-color: transparent;
                    border-style: solid;
                    border-left-width: 3px;
                    border-right-width: 3px;
                }

                .item-top {
                    width: 100%;
                    max-height: 102px;
                    padding: 18px 20px 20px 18px;
                    background-color: $natural-medium;

                    .item-content-column {
                        width: 90%;
                        display: table-cell;
                        vertical-align: top;

                        &.padding-both-side {
                            padding: 0 15px;
                        }

                        &.icon {
                            width: 5%;
                            min-width: 26px;
                            color: $apricot-dark;
                            font-size: 26px;
                            padding-top: 2px;

                            &.smaller-icon {
                                color: $cement-medium;
                                font-size: 22px;
                                text-align: right;
                            }
                        }

                        .item-title {
                            font-size: 16px;
                            line-height: 20px;
                            color: $chalkboard-dark;
                        }

                        .item-subtitle {
                            font-size: 14px;
                            line-height: 20px;
                            color: $cement-medium;
                            margin-top: 3px;
                        }
                    }
                }

                .item-bottom {
                    height: 52px;
                    font-size: 14px;
                    color: $chalkboard-dark;
                    padding: 10px 8px;
                    background-color: $natural-dark;

                    .light-color {
                        color: $cement-medium;
                    }

                    @include bp(small) {
                        height: auto;
                    }
                }
            }

            .compensation-item {
                .item-top {
                    .item-content-column {
                        &.icon {
                            padding-top: 8px;
                        }
                    }
                }
            }
        }
    }

    .compensation-adp-link {
        color: $chalkboard-dark;
    }
}

.compensation-container {
    .tabs-container {
        .nav {
            &.nav-tabs {
                margin-top: 0;
            }
        }
    }

    .header-box {
        margin: 30px 0;
    }

    .adp-link {
        padding-right: 15px;
    }
}

.correspondence {
    &-container {
        .dropdown {
            .dropdown {
                &-menu {
                    ul {
                        li {
                            &:hover {
                                background-color: inherit;
                            }
                        }
                    }
                }
            }
        }
    }
}

policy-tax-documents {
    display: flex;
    flex-direction: column;
    align-items: center;
    // border: solid 1px $chalkboard;
    margin: 1em auto auto;
    padding: .5em;
    max-width: 500px;

    h5 {
        border-bottom: 1px solid $silver;
        font-weight: 400;
        font-size: 19px;
        padding-bottom: 13px;
        clear: both;
        width: 100%;
        text-align: center;
    }

    table {
        width: 100%;

        thead > tr > th {
            background-color: transparent;
            border: none;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: 1px;
            color: $cement-medium;
            padding: 0;
        }
    }
}