.nba-upload,
contact-us {
    .flex {
        &-container {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -moz-flex;
            display: -webkit-flex;
            display: flex;
        }

        &-wrap {
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
        }

        &-row {
            -ms-flex-direction: row;
            flex-direction: row;
        }

        &-column {
            -ms-flex-direction: column;
            flex-direction: column;
        }

        &-item-0 {
            -ms-flex: 0 auto;
            flex: 0 auto;
        }

        &-item-1 {
            -ms-flex: 1 auto;
            flex: 1 auto;
        }

        &-item {
            -ms-flex: 1 0;
            flex: 1 0;
        }

        &-align-items-stretch {
            -ms-flex-align: stretch;
            align-items: stretch;
        }

        &-justify-content-space-between {
            // -ms-flex-align: spa;
            justify-content: space-between;
        }
    }

    .header-box {
        p {
            font-size: 16px;
        }
    }

    .btn-default {
        line-height: 16px;
        height: auto;
    }

    .auto-size {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .body-box {
        display: flex;
        flex-direction: row;

        application-form {
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            margin-bottom: 15px;

            .section-header {
                border-bottom: 1px solid black;
            }
        }

        application-files {
            position: relative;
            justify-content: start;
            margin-bottom: 15px;

            file-selector {
                padding: .25em;
                border: dashed $silver-dark .1em;

                @include bp($small) {
                    margin: 15px;
                }

                drop-target,
                upload-progress {
                    display: flex;
                    -ms-flex-align: center;
                    align-items: stretch;
                    -ms-flex-pack: center;
                    justify-content: stretch;
                    cursor: default;
                    -ms-flex: 1 auto;
                    flex: 1 auto;

                    div.progress {
                        width: 100%;
                    }

                    p {
                        font-size: 1em;
                    }

                    drop-message {
                        vertical-align: middle;
                        text-align: center;
                        font-weight: bold;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        flex: 1 auto;

                        i {
                            font-size: 3em;
                        }

                        u {
                            color: $bright-link;
                            text-decoration: none;
                        }
                    }
                }

                drop-target {
                    padding: 20px;
                    cursor: pointer;
                }
            }

            file-list {
                flex: 1 auto;

                .table-holder {
                    border: 1px solid grey;
                }

                .table {
                    > thead > tr {
                        > th {
                            &.fileSize {
                                width: 20%;
                                text-align: right;
                            }

                            &.fileActions {
                                width: 8%;
                            }
                        }
                    }

                    > tbody > tr {
                        > td {
                            padding: .2em;
                            border-bottom: 1px solid silver;

                            &:first-child {
                                padding-left: .4em;
                            }

                            // &.fileName {

                            // }
                            &.fileSize {
                                white-space: nowrap;
                                text-align: right;
                            }

                            &.fileActions {
                                text-align: center;
                            }
                        }
                    }
                }

                .trashcan {
                    cursor: pointer;
                    // z-index: 10;
                    // position: absolute;
                    // width: 100%;
                }
            }

            .hovering {
                background-color: rgba(121, 121, 121, .25);
            }
        }
    }

    .button-holder {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: end;
        margin-top: .4em;

        :first-child {
            margin-right: .2em;
        }
    }

    .footer-box {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-end;
        border-top: 1px solid silver;
        margin-top: .5em;
        padding-top: .5em;



        // button {
        //     margin-top: .2em;
        //     margin-left: .2em;
        // }
    }

    .question-container {
        margin-bottom: 30px;

        .question {
            margin-bottom: 10px;
            font-size: 16px;

            p,
            .question-buttons {
                display: inline-block;
            }

            .follow-up {
                padding-left: 30px;

                .question-secondary {
                    margin-top: 15px;

                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .client-insured-form {
        h1 {
            font-weight: 100;
            font-size: 18px;
        }
    }

    .alert {
        &-success {
            margin-bottom: 15px;
        }
    }
}