combo-box {
    position: relative;

    .combobox,
    .combobox-select-only {
        width: 100%;
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: stretch;
        vertical-align: middle;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        border: 1px solid $silver;
        background: $white;

        &-inner {
            flex: 1;
            position: relative;
            z-index: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            border: 0;
        }

        &-clear {
            outline: 0;
            flex: none;
            align-self: center;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            opacity: 80%;
            margin-right: 10px;

            .icon {
                flex-shrink: 0;
                display: inline-flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: center;
                vertical-align: middle;
                position: relative;
            }
        }

        &-dropdown-trigger {
            border-width: 0;
            flex: none;
            aspect-ratio: auto;
            padding: 6px 15px;
            color: $chalkboard-dark;

            &:hover,
            &:focus {
                color: $chalkboard-dark;
            }

            &:focus {
                box-shadow: inset 0 0 8px rgba(0, 0, 0, .15);
            }

            .icon {
                display: block;
                transition: all .15s linear;
            }
        }

        &-option-list {
            position: absolute;
            width: 100%;
            background: $white;
            transition: all linear .5s;
            opacity: 1;
            z-index: 2;
            box-shadow: 0 0 15px 0 $shadow;
            top: 72px;

            &.ng-hide {
                opacity: 0;
            }

            li {
                padding: 10px;
                cursor: pointer;
                transition: all .25s linear;

                &:hover,
                &:focus {
                    background: $natural-medium;
                }

                &:focus-visible {
                    outline: 0;
                }
            }
        }

        &.menu-open {
            .combobox {
                &-dropdown-trigger {
                    box-shadow: inset 0 0 8px rgba(0, 0, 0, .15);

                    .icon {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .combobox-select-only {
        .value-display {
            flex: 1;
            padding: 10px 20px;
            cursor: pointer;
        }
    }

    &.focused,
    &:focus {
        outline: 0;
    }

    &.focused {
        .select-container {
            border: 5px solid green;
        }
    }

    .select-container {
        display: block;
    }

    .combobox-select-only {
        &:focus-visible {
            outline: 0;
        }
    }
}
