idle-monitor {
    display: block;
    // position: fixed;
    // top: 64px;
    width: 100%;

    .idle-header {
        font-size: 1.1em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .idle-title {
            font-size: 1.3em;
        }
    }

    .idle-container {
        display: flex;
        flex-direction: column;
        background: gold;
        color: black;
        padding: .4em;
    }
}