all-policies-view {
    li {
        &.icon-table-view:hover,
        &.icon-row-view:hover {
            background: $cement;
        }

        &.selected-view {
            background: $chalkboard;
            color: white;
        }
    }

    .issue-state-filter {
        margin-left: 0;
    }

    sort-dropdown {
        padding-top: 15px;
        display: inline-block;
        vertical-align: top;
        width: 100%;
        @include bp(1070px) {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        @include bp($medium) {
            padding-top: 0;
        }

        .form-group {
            &.sort {
                &-by {
                    margin-right: 10px;
                    width: 50%;
                    @include bp(1070px) {
                        width: 100%;
                    }
                }

                &-direction {
                    width: 40%;
                    @include bp(1070px) {
                        width: 100%;
                    }

                    .select-wrapper {
                        &:before {
                            top: 4px;
                        }
                    }
                }
            }

            .select-wrapper {
                margin-right: 5px;
                width: 100%;

                &:before {
                    top: 22px;
                }
            }
        }

        .form-control {
            height: 30px;
            padding: 0 5px;
            font-size: 13px;
        }
    }

    .display-select {
        padding-top: 18px;
    }

    .right-filter-controls {
        &.inforce {
            text-align: right;
        }

        &.pending {
            sort-dropdown {
                padding-top: 0;
            }
        }
        @include bp(1070px) {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        @include bp($medium) {
            align-items: flex-start;
        }

        > div {
            @include bp($medium) {
                padding-left: 0;
            }
        }
    }

    .table {
        &.table-mobile {
            .owner-label {
                height: 150px;
            }
        }
    }
}

.download-modal.modal-body {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}