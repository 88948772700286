info-heading-block {
    background-color: $natural-medium;
    padding: 1em;
    display: block;
    overflow: auto;

    h2 {
        margin: 0;
        line-height: 1.69;
        font-size: 2em;
        font-weight: 400;
    }

    r

    h2,
    p {
        color: $chalkboard-dark;
    }
}