.share-amount-container {
    &.client {
        .input {
            &-group {
                margin: 0 auto;
            }

            &-value-type {
                vertical-align: middle;
                margin: 10px 0 0 10px;
                display: inline-block;
            }
        }

        .enter-amount-warning {
            display: block;
            margin-top: 15px;
            text-align: left;
            font-size: 80%;
        }
    }
}