@media print {
    @import '../components/print/coverages';
    @import '../components/print/requirements';
    @import '../components/print/riders';
    @import '../components/print/values';
    @import '../components/print/wg-policy-relatives';
    @import '../components/print/policy-summary';
    @import '../components/print/grid';
    @import '../components/print/table';
    @import '../components/print/transactions-history-table';
    @import '../components/print/modals';
    wg-navigation-header {
        display: none;
    }

    th,
    tr,
    td {
        page-break-inside: avoid;
        break-inside: avoid;
    }

    .label {
        border: 0;
        font-size: 11px;
    }

    .icon {
        &-close,
        &-eye,
        &-heart-unselected {
            display: none;
        }
    }

    .table-responsive {
        overflow-y: visible;
    }

    html body .main-view-container {
        padding-top: 0;

        &.client,
        &.legacy-nav {
            padding-top: 0;
        }
    }

    body.policy-detail-values,
    body.policy-detail-coverages,
    body.policy-detail-riders {
        visibility: hidden;

        .policy-detail.popup-container,
        .coverage-container.popup-container,
        .riders-container.popup-container {
            visibility: visible;
        }

        .policy-detail,
        .popup-container {
            overflow: visible;
            overflow-x: visible;
            overflow-y: visible;
            position: relative;
        }

        .relatives-container {
            display: none;
        }
    }

    .table-responsive {
        &.hidden-xs,
        &.hidden-is {
            // necessary to make the table rows print as normal
            display: block !important;
        }

        .icon-policy,
        .column-sorted-icon {
            display: none !important;
        }

        & + .box-container.visible-xs {
            // necessary to prevent the "responsive" part below the table from printing
            display: none !important;
        }
    }

    .links-above-table {
        display: none;
    }

    .modal {
        &-title {
            font-size: 16px;
            padding-left: 0;
        }

        &-summary-header {
            padding: 5px 0;
            font-size: 11px;

            .label {
                font-weight: 600;
                font-size: 11px;
            }

            p {
                margin: 0;
            }
        }
    }

    .line-row {
        .value {
            float: right;
        }
    }

    .pending-summary-container {
        h4 {
            margin: 0 0 36px;
        }
    }
}