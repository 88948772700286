slider-switch {
    .switch {
        position: relative;
        display: inline-block;
        width: 65px;
        height: 26px;

        input {
            display: none;
        }

        &-container {
            .label-text {
                vertical-align: top;
            }
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $white;
        -webkit-transition: .2s;
        transition: .2s;
        border: 2px solid $silver;

        &:before {
            position: absolute;
            content: '';
            height: 15px;
            width: 15px;
            left: 4px;
            bottom: 4px;
            background-color: $cement-dark;
            -webkit-transition: .2s;
            transition: .2s;
        }

        &.round {
            border-radius: 34px;

            &:before {
                border-radius: 50%;
            }
        }

        &-text {
            position: absolute;
            top: 2px;
            font-weight: bold;
            user-select: none;

            &.off {
                color: $cement-dark;
                right: 6px;
            }

            &.on {
                left: 6px;
            }
        }
    }

    input {
        &:checked,
        &:focus {
            + .slider {
                background-color: $sky;
                border-color: $sky;

                .slider-text {
                    color: $white;
                }
            }
        }

        &:checked {
            + .slider {
                &:before {
                    background-color: $white;
                    -webkit-transform: translateX(37px);
                    -ms-transform: translateX(37px);
                    transform: translateX(37px);
                }
            }
        }
    }

    &:focus-visible {
        outline: none;

        .slider {
            border-color: $sky;
            // box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        }
    }

    .switch-container {
        &.disabled {
            opacity: 50%;

            .slider {
                cursor: not-allowed;

                // &:before {
                //     background-color: $silver;
                // }

                // &-text {
                //     &.off {
                //         color: $silver;
                //     }
                // }
            }
        }
    }
}