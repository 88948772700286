common-nav {
    height: 56px;
    position: fixed;
    top: 0;
    z-index: 1002;
    background-color: $common-nav-bg;
    width: 100%;
    padding-top: 12px;

    app-switcher,
    profile-dropdown {
        display: inline-block;
    }
}