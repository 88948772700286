.wg-client {
    &-payment {
        &-table-container {
            .subtotal {
                border-top: 2px solid $natural-dark;
                font-weight: bold;
            }

            .net-due {
                td {
                    font-weight: bold;
                }
            }

            .table {
                > tbody {
                    > tr {
                        > td {
                            padding: 5px 20px;
                        }
                    }
                }

                > thead {
                    > tr {
                        th {
                            padding: 12px 20px;
                        }
                    }
                }

                .loan {
                    &-details {
                        td {
                            padding: 0;

                            &.loan-interest-total {
                                border-bottom: 1px solid $black;
                            }
                        }
                    }

                    &-payoff-details {
                        h1 {
                            font-size: 16px;
                            font-weight: normal;
                        }
                    }

                    &-interest-details {
                        p {
                            margin: 0;
                        }
                    }

                    &-anniversary-totals {
                        margin: 20px 0;
                    }
                }
            }

            .pua-amount {
                margin-right: -11px;
            }

            .before-total {
                border-bottom: 1px solid $black;
                padding-bottom: 5px;
            }

            .mec-message {
                border-top: 1px solid $silver;
                padding-top: 10px;
            }
        }

        .bank-account-number {
            clear: left;
        }

        .ep-checkbox {
            .opt-in-label {
                font-size: 14px;
                font-weight: bold;
            }

            .wg-checkbox-container {
                padding: 6px;
            }
        }

        tooltip {
            margin-left: 10px;
        }

        .section-title {
            display: inline-block;
        }

        .icon {
            &-bill {
                font-size: 20px;
                vertical-align: sub;
            }
        }

        .link {
            &-bill {
                margin-top: 30px;
            }
        }

        .view {
            &-failure {
                .btn {
                    margin-top: 15px;
                }
            }

            &-success {
                .payment-confirmation,
                .print-header {
                    display: none;
                }
            }
        }

        .logo {
            display: inline-block;
            width: 280px;
            height: 95px;
            margin-top: 25px;
            fill: $white;
            margin-bottom: 36px;
            background: url('../images/landing-logo.svg') no-repeat 100% 100%;
            background-size: contain;
        }
    }
}

.print-popup {
    .payment-confirmation {
        display: block;

        .success-message {
            margin: 15px 0;
        }

        .table {
            margin-bottom: 0;

            > thead {
                > tr {
                    th {
                        background-color: transparent;
                        border-bottom: 0;
                    }
                }
            }

            > tbody {
                > tr {
                    > td {
                        padding: 0;
                    }
                }
            }
        }

        img {
            margin-bottom: 40px;
        }

        .print-link {
            margin: 15px;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.form {
    &-instruction {
        label {
            color: $chalkboard-dark;
            font-weight: bold;
        }
    }
}