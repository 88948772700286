.overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: '';
    background: #23282b;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease-in-out;

    &.open-menu {
        opacity: .65;
        visibility: visible;
        transition: all .2s ease-in-out;
    }
}

.main-menu {
    position: fixed;
    right: -473px;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 473px;
    z-index: 1001;
    background-color: $common-nav-underline;
    text-align: left;
    overflow-x: hidden;
    overflow-y: visible;
    transition: all .2s ease-in-out;

    @include bp(small) {
        right: -270px;
        width: 270px;
    }

    &.only-logout {
        padding-top: 70px;
    }

    .btn-contact-us {
        font-size: 16px;
        margin-right: 13px;
        color: $white;
        border: 0;
        font-weight: 600;
        cursor: pointer;
        text-decoration: none;
        padding-top: 0;
        line-height: 30px;
        background-color: #015d82;
        border-radius: 15px;
        height: 30px;
    }

    &.open-menu {
        transform: translate3d(-473px, 0, 0);
        -webkit-transform: translate3d(-473px, 0, 0);
        -moz-transform: translate3d(-473px, 0, 0);
        -o-transform: translate3d(-473px, 0, 0);

        @include bp(small) {
            transform: translate3d(-270px, 0, 0);
            -webkit-transform: translate3d(-270px, 0, 0);
            -moz-transform: translate3d(-270px, 0, 0);
            -o-transform: translate3d(-270px, 0, 0);
        }
    }

    .close {
        position: absolute;
        right: 30px;
        top: 30px;
        font-size: 20px;
        line-height: 40px;
        color: $white;
        font-weight: 100;
        opacity: 1;
        outline: none;

        @include bp(small) {
            font-size: 26px;
            line-height: inherit;
        }
    }

    ul {
        list-style: none;
        margin-top: 140px;
        padding: 20px 40px;

        .client-site & {
            margin: 0;
            padding: 0 40px;
        }

        @include bp(medium) {
            margin-top: 200px;
        }

        li {
            padding-bottom: 30px;

            .client-site & {
                padding: 0;
            }

            a {
                font-size: 26px;
                font-weight: 500;
                line-height: 30px;
                cursor: pointer;
                text-transform: capitalize;
                overflow: hidden;
                text-decoration: none;
                padding-bottom: 4px;
                color: $white;
                font-family: 'TiemposHeadline';

                &.active {
                    color: $white;
                    border-bottom: solid 2px $app-select-open;
                }

                &:hover {
                    color: $white;
                }

                .icon-logout,
                .icon-user {
                    font-size: 20px;
                    margin-top: 5px;
                    margin-right: 10px;
                }

                .icon-user {
                    font-size: 18px;
                    margin-right: 14px;
                    margin-left: 2px;
                }

                &.btn-contact-us {
                    font-size: 14px;
                    margin-left: 2px;
                }

                .client-site & {
                    color: $white;
                    display: block;
                    padding: 11px 0;
                }
            }
        }
    }

    .client-site & .nav-pills.nav-stacked {
        margin: 150px 0 0;
        padding-bottom: 20px;
        padding-top: 0;

        & > li {
            margin: 0;
            padding: 0;

            & > a {
                background: none;
                color: $white;
                padding: 15px 0;
                line-height: 1.4;
            }
        }

        + hr {
            margin: 10px 40px;
            border: 0;
            border-bottom: 1px solid $white;
            opacity: .2;
        }
    }

    &.legacy {
        background-color: #0f1920;
        width: 332px;
        right: -332px;

        ul {
            li {
                a {
                    font-family: 'Open Sans', sans-serif;
                    font-size: 20px;
                    font-weight: 100;
                    color: $sea-foam;

                    &.active {
                        color: $white;
                        border-bottom: solid 2px $kelly;
                    }
                }
            }
        }

        &.open-menu {
            transform: translate3d(-332px, 0, 0);
            -webkit-transform: translate3d(-332px, 0, 0);
            -moz-transform: translate3d(-332px, 0, 0);
            -o-transform: translate3d(-332px, 0, 0);
            @include bp(small) {
                transform: translate3d(-270px, 0, 0);
                -webkit-transform: translate3d(-270px, 0, 0);
                -moz-transform: translate3d(-270px, 0, 0);
                -o-transform: translate3d(-270px, 0, 0);
            }
        }
    }
}

@media (max-width: 749px) and (min-width: 360px) {
    .section-user-info .insured-date {
        margin-bottom: 10px;
    }
}
