/* This helps the ng-show/ng-hide animations start at the right place. */
/* Since Angular has this but needs to load, this gives us the class early. */
.ng-hide {
    display: none !important;
}

/* Prevent the Angular html template from being briefly displayed by the browser in its raw (uncompiled)*/
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
    display: none !important;
}
