.general-details-table {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: $chalkboard-dark;
    margin-bottom: 0;
    overflow: auto;

    .column-sorted-icon {
        margin-left: 5px;
        color: $cement-medium;
        vertical-align: middle;
        font-size: 14px;

        &.icon-sort-down-arrow,
        &.icon-sort-up-arrow {
            font-size: 6px;
            cursor: pointer;
            @include transition(all .15s ease);
        }
    }

    .icon-sort-down-arrow,
    .icon-sort-up-arrow {
        font-size: 10px;
        font-weight: bold;
        cursor: pointer;
        @include transition(all .15s ease);
    }

    a {
        color: #0089d5;
    }

    .row-container::after {
        content: '';
        display: block;
        clear: both;
    }

    .general-table-structure {
        min-width: 650px;
        display: table;
        width: 100%;

        .row-container {
            background-color: $natural-medium;
            border-bottom: 1px solid $silver;
            display: table-row;
            clear: none;

            &:first-child,
            &:last-child {
                border-bottom: none;
            }

            .cell-container {
                padding: 13px 8px;
                display: table-cell;

                &:first-child {
                    padding-left: 25px;
                }

                &:last-child {
                    padding-right: 25px;
                }
            }
        }

        .table-header-row {
            background-color: $natural-dark;
            color: $chalkboard-dark;
            font-weight: 600;
        }
    }

    tfoot {
        td,
        th {
            padding: 13px 8px;
        }
    }

    tbody {
        tr {
            background-color: $natural-medium;
            height: 50px;
            vertical-align: top;
            @include transition(all .15s ease);

            th[ng-click] {
                cursor: pointer;
            }

            tr,
            th,
            td {
                padding: 13px 8px;
            }

            .cell-container {
                padding: 13px 8px;
                vertical-align: top;
            }

            &.open-note {
                background-color: $natural;

                td {
                    border-bottom: 0;

                    &:last-child {
                        .icon {
                            color: $silver-medium;
                        }
                    }
                }

                .small-icon {
                    transform: rotate(180deg);
                }
            }

            &.note-row {
                display: table-row !important;
                background-color: $natural;
                border: none;
                height: inherit;

                td {
                    padding-bottom: 13px;
                    padding-top: 0;
                }

                .pending-policy-note {
                    padding: 0;
                }

                .note-body {
                    padding: 5px 0;
                    font-size: 12px;
                    font-weight: 400;
                }
            }

            &:first-child,
            &:last-child {
                border-bottom: none;
            }

            &:first-child {
                td,
                th {
                    border-top: none;
                }
            }

            &:last-child:not(.unread) {
                td,
                th {
                    border-bottom: none;
                }
            }

            th,
            td {
                &:first-child {
                    padding-left: 25px;
                }
            }

            th,
            &.empty-table-header {
                background-color: $natural-dark;
                color: $chalkboard-dark;
                font-weight: 600;
            }

            td {
                border-bottom: 1px solid $silver;

                &.icon-note {
                    text-align: center;
                    width: 50px;
                }

                .icon {
                    font-size: 18px;
                    color: $cement-medium;
                    cursor: pointer;

                    &.past-due {
                        margin-right: 4px;
                        color: #f5a623;
                        cursor: default;
                        font-size: 18px;
                        line-height: 20px;
                        padding-top: 2px;
                        display: inline-block;
                        float: left;
                    }

                    &.icon-inbox-upload {
                        font-size: 50px;
                    }
                }
            }

            td.icon-column {
                text-align: center;
                // vertical-align: middle;
            }

            th {
                &.pending {
                    padding-left: 18px;
                }
            }

            &.show-error {
                td,
                > td,
                td:first-child {
                    border-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }

        tr.error-row {
            height: auto;

            td {
                padding-top: 0;
                // padding-bottom: 0;
            }
        }
    }

    .empty-table-state-text {
        min-height: 100px;
        margin: 0;
        padding: 0;
        text-align: center;
        line-height: 100px;
        color: $cement-medium;
    }

    .table-transaction-details {
        thead,
        tbody {
            tr {
                padding: 0;
                height: auto;

                th,
                td {
                    padding: 0;
                }
            }
        }

        thead {
            tr {
                th {
                    background-color: transparent;
                }
            }
        }
    }
}
