.client-profile-popup {
    .alert-danger {
        margin-top: 1em;
    }

    .alert {
        .close {
            display: none;
        }
    }

    .btn {
        margin-right: 2em;
    }

    .client-profile-content {
        .password-form {
            padding: 1em;
        }
    }

    .success-message {
        background-color: $kelly;
        border-radius: 3px;
        color: $white;
        margin: 2em 1em;
        margin-bottom: .5em;
        padding: 1em;

        .icon-check {
            margin-right: .75em;
        }
    }

    .form-buttons {
        padding-left: 0;
    }

    .password-requirement-met {
        color: $kelly;
    }

    .password-requirement-unmet {
        color: $cherry;
    }

    .password-requirements {
        color: $chalkboard-medium;

        @include bp(medium) {
            position: absolute;
            top: -1px; /* because the containing div has a height of 1px */
        }

        li {
            line-height: 1em;
            margin-bottom: .5em;
        }
    }

    .passwords-dont-match-mesage {
        color: $cherry;
    }

    .popup-content {
        margin: 0 auto;
        max-width: 650px;
    }

    input {
        border-color: $cement;
        border-style: solid;
        border-width: 1px;
        // margin-bottom: 1em;

        &.invalid {
            border-color: $cherry;
        }
    }

    label {
        margin-bottom: .5em;
        padding: 0;
        color: #3d3d3d;
    }

    .phone-section {
        border-top: 1px solid $silver;
    }

    .section-heading {
        font-weight: bold;
        font-size: 14px;
    }

    .poc-list {
        li {
            margin: 25px 0;
            @include bp(459px) {
                height: 50px;
                border-bottom: 1px solid $silver;
                margin: 10px 0;
                padding-bottom: 78px;
            }
        }

        button {
            margin-left: 10px;
            margin-right: 5px;
            @include bp(459px) {
                margin-left: 0;
            }
        }

        small {
            color: $bright-link;
        }

        .panel-heading {
            padding: 10px 0;
        }

        input {
            width: 25%;
            @include bp($smaller) {
                width: 35%;
            }

            @include bp($policy-summary-custom-bp) {
                width: 50%;
            }
        }

        .poc-item {
            position: relative;

            .button-container {
                position: absolute;
                top: -5px;
                left: 18%;
                @include bp($small) {
                    left: 25%;
                }
                @include bp($smaller) {
                    left: 30%;
                }

                @include bp(459px) {
                    position: relative;
                    display: block;
                    left: 0;
                    top: inherit;
                    margin-top: 5px;
                }

                button,
                span.icon-trashcan {
                    position: absolute;
                    right: -30px;
                }

                .secondary-buttons {
                    button {
                        position: relative;
                        @include bp(459px) {
                            margin-left: 0;
                        }
                    }
                }

                .icon {
                    &.icon-trashcan {
                        font-size: 16px;
                        cursor: pointer;
                        padding-top: 8px;
                    }
                }

                &.international {
                    left: 22%;
                    @include bp($small) {
                        left: 29%;
                    }
                    @include bp($smaller) {
                        left: 34%;
                    }
                }
            }
        }
    }

    .email-section {
        input {
            width: 60%;
            display: inline-block;
            @include bp($small) {
                width: 100%;
            }
        }

        label {
            display: block;
        }
    }

    .email,
    .phone {
        &-section {
            button {
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
                @include bp($small) {
                    margin-left: 0;
                }
            }
        }
    }
}

