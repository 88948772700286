.notifications-container {
    z-index: 100;
    width: 100%;

    .alert {
        padding: 14px 54px 14px 20px;
        position: relative;

        @include bp(small) {
            text-align: left;
        }

        &-danger,
        &-warning,
        &-success,
        &-info {
            &.alert-dismissible {
                .close {
                    top: 22px;
                }
            }
        }
    }

    .alert-dismissable .close {
        font-size: 18px;
        line-height: 0;
    }
}
