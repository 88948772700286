phone-number-input {
    display: inline-block;
    width: 100%;

    .select-wrapper {
        margin-right: 15px;
    }

    .phone-input {
        position: relative;
    }

    .delete-phone {
        position: absolute;
        right: -25px;
        top: 18px;
        cursor: pointer;
    }

    .undo-delete-phone {
        cursor: pointer;
    }

    label {
        position: absolute;
        left: 1px;
        top: -20px;
    }
}