.transaction-history-main {
    margin-top: 30px;

    .table {
        @include bp($max-width) {
            width: 95%;
            margin: 0 auto;
        }
    }

    .transaction-history {
        .table {
            margin-top: 30px;

            > thead {
                > tr {
                    > th {
                        position: sticky;
                        top: 105px;
                    }
                }
            }

            tbody {
                tr {
                    transition: all linear .15s;

                    // &:hover {
                    //     transform: scale(1.003);
                    //     background: $natural-light;
                    //     box-shadow: 0 2px 4px 0 $shadow;
                    // }

                    &.transaction {
                        font-weight: bold;
                    }

                    &:nth-child(even) {
                        border-bottom: 1px solid $silver;
                    }
                }
            }

            &.role-transactions {
                margin: 0;
                border-left: 2px solid $sky;

                > thead {
                    > tr {
                        border: 0;

                        > th {
                            border-bottom: 0;
                            padding-bottom: 10px;
                            text-transform: uppercase;
                            max-width: 300px;
                            width: 300px;
                            position: inherit;
                            background: $natural-medium;

                            > td {
                                width: 300px;
                                max-width: 300px;
                            }
                        }
                    }
                }

                > tbody {
                    > tr {
                        border: 0;
                        cursor: pointer;
                        transition: all linear .15s;

                        &:hover {
                            transform: scale(1.003);
                            background: $natural-light;
                            box-shadow: 0 2px 4px 0 $shadow;
                        }

                        > td {
                            padding-top: 0;
                            width: 300px;
                            max-width: 300px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }

    .transaction-summary {
        .summary {
            margin: 20px 0;
            overflow: hidden;
        }

        .details {
            .table {
                > tbody {
                    > tr {
                        td {
                            &.client-name {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                max-width: 300px;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }
    }

    .table {
        > tbody {
            > tr {
                &.changed {
                    > td:first-of-type {
                        position: relative;
                        border-left: 4px solid $kelly;
                    }

                    > td {
                        font-weight: bold;
                    }
                }

                > td {
                    padding: 8px;

                    &.details {
                        padding-left: 35px;
                    }
                }
            }
        }
    }
}

@keyframes arrow {
    0%,
    100% {
        transform: scale(1.5) rotate(-45deg);
    }

    50% {
        transform: scale(1) rotate(-45deg);
    }
}