.btn-blue {
    background-color: #436396;
    border-color: #436396;
    color: $white;
    padding: 10px 12px;
}

.btn-default[disabled].btn-blue {
    background-color: $silver-medium;
    border-color: $silver-medium;
}

.btn-default.disabled:focus {
    color: $chalkboard-dark;
    background-color: $natural-dark;
}

.btn-info:hover,
.btn-info:focus,
.btn-default:hover,
.btn-default:focus {
    background-color: darken($sky, 5%);
}

.outline-button {
    border: 1px solid $white;
    color: $white;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    font-weight: 400;

    &:hover,
    &:focus {
        color: $white;
        text-decoration: none;
    }
}

.btn-feedback,
.btn-contact-us {
    font-size: 14px;
    margin-right: 13px;
    margin-top: 15px;
    color: $white;
    border: 0;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    padding-top: 0;
    line-height: 30px;
    background-color: $sky-button;
    border-radius: 15px;
    height: 30px;

    @include bp(small) {
        margin-right: 0;
        margin-top: 0;
    }
}

.btn-pager {
    background-color: transparent;
    border: 1px solid $silver;
    position: relative;
    margin-left: 10px;

    &.prev {
        .icon {
            transform: rotate(180deg);
            left: 10%;
        }
    }

    &.next {
        .icon {
            right: 10%;
        }
    }

    &.prev,
    &.next {
        .icon {
            position: absolute;
            top: 29%;
        }
    }

    &:hover,
    &:active,
    &:focus {
        background-color: transparent;
        border-color: $chalkboard-dark;
    }
}

.btn {
    color: $white;
    font-weight: 100;
    @include transition(all .5s ease);

    &-normal {
        background-color: $sky;

        &:hover {
            background-color: $normal-btn-hover;
        }
    }

    &-secondary {
        background-color: $sea-foam-medium;

        &:hover {
            background-color: $secondary-btn-hover;
        }
    }

    &-finality {
        background-color: $finality-btn;

        &:hover {
            background-color: $finality-btn-hover;
        }
    }

    &-warning {
        background-color: $cherry;

        &:hover,
        &:focus {
            background-color: $warning-btn-hover;
        }
    }

    &-jumbo {
        font-size: 18px;
        height: 60px;
    }

    &-default {
        font-size: 16px;
        height: 40px;

        &[disabled] {
            background-color: $natural-dark;
            border-color: $silver;

            &:hover {
                background-color: $natural-dark;
            }
        }
    }

    &-success {
        &[disabled] {
            background-color: $natural-dark;
            border-color: $silver;

            &:hover {
                background-color: $natural-dark;
            }
        }
    }

    &-small {
        font-size: 14px;
        height: 36px;
    }

    &-ex-small {
        font-size: 12px;
        height: 28px;
        padding-top: 6px;
    }

    &-full {
        width: 100%;
    }

    &[disabled] {
        pointer-events: visible;
    }

    &-link {
        padding: 0;
        outline: none;

        &:hover {
            text-decoration: none;
        }
    }

    &.btn-pager {
        color: $chalkboard-dark;
    }
}

.question-container {
    .btn {
        &-radio {
            background-color: $chalkboard-medium;

            &:hover {
                background-color: darken($chalkboard-medium, 15%);
            }

            &.yes {
                &.active {
                    background-color: $kelly;
                }
            }

            &.no {
                &.active {
                    background-color: $cherry;
                }
            }
        }
    }
}

.btn {
    &-radio {
        &.active {
            background-color: $sky;
        }
    }
}

.btn-add-client-bene {
    min-width: 275px;
    max-width: 275px;
}