.e-delivery {
    &-body {
        margin: 15px auto 0;
    }

    .select-all-container {
        margin: 25px 0;

        .all-paperless {
            strong,
            slider-switch {
                display: inline-block;
            }

            slider-switch {
                vertical-align: middle;
                margin-left: 15px;
            }

            strong {
                font-size: 16px;
            }

            small {
                display: block;
            }
        }

        p {
            margin: 0;
        }
    }

    .paperless {
        &-option {
            margin: 10px 0;
            overflow: hidden;

            p {
                margin: 0;
            }

            &-selections {
                padding-top: 5px;

                .btn {
                    &.active {
                        background-color: $kelly;
                    }
                }
            }

            small {
                font-size: 82%;
            }
        }
    }

    .footer {
        margin-top: 16px;
        border-top: 1px solid $silver;
        padding-top: 8px;

        check-box {
            margin-bottom: 15px;

            .wg-checkbox-container {
                margin-right: 10px;
                margin-top: 1px;
            }

            p {
                margin-top: 0;
            }
        }
    }

    &.user-settings {
        .alert {
            button {
                &.close {
                    top: 23px;
                }
            }
        }
    }

    info-heading-block {
        margin-top: 15px;

        h1 {
            font-size: 16px;
            margin-top: 0;
        }

        p,
        html-text-message {
            margin: 0;
        }
    }
}