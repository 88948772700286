.transactions-history {
    &-print {
        &-container {
            .general-details-table {
                tbody {
                    tr {
                        height: inherit;

                        td {
                            &:first-child {
                                padding-left: 0;
                            }
                        }

                        th {
                            font-size: 9px;
                            color: $cement-dark !important; // Thanks for that bootstrap
                            text-transform: uppercase;
                            letter-spacing: 1px;
                        }

                        .cell-container {
                            padding: 0;
                        }
                    }

                    &.visible-print-block {
                        display: table-row-group !important;
                    }
                }

                thead {
                    &.visible-print-block {
                        display: table-header-group !important;
                    }
                }
            }
        }
    }
}