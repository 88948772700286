.whats-new {
    &-dropdown {
        .icon {
            &-megaphone {
                &:after {
                    content: '';
                    width: 10px;
                    height: 10px;
                    background-color: transparent;
                    display: block;
                    position: absolute;
                    top: 16px;
                    right: 13px;
                    border-radius: 50%;
                    @include bp(small) {
                        right: 15px;
                        top: 17px;
                    }
                }
            }

            &-close {
                position: absolute;
                top: 28px;
                right: 10px;
                font-size: 24px;
                color: $cement-medium;
                display: none;

                @include bp(small) {
                    display: block;
                }
            }
        }

        .dropdown {
            &-menu {
                @include bp(small) {
                    width: 100%;
                }
                width: 400px;

                .content {
                    padding: 0 20px 15px;
                    overflow-y: auto;
                    max-height: 620px;
                }

                ul {
                    li {
                        cursor: initial;

                        &:hover {
                            background-color: transparent;
                        }
                    }
                }

                h1 {
                    font-size: 14px;
                    margin-bottom: 0;
                    color: $chalkboard;
                    border-top: 1px solid $chalkboard;
                    padding-top: 18px;

                    &:first-child {
                        border: none;
                        padding-top: 0;
                    }
                }

                h2 {
                    margin-top: 10px;
                    margin-bottom: 0;
                }

                h2,
                h3 {
                    font-size: 16px;
                }
            }
        }

        &.unread {
            .icon {
                &:after {
                    background-color: $cherry;
                }
            }
        }

        &.dropdown {
            &.open {
                display: inline-block;
            }
        }

        p {
            margin-top: 5px;
            line-height: 20px;
        }

        @media (max-height: 745px) {
            .content {
                height: 70vh;
            }
        }

        @media (max-height: 380px) {
            .content {
                height: 50vh;
            }
        }
    }

    &-date {
        display: block;
        padding: 10px 20px;
    }
}

.policy-summary {
    .whats-new-dropdown {
        display: none;
    }
}