body.policy-detail-coverages {
    .popup-container.modal-container {
        padding: 0;
    }

    .summary-container .popup-container {
        overflow: visible;
        overflow-y: visible;
    }

    .modal-header {
        padding: 0;
        margin: 15px 0;
        border-bottom: 1px solid $black;
    }

    .lightbox-quote-date-row {
        .hidden-xs,
        .hidden-is {
            display: block !important;
        }
    }

    .general-details-table {
        .general-table-structure {
            .row-container {
                .cell-container {
                    padding: 13px 8px;
                }
            }
        }
    }
}

body {
    &.policy-detail-riders,
    &.policy-detail-values {
        font-size: 11px;

        .modal-header {
            padding: 0;
            margin: 15px 0;
            border-bottom: 1px solid $black;
        }

        .field-value,
        label {
            font-size: 11px;
        }

        .section-heading {
            text-align: left;
        }
    }
}

.cov-info-title {
    font-size: 16px;
}

.general-details-table {
    font-size: 11px;
    overflow: hidden;
}

.coverageInfo {
    padding-bottom: 0;
}

.padding-top-30 {
    padding-top: 0;
}

.copyright {
    margin: 0 auto;
    padding: 5px 5px 28px;
}

.pua-rider-container {
    text-align: left;
    margin: 20px 0;

    .label {
        font-size: 11px;
        float: left;
    }

    .value {
        float: right;
    }
}
