.wg-client-add-payment-account {
    .panel {
        border: 0;

        &-heading {
            padding: 0;
            background-color: $natural-dark;
            border: 0;
            @include transition(all .25s ease);

            &:hover {
                background-color: darken($natural-dark, 5%);
            }

            .icon {
                &-close {
                    border: 1px solid $white;
                    position: absolute;
                    border-radius: 50%;
                    line-height: 30px;
                    padding-left: 8px;
                    left: 16px;
                    top: 14px;
                    transform: rotate(44deg);
                    @include transition(all .45s ease);
                }
            }
        }

        &-open {
            .panel {
                &-heading {
                    .icon {
                        &-close {
                            transform: rotate(0deg);
                        }
                    }
                }
            }
        }
    }

    .accordion-toggle {
        display: block;
        padding: 20px 20px 20px 60px;
    }
}

.panel {
    border: 0;
    box-shadow: none;

    &-title {
        a {
            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }

    &-body {
        padding: 15px 15px 15px 0;
    }

    &.view-info {
        background: $natural-medium;

        .panel-body {
            padding: 15px;
        }
    }
}