.modal-dialog-container {
    width: 100%;
    min-height: 100%;
    position: fixed;
    margin: auto;
    top: 0;
    padding: 20px;
    background: $modal-background;
    z-index: 10001;
    left: 0;
    right: 0;
    display: none;

    &.open {
        display: block;
        overflow-y: auto;
    }

    .center-point {
        top: 50%;
        left: 50%;
        position: absolute;
        width: 0;
        height: 0;
    }

    .modal-dialog {
        position: relative;
        width: 530px;
        margin-left: -265px;
        height: 340px;
        margin-top: -170px;

        .modal-content {
            border-radius: 5px;
            background-color: $white;

            .modal-header {
                background-color: $natural-medium;
                border-radius: 5px 5px 0 0;
                padding: 15px 30px;

                .modal-title {
                    font-size: 20px;
                    color: $chalkboard-dark;
                    line-height: 35px;
                    display: inline-block;
                    font-weight: 400;
                }

                .close {
                    color: $cement-medium;
                    display: inline-block;
                    line-height: 40px;
                    font-weight: 400;
                    top: 13px;
                    right: 25px;

                    &:hover {
                        color: $chalkboard-dark;
                    }
                }
            }

            .modal-body {
                padding: 15px 30px;
            }

            .modal-footer {
                text-align: right;
                padding: 15px 30px;
                margin-top: 15px;
                border: none;

                .btn-close {
                    padding: 10px 35px;
                }
            }
        }
    }
}
