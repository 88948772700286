@mixin nullify-border {
    border: 0;
    border-left: 0;
    border-bottom: 0;
}

.pending-summary-container {
    .bluelink {
        display: none;
    }

    .name-role-container p,
    .product-info-section .user-details-container .column-name span {
        display: inline;
    }

    .name-role-container {
        padding: 0;

        label::after {
            content: ":";
            margin-right: 1em;
        }
    }


    .client-widget {
        box-shadow: none;
        height: auto;
        line-height: 2.5em;
        margin: 0;
        padding: 0;

        .icon {
            display: none;
        }

        &.insured-link, &.owner-link, &.payor-link, &.annuitant-link {
            @include nullify-border;
        }
    }

    .relatives-container .relatives-hr {
        border-color: transparent;
    }

    .product-info-section {
        .separator-vertical-line {
            display: none;
        }

        .policy-header-content,
        .name-container .policy-header-content.pol-number { // We are all about the overrides.
            display: inline;
            font-size: 1.5em;
            line-height: 2em;
            margin-right: 1em;
            vertical-align: middle;
        }
    }

    .requirements-widget {
        label::after {
            content: ":";
        }
        .widget-text {
            font-size: 16px;
        }
    }
}

.row-colors {
    .insured-link, .owner-link, .payor-link, .annuitant-link {
        @include nullify-border;
    }
}
