.role-edit {
    margin-top: 30px;

    .progress-container {
        margin: 35px 0;
    }

    .progress-list {
        .step {
            &:before {
                border-left-color: $natural;
            }
        }
    }

    .action-buttons-container {
        margin: 35px 0;
        padding-top: 35px;
        border-top: 1px solid $natural-dark;
    }

    wg-checkbox {
        float: right;

        small {
            margin-left: 5px;
        }
    }

    &-modal {
        .modal {
            &-body {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                overflow: visible;

                .role-details,
                .personal-details,
                .personal-information {
                    label {
                        margin-top: 20px;
                    }
                }
            }
        }

        &.submit-interstitial {
            .datepicker {
                .icon {
                    position: absolute;
                    font-size: 24px;
                    top: 10px;
                    left: 8px;
                }

                &-container {
                    position: relative;
                }
            }

            .modal-body {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: row;

                slider-switch,
                .datepicker {
                    width: 100%;
                }

                slider-switch {
                    margin-top: 24px;
                }
            }
        }
    }
}