.text-normal {
    font-weight: normal;
}

.bold {
    font-weight: bold;

    &.total {
        color: $sky-medium;

        &:before {
            border-top: 1px solid $sky-medium;
            content: '';
            display: block;
            margin: 5px 0;
        }
    }
}

.mobile-h2 {
    margin: 28px 0;
    font-size: 20px;
}

.lh-20 {
    line-height: 20px;
}

.text-muted {
    color: $cement-medium;
}

h4 {
    color: #2d2d2d;
}

label {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;

    &.dark {
        color: #758691;
    }
}

label,
.label,
.section-user-info {
    @include bp(medium) {
        font-size: 14px;
    }
}

.label-info {
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #3f4042;
    background: none;

    &.white {
        color: $white;
    }
}

.disclaimer {
    font-size: 11px;
    line-height: 15px;
    color: $cement-medium;
}

.field-label {
    color: $cement-medium;

    // same as strong.field-label
    @at-root {
        strong#{&} {
            color: $chalkboard-dark;
        }
    }

    &.field-label-strong {
        font-weight: bold;
        color: $chalkboard-dark;
    }
}

.field-value {
    font-size: 14px;
    color: $chalkboard-dark;

    @at-root {
        strong#{&} {
            color: $sky;
        }
    }

    &.field-value-strong {
        font-weight: bold;
        color: $sky;
    }
}

.capitalize {
    text-transform: capitalize;
}

.bold {
    font-weight: bold;
}

.line-height-md {
    line-height: 1.5em !important;
}

//text size helpers
$size-values: .60em .70em .80em .90em 1em 1.1em 1.2em 1.3em 1.4em 1.5em;
$size-names: 1 2 3 4 5 6 7 8 9 10;
$counts: length($size-names);

@for $i from 1 to $counts + 1 {
    $sn: nth($size-names, $i);
    $sv: nth($size-values, $i);

    .text-size-#{$sn} {
        font-size: #{$sv} !important;
    }
}

