.info-page {
    margin-top: 50px;

    .header-box {
        display: block;
        background-color: $natural-medium;
        padding: 1.5em 2em;
        margin-bottom: 1em;

        h1 {
            font-size: 2em;
            font-weight: 400;
            color: $chalkboard-dark;
            margin: 0;
        }
    }

    .table {
        > tbody {
            > tr {
                > td {
                    @include bp($small) {
                        padding: 8px;
                    }
                }
            }
        }

        th {
            height: 2.75em;
            position: relative;

            &.pml-sticky {
                position: sticky;
                top: 63px;
            }

            .th-contents {
                display: flex;
                flex-direction: row;
                // align-content: left;
                position: absolute;
                top: 10px;
                left: 4px;
                bottom: 0;
                right: 0;

                span {
                    flex: 0 auto;
                    margin-right: .65em;
                    @include bp(smaller) {
                        white-space: nowrap;
                    }
                }
            }
        }

        .filter-arrows-container {
            // position: absolute;
            // right: 0;
            // top: 15px;
            display: inline-block;
            flex: 0 auto;
            margin-top: .35em;
            position: relative;

            .icon-sort {
                &-up-arrow,
                &-down-arrow {
                    display: block;
                    color: $chalkboard;
                    font-size: .705em;
                    position: absolute;

                    &.sorted {
                        color: $chalkboard-dark;
                        font-size: 20px;
                    }
                }

                &-up-arrow {
                    top: 0;

                    &.sorted {
                        top: -7px;
                        left: -5px;

                        &:before {
                            content: '\e911';
                        }
                    }
                }

                &-down-arrow {
                    bottom: 13px;

                    &.sorted {
                        top: 9px;
                        left: -5px;

                        &:before {
                            content: '\e912';
                        }
                    }
                }

                &-up-arrow {
                    margin-bottom: 5px;
                }

                &-down-arrow {
                    margin-top: -5px;
                }
            }
        }

        &.table-mobile {
            .filter-arrows-container {
                .icon-sort-down-arrow {
                    bottom: -22px;
                }
            }
        }
    }

    .general-details-table {
        .icon-sort {
            &-down,
            &-up {
                &-arrow {
                    transition: none;
                }
            }
        }
    }
}