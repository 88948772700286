.table-container {
    .table-header {
        background-color: #efefef;
        color: $black;
        height: 30px;
        line-height: 30px;
        font-weight: 600;
    }

    .table-row {
        height: 50px;
        line-height: 50px;
        color: #626a81;
        background-color: $white;
        border-bottom: solid #ccc 1px;
    }

    .table-column {
        padding-left: 30px;
        padding-right: 20px;
    }
}

.table {
    > thead {
        > tr {
            th {
                background-color: $natural-dark;
                padding: 12px 8px;
            }
        }
    }

    > tbody {
        > tr {
            background-color: $natural;

            > td {
                padding: 15px 8px;
                border-top: 0;
            }
        }
    }

    &.table-default {
        th {
            background-color: $natural-dark;
            vertical-align: middle;
        }

        td {
            background-color: $natural-medium;
        }

        > thead > tr > th,
        > tbody > tr > td,
        > tfoot > tr > td {
            padding: 13px 8px;

            &:first-child {
                padding-left: 20px;
            }

            &:last-child {
                padding-right: 20px;
            }
        }
    }

    &.table-borderless {
        > thead > tr > th,
        > tbody > tr > td,
        > tfoot > tr > td {
            border-top: 0;
        }

        &.table-accounting {
            > tbody > tr > td,
            > tfoot > tr > td {
                padding: 2px 8px;
                text-align: right;
            }

            hr {
                margin: 11px 0;
            }
        }
    }

    &.table-align {
        &-right {
            > tbody,
            > thead {
                > tr {
                    > td {
                        text-align: right;

                        &:first-child {
                            text-align: left;
                        }
                    }
                }

                &:first-child {
                    tr {
                        &:first-child {
                            > th {
                                text-align: right;

                                &:first-child {
                                    text-align: left;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &-mobile {
        margin-left: 34%;
        display: none;
        @include bp(small) {
            display: block;
        }

        .fixed-header {
            position: absolute;
            left: 15px;
            top: auto;
            background-color: $natural-dark;
            width: 33%;
            font-weight: 600;
            border-bottom: 0;
            border-top: 1px solid $natural-dark;

            &.subaccount-header {
                margin-top: 0;
                min-height: 165px;
            }
        }
    }

    &.condensed {
        thead,
        tbody {
            tr {
                th,
                td {
                    padding: 5px 4px;
                    font-size: 12px;
                }
            }
        }
    }
}

.table-responsive {
    overflow-y: hidden;
}

.links-above-table {
    padding-bottom: 12px;
    position: relative;
    z-index: 1;

    a {
        margin-left: 1em;
    }
}

.mobile {
    &-table-container {
        display: none;
        overflow: scroll;

        @include bp(small) {
            display: block;
        }
    }
}
