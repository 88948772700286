.summary-inforce .product-info-section {
    background: $kelly-medium;

    .separator-vertical-line {
        background-color: $kelly-medium-lines;
    }

    .product-info-hr {
        border-color: $kelly-medium-lines;
    }

    .user-details-container .column-name {
        color: $kelly-medium-text;
    }
}

.summary-annuity .product-info-section {
    background: $sky-medium;

    .separator-vertical-line {
        background-color: $sky-medium-lines;
    }

    .product-info-hr {
        border-color: $sky-medium-lines;
    }

    .user-details-container .column-name {
        color: $sky-medium-text;
    }
}

.product-info-section {
    font-size: 14px;
    color: $white;
    text-align: right;
    position: relative;

    .vertical-padding {
        height: 12px;
        @include bp(small) {
            height: 0;
        }
    }

    .product-info-hr {
        margin: 0;
    }

    wg-favorite-policy {
        position: absolute;
        z-index: 998;
        top: 17px;
        right: 16px;

        &.mobile-favorite {
            position: absolute;
            top: 25px;
            right: 15px;
        }

        .btn-favorite {
            vertical-align: baseline;
        }
    }

    .policy-header-content {
        @include bp(large) {
            &.pol-name {
                max-width: 80%;
            }
        }
    }

    .name-container,
    .user-details-container {
        text-align: left;
    }

    .user-details-container {
        padding-top: 13px;
        padding-bottom: 15px;
        padding-right: 55px;
        text-align: right;

        @include bp(medium) {
            text-align: right;

            .column-name {
                float: none;
                margin-right: 0;
                margin-left: 26px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
        @include bp(small) {
            text-align: left;
            padding-top: 14px;
            padding-right: 15px;

            &.right-aligned {
                text-align: right;
            }
        }

        &.client-details-container {
            padding-right: 15px;
        }

        .visible-status-next-line {
            display: none !important;

            @include bp($policy-summary-custom-bp) {
                display: block !important;
                height: 12px;
            }
        }

        .label {
            display: inline-block;
            font-weight: normal;
            margin: 0;
            padding: 0;
            text-align: left;
        }

        .column-name {
            float: none;
            position: relative;
            min-height: 1px;
            margin-left: 26px;
            margin-right: 0;
            width: auto;
            font-size: 14px;
            line-height: 22px;
            vertical-align: middle;
            display: inline-block;

            span {
                color: $white;
            }

            @include bp(medium) {
                span {
                    display: inline-block;
                }

                &:first-child {
                    margin-left: 0;
                }
            }
            @include bp(small) {
                margin-left: 0;
                margin-right: 26px;
                font-size: 16px;
                float: left;

                &:last-child {
                    margin-right: 0;
                }

                span {
                    display: block;
                }
            }
        }

        .vertical-padding {
            height: 12px;
        }
    }

    .name-container {
        padding-top: 14px;
        padding-bottom: 14px;

        @include bp(small) {
            padding-top: 25px;
            padding-bottom: 25px;
        }

        .policy-header-content {
            vertical-align: middle;
            display: inline-block;
            font-size: 16px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 400;
            line-height: 24px;

            &.pol-number {
                display: inline-block;
                color: $white;
                vertical-align: middle;

                @include bp(small) {
                    vertical-align: baseline;
                    display: block;

                    &.pol-name {
                        max-width: 70%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .icon {
        margin-right: 10px;
        vertical-align: middle;
    }
}
