.summary-container {
    .popup-container {
        position: relative;
    }
}

/* Hack for FF */
@-moz-document url-prefix() {
    .summary-container {
        .popup-container {
            margin-top: 55px;
        }
    }
}

/* Hack for IE.  @media is needed for the hack to work */
@media print and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .summary-container {
        .popup-container {
            margin-top: 15px;
        }
    }
}