.requirement-reply-inputs {
    &.relative-container {
        position: relative;
    }

    .content-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;

        .upload-widget {
            max-width: 20em;
            flex: 1 20em;
            background-color: white;
        }
    }

    textarea {
        height: 8em;
        width: 100%;
        resize: none;
    }

    .btn-normal {
        margin-left: .5em;
    }

    .well {
        min-height: 20px;
        padding: 19px;
        margin-bottom: 20px;
        border-style: solid;
        border-width: 1px;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    }

    .download-template {
        display: flex;
        flex-direction: row;
        flex: 1 auto;

        h5 {
            margin-bottom: 0;
        }

        p {
            padding: 0;
            margin: 0;
            font-weight: bold;
        }
    }

    .download-buttons {
        position: relative;
        display: flex;
        flex-direction: row;
        flex: 0 auto;
        align-items: flex-end;
        justify-content: flex-end;
    }
}

.upload-title {
    margin-bottom: 1em;
    flex: 1 auto;
    margin-right: 1em;
    margin-left: 1em;
}

.not-visible {
    visibility: hidden;
}

.upload-buttons {
    margin-top: 1em;
}


// .general-details-table tbody tr td.requirement-reply-inputs {
//     // Yes, we need to be this specific. :(
//     padding-right: 2.5em;
// }
.general-details-table {
    tbody {
        tr {
            background-color: inherit;

            td {
                border: none;

                .small-icon.open-note {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

.requirements-table {
    tr {
        &.req-row {
            td {
                border-top: 1px solid silver;
            }

            &-note {
                td {
                    border-top: none;
                }
            }
        }

        .req-reply {
            &-box {
                box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0px 1px 1px 0px rgba(0, 0, 0, .14), 0px 1px 3px 0px rgba(0, 0, 0, .08);
                padding: 1em;
                display: flex;
                flex: 1 auto;
                flex-direction: column;

                &-choice {
                    flex-direction: row;
                    justify-content: space-between;
                }
            }

            &-text {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex: 1 auto;
                margin-bottom: 1em;
                height: 6em;

                textarea {
                    flex: 1 auto;
                }
            }

            &-choice {
                display: flex;

                &-text {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }
            }

            &-upload {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex: 1 auto;
            }

            &-buttons {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: space-between;
                flex: 1 auto;
            }

            td {
                border: 0;
                margin: 0;
                padding: 0;
            }
        }
    }
}

.requirements-table {
    .status {
        padding: 2px 10px;

        &-ordered,
        &-pending,
        &-received {
            background-color: $apricot;
            color: $white;
            border-radius: 11px;
        }

        &-replied {
            background-color: $kelly;
            color: $white;
            border-radius: 11px;
        }
    }
}


