.transaction-history-table-filter-section {
    padding: 0 0 15px;

    .filter-section-dropdown {
        > div > span {
            color: $cement-medium;
        }

        &.opened {
            .transaction-type-menu {
                display: block;
            }
        }

        .transaction-type-menu {
            top: 30px;
            width: auto;

            ul li .cat-name {
                width: 100%;
            }
        }
    }
}

.transaction-history-table {
    .transaction-details-table {
        tr {
            &.expanded {
                background-color: #f7f7f7;

                .small-icon {
                    @include transform(rotate(180deg));
                }
            }

            .small-icon {
                padding: 0;
                margin-left: 5px;
                @include transition(all .15s ease);
            }
        }

        .btn-show-details {
            color: #333333;
            text-decoration: none;

            &:hover,
            &:visited {
                color: #333333;
                text-decoration: none;
            }
        }

        .transaction-details {
            background-color: #f7f7f7;

            > td {
                border-top: 1px solid $silver;
                padding: 20px 20px 20px 25px !important;

                tr {
                    background-color: #f7f7f7;

                    td {
                        border-top: 1px solid $silver;
                        padding-bottom: 10px !important;
                        padding-top: 10px !important;
                    }

                    &:first-child {
                        td {
                            border: none;
                        }
                    }
                }
            }

            .table-transaction-details {
                tr {
                    td {
                        border: 0;
                    }
                }
            }
        }
    }

    .transaction-details-table {
        &.margin-bottom-95 {
            margin-bottom: 95px;
        }

        &.margin-bottom-150 {
            margin-bottom: 150px;
        }
    }
}

.transaction-full-line-item-amount {
    float: right;
}