.header-container {
    position: fixed;
    z-index: 1001;
    width: 100%;
    top: 56px;
    @include bp(small) {
        top: 0;
    }

    &.menu-open {
        z-index: 10002;
    }

    .dropdown {
        .dropdown-menu {
            li {
                border-bottom: 1px solid $silver;

                &:nth-last-of-type(1) {
                    border: 0;
                }
            }
        }
    }

    &.legacy,
    &.client-header {
        top: 0;
    }
}

.header {
    background-color: #061f2c;
    padding: 0;
    min-width: 320px;
    height: 63px;

    .login-message {
        color: $sea-foam-dark;
        font-size: 14px;
        margin-top: 12.5px;
        margin-bottom: 12.5px;

        span {
            color: $white;
        }
    }

    .logo-container {
        padding-top: 16px;
        cursor: pointer;

        .logo {
            color: $white;
            font-size: 30px;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .logout,
    .my-profile {
        color: $white;
        font-size: 14px;
        margin-top: 20px;
        display: block;
        text-decoration: none;
        margin-right: 30px;

        &:hover {
            text-decoration: none;
        }

        .icon-logout,
        .icon-user {
            padding: 0;
            margin-right: 10px;
            height: auto;
        }

        .icon-user {
            margin-top: -2px;
            font-size: 16px;
        }

        .icon-logout {
            font-size: 19px;
        }
    }

    .btn-contact-us {
        margin-top: 17px;
        margin-right: 0;
    }

    .icon-hamburger {
        margin-top: 18px;
        padding: 0 0 0 12px;
        height: auto;
    }

    .my-advisors {
        color: $white;
        font-size: 14px;
        margin-top: 20px;
        display: block;
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;

        &:hover {
            text-decoration: none;
        }

        .gray-text {
            color: #9ba5aa;
        }
    }

    .login-message {
        color: $sea-foam-dark;
        font-size: 14px;
        margin-top: 12.5px;
        margin-bottom: 12.5px;

        span {
            color: $white;
        }
    }

    .header-title {
        line-height: 63px;
        font-size: 16px;
        color: $white;
        text-transform: capitalize;

        &.mobile-dropdown-middle {
            height: 62px;
            line-height: 22px;

            party-selector-dropdown {
                margin-top: 20px;
            }
        }
    }

    .left-column {
        height: 63px;
    }

    .left,
    .center,
    .right {
        &-column {
            flex: 1 auto;
        }
    }

    .vertical-separator {
        width: 1px;
        height: 26px;
        opacity: .3;
        border-left: 1px solid $white;
        margin: 19px 20px 0;

        .client-site & {
            margin: 19px 20px 0 26px;
        }
    }

    .client-site & {
        .nav-pills {
            display: inline-block;
            margin-left: -5px;
            margin-top: 20px;

            & > li {
                & > a {
                    background: none;
                    color: $white;
                    padding: 0 15px;
                }
            }
        }

        .logout,
        .my-profile {
            color: $white;
            font-size: 14px;
            margin-top: 20px;
            display: block;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }

            .icon-logout,
            .icon-user {
                padding: 0;
                margin-right: 10px;
                height: auto;
            }

            .icon-user {
                margin-top: -2px;
                font-size: 16px;
            }

            .icon-logout {
                font-size: 19px;
            }
        }

        .logout {
            margin-right: 0;
        }

        .btn-contact-us {
            margin-top: 17px;
            margin-right: 0;
        }

        .icon-hamburger {
            margin-top: 18px;
            padding: 0 0 0 12px;
            height: auto;
        }
    }

    .icons-container {
        margin-top: 20px;
        display: inline-block;

        .icon {
            width: 20px;
            height: 20px;
            display: inline-block;
        }
    }

    .last-login-label,
    .last-login-date-label,
    .last-login-hour-label {
        display: inline-block;
        font-size: 12px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .last-login-label {
        color: $sea-foam-dark;
        padding-left: 20px;
    }

    .last-login-date-label {
        color: $white;
        padding-left: 10px;
    }

    .separator {
        color: $sea-foam-dark;
    }

    .last-login-hour-label {
        color: $white;
    }

    .icon.icon-arrow-right {
        color: $white;
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        font-weight: 600;
        font-size: 20px;
        cursor: pointer;
        display: inherit;
    }

    .icon {
        &.icon-user,
        &.icon-alert,
        &.icon-search,
        &.icon-logout,
        &.icon-hamburger,
        &.icon-megaphone,
        &.icon-external-link,
        &.icon-arrow-right {
            color: $white;
            font-size: 24px;
            line-height: 28px;
            cursor: pointer;
            margin: 0;
            padding: 18px 16px;
        }

        &.icon-alert {
            @include bp(small) {
                padding-right: 0;
            }
        }

        &.icon-arrow-right {
            font-size: 20px;
            padding: 18px 0px 18px 10px;
        }

        &.icon-logout-shiftdown {
            padding-top: 19px;
        }

        &.icon-user {
            font-size: 20px;
            padding-top: 18px;
            margin-left: 6px;
        }

        &.icon-hamburger {
            padding-left: 12px;
        }

        &.icon-search {
            padding-left: 0;
            padding-right: 0;
        }

        &.icon-logout,
        &.icon-external-link {
            padding: 0 10px 0 5px;
        }

        &.icon-external-link {
            vertical-align: text-bottom;
            font-size: 12pt;
            color: $sea-foam;
        }

        &.icon-logout {
            vertical-align: middle;
        }

        &.no-right-space {
            padding-right: 0;
            margin-right: 0;
        }

        &.icon-megaphone {
            @include bp(small) {
                padding-left: 15px;
            }
        }
    }

    .icon.icon-alert {
        color: $white;

        &.btn-alert-mobile {
            display: none;
        }

        // &.opened {
        // }
    }

    .icon.icon-logout,
    .icon.icon-hamburger {
        &.btn-logout-mobile {
            display: none;
        }
    }

    .welcome-msg {
        color: $white;
        margin-top: 5px;

        &.welcom-msg-no-l-margin {
            margin-left: 0;
        }

        .cursor-click {
            cursor: pointer;
        }

        .no-party-list {
            cursor: default;
        }
    }

    .welcome-msg-label {
        color: #8b9ea6;
    }

    a.dropdown-item-wrap {
        display: block;
        background-color: $white;

        &:focus {
            background-color: $natural-medium;
        }
    }

    .small-icon {
        padding-left: 8px;
        font-size: 10px;
        font-weight: bold;
        vertical-align: middle;
    }

    wg-whats-new {
        @include bp(small) {
            float: left;
        }
    }

    .alert-indicator {
        position: relative;

        .alert-counter {
            font-family: $font-family-sans-serif;
            position: absolute;
            top: 8px;
            left: 25px;
            background: crimson;
            @include bp(small) {
                top: 13px;
                left: 2px;
                font-size: 11px;
            }

            &.badge {
                @include bp(small) {
                    padding: 2px 4px;
                }
            }
        }
    }

    .search-icon,
    .whats-new-icon,
    .alerts-icon,
    .hamburger-icon,
    .back-icon,
    .party-selector {
        &-container {
            display: inline-block;
            vertical-align: top;
        }
    }

    .party-selector-container {
        padding-top: 5px;
    }

    .alerts-icon-container {
        @include bp(small) {
            margin-left: -10px;
        }
    }

    .left {
        &-column {
            .icon {
                &.icon-alert,
                &.icon-megaphone {
                    @include bp(small) {
                        padding-left: 0;
                    }
                }
            }
        }
    }

    party-selector-dropdown {
        color: $white;
    }

    .normal-menu-container {
        display: inline-block;
    }

    &-lg {
        height: 80px;
        background-color: $common-nav-underline;
        padding-top: 16px;

        .logo {
            &.icon {
                width: 200px;
                height: auto;
                display: inline-block;
                font-size: 37px;
                position: relative;

                &:after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    width: 1px;
                    height: 50px;
                    background-color: $lg-nav-vertical-border;
                    right: -35px;
                    top: -2px;
                    @include bp(small) {
                        display: none;
                    }
                }
            }
        }

        .app-name {
            color: $white;
            display: inline-block;
            font-family: 'TiemposHeadline';
            font-size: 38px;
            line-height: 43px;
            margin-left: 63px;
            font-weight: normal;
            margin-top: 0;
            @include bp(small) {
                display: none;
            }
        }

        ul {
            &.icons-list {
                > li {
                    display: inline-block;
                    margin-left: 5px;

                    &.party-selector-item {
                        &.mobile {
                            @include bp(777px) {
                                display: block;
                            }
                        }
                    }
                }

                .party-selector-item {
                    @include bp(777px) {
                        display: none;
                    }

                    &.mobile {
                        display: none;
                        @include bp(777px) {
                            display: block;
                        }
                    }
                }
            }
        }

        .icon {
            &.icon-user,
            &.icon-alert,
            &.icon-search,
            &.icon-logout,
            &.icon-hamburger,
            &.icon-megaphone,
            &.icon-external-link,
            &.icon-arrow-right {
                color: $white;
                font-size: 24px;
                line-height: 28px;
                cursor: pointer;
                margin: 0;
                padding: 0;
            }

            &.icon-alert,
            &.icon-search,
            &.icon-hamburger,
            &.icon-megaphone,
             {
                margin-left: 17px;
            }

            &.icon-hamburger {
                &:before {
                    position: relative;
                    top: 6px;
                }
            }
        }

        .search-icon,
        .whats-new-icon,
        .alerts-icon,
        .hamburger-icon,
        .back-icon,
        .party-selector {
            &-container {
                display: inline-block;
                vertical-align: top;
            }
        }

        .small-icon {
            padding-left: 8px;
            font-size: 10px;
            font-weight: bold;
            vertical-align: middle;
        }

        party-selector-dropdown {
            color: $white;

            .party-selector-click {
                margin-top: 3px;
                cursor: pointer;
            }
        }

        .whats-new-dropdown {
            .icon-megaphone {
                &:after {
                    top: 0;
                    right: -2px;
                }
            }
        }

        .profile-item {
            @include bp(small) {
                margin-left: 28px;
            }

            .full-name {
                color: $white;

                &:before {
                    content: '\e900';
                    font-family: 'icomoon';
                    font-size: 32px;
                    position: absolute;
                    left: -12px;
                    top: -14px;
                }
            }

            .icon-close {
                position: absolute;
                top: 28px;
                right: 10px;
                font-size: 24px;
                color: $cement-medium;
            }

            .dropdown-menu {
                .content {
                    padding: 0 20px 15px;
                    overflow-y: auto;
                    max-height: 620px;

                    h2 {
                        font-family: 'TiemposHeadline';
                        font-size: 18px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 20px;
                        display: inline-block;
                        padding-bottom: 2px;
                        cursor: pointer;
                    }
                }
            }
        }

        .alert-indicator {
            .alert-counter {
                font-family: $font-family-sans-serif;
                position: absolute;
                top: -7px;
                left: 12px;
                background: crimson;
                @include bp(small) {
                    top: -5px;
                    left: 12px;
                    font-size: 11px;
                }

                &.badge {
                    @include bp(small) {
                        padding: 2px 4px;
                    }
                }
            }
        }
    }
}

body.search .header {
    .welcome-msg {
        display: inherit;
        margin-left: 0;
    }

    .icon.icon-arrow-right {
        display: none;
    }

    .icon.icon-search {
        display: none;
    }

    .icon.icon-alert {
        &.btn-alert-mobile {
            display: inherit;
            padding-left: 0;
            width: 30px;
        }
    }

    .icon-logout,
    .icon-hamburger {
        &.btn-logout-mobile {
            display: inherit;
        }
    }
}

body {
    &.search {
        .header {
            &-lg {
                .icon.icon-search {
                    display: none;
                }
            }
        }
    }
}

.mobile-header-feedback {
    background-color: #015d82;
    width: 100%;
    text-align: center;
    height: 45px;

    .btn-feedback,
    .btn-contact-us {
        font-size: 16px;
        padding: 12px 0 3px;
        color: $white;
        border: 0;
        font-weight: 400;
        cursor: pointer;
        text-decoration: none;
        line-height: 20px;

        .icon-arrow-right {
            padding-left: 10px;
            font-weight: 800;
            font-size: 12px;
            line-height: 22px;
            display: inline-block;
        }
    }
}

.dropdown {
    .alert {
        &-dropdown {
            &.dropdown {
                &-menu {
                    ul {
                        li {
                            &:hover {
                                background-color: inherit;
                            }
                        }
                    }
                }
            }
        }
    }
}

body {
    &.login {
        .mobile-header-feedback {
            display: none !important;
        }
    }

    &.client,
    &.alert-view,
    &.policy-alerts,
    &.policy-requirements {
        .icon-megaphone {
            display: none;
        }
    }
}

// MOBILE STYLES
@media (max-width: 749px) {
    .header .icon {
        &.icon-logout-shiftdown,
        &.icon-profile {
            padding-left: 4px;
        }

        &.icon-user,
        &.icon-logout {
            margin-left: 0;
            padding: 0 10px 0 0;
        }

        &.icon-search {
            margin: 0 2px 0 0;
        }
    }
}
