//
// Alerts
// --------------------------------------------------


// Base styles
// -------------------------

.alert {
    padding: $alert-padding;
    margin-bottom: $line-height-computed;
    border: 1px solid transparent;
    border-radius: $alert-border-radius;
    font-size: 16px;
    line-height: 20px;
    // Headings for larger alerts
    h4 {
        margin-top: 0;
        // Specified for the h4 to prevent conflicts of changing $headings-color
        color: inherit;
    }
    // Provide class for links that match alerts
    .alert-link {
        font-weight: $alert-link-font-weight;
    }
    // Improve alignment and spacing of inner content
    > p,
    > ul {
        margin-bottom: 0;
    }

    > p + p {
        margin-top: 5px;
    }
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissable, // The misspelled .alert-dismissable was deprecated in 3.2.0.
.alert-dismissible {
    padding-right: ($alert-padding + 20);
    // Adjust close link position
    .close {
        position: absolute;
        top: 50%;
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 5px;
        padding: 0 10px;
        color: inherit;
        opacity: 1;
        font-weight: 100;
        font-size: 30px;
        line-height: 26px;
        font-family: 'Open Sans', sans-serif;

        &:hover {
        }
    }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

.alert-success {
    @include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
}

.alert-info {
    @include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text);
}

.alert-warning {
    @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
}

.alert-danger {
    @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
}
