.download-forms {
    &.download-forms-view-container {
        .modal-body {
            padding-top: .5em;

            company-forms {
                display: block;

                lob-section {
                    display: block;

                    lob-company-container {
                        display: flex;
                        flex-direction: column;

                        h5 {
                            font-weight: normal;
                            font-size: 1em;
                            margin-top: 2em;
                            margin-bottom: 1em;
                        }

                        lob-forms {
                            display: block;

                            lob-form {
                                align-items: baseline;
                                padding-bottom: 1em;
                                display: block;

                                i {
                                    margin-right: .4em;
                                }
                            }
                        }
                    }
                }
            }

            .forms-filter {
                position: relative;
                margin: 15px 0;

                .icon-close {
                    position: absolute;
                    right: 25px;
                    top: 16px;
                    cursor: pointer;
                }
            }
        }
    }

    .external-links {
        margin-top: 15px;

        p {
            margin-top: 0;
        }
    }
}