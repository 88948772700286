.progress {
    &-container {
        display: table;
        table-layout: fixed;
        width: 100%;
        border-spacing: 2px;
        margin: 10px 0;
    }

    &-list {
        display: table-row;
        margin: 0;
        padding: 0;

        .step {
            text-transform: capitalize;
            text-align: center;
            display: table-cell;
            padding: 10px 10px 10px 15px;
            background-color: $natural-dark;
            position: relative;

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 0;
                border-top: 22px solid transparent;
                border-bottom: 21px solid transparent;
            }

            &:before {
                left: 0;
                border-left: 10px solid $white;
            }

            &:after {
                left: 100%;
                border-left: 10px solid $natural-dark;
                z-index: 20;
            }

            &.active {
                background-color: $kelly;
                color: $white;
                font-weight: bold;
            }

            &.active {
                &:after {
                    border-left-color: $kelly;
                }
            }
        }
    }
}