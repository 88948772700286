.header-box {
    display: block;
    background-color: $natural-medium;
    padding: 1.5em 2em;
    margin-bottom: 1em;

    h1 {
        font-size: 2em;
        font-weight: 400;
        color: $chalkboard-dark;
        margin: 0;
    }
}