pml-ng-file-upload {
    display: flex;
    position: relative;
    border: dashed $chalkboard .1em;
    @include bp($small) {
        margin: 15px;
    }

    dt-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 1em;
        flex: 1 auto;

        drop-target,
        upload-progress {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            cursor: pointer;
            flex: 1 auto;

            div.progress {
                width: 100%;
            }

            p {
                font-size: 1em;
            }

            drag-message {
                font-size: 1.1em;
                font-weight: bold;
                text-align: center;
            }

            drop-message {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(121, 121, 121, .25);
                vertical-align: middle;
                text-align: center;
                font-weight: bold;
            }
        }

        drop-target {
            i {
                font-size: 50px;
            }

            u {
                color: $bright-link;
                text-decoration: none;
            }
        }
    }
}

