wg-policy-relatives {
    display: block;
}

.relatives-container {
    box-shadow: 0 2px 4px 0 $shadow;
    position: relative;

    .relatives-hr {
        border-color: $silver-medium;
        margin: 0;
    }

    .btn {
        vertical-align: text-bottom;
    }
}

.owner-link .icon.user {
    background-color: $kelly;
}

.insured-link .icon.user,
.annuitant-link .icon.user {
    background-color: $apricot-dark;
}

.assignee-link .icon.user,
.payor-link .icon.user,
.beneficiary-link .icon.user {
    background-color: $sky;
}

.client-widget {
    padding: 15px;
    height: 76px;
    cursor: pointer;
    background-color: $white;
    @include transition(all .25s ease);

    .disabled {
        cursor: default !important;
    }

    &.disabled {
        cursor: default !important;

        &:hover {
            background-color: inherit;
        }
    }

    &:hover {
        background-color: #e5e5e5;
    }

    &.owner-link {
        @include policy-relatives-border-left($apricot-dark);
    }

    &.insured-link,
    &.annuitant-link {
        @include policy-relatives-border-left($sky);
    }

    &.payor-link,
    &.beneficiary-link {
        @include policy-relatives-border-left($kelly);
    }

    &.disabled {
        .name-role-container {
            .name {
                color: $silver-dark;
            }
        }

        .dropdown-menu {
            & > li > .relatives-menu-link {
                color: $silver-dark;
                text-decoration: none;
            }
        }
    }

    .name-role-container,
    .icon.user,
    .col-arrow {
        display: inline-block;
        vertical-align: middle;
    }

    .icon {
        border-radius: 50%;
        color: white;
        width: 34px;
        font-size: 17px;
        text-align: center;
        line-height: 34px;

        &.arrow {
            color: $cement;
            font-weight: 600;
            font-size: 18px;
            line-height: 18px;
            padding-top: 7px;
        }

        &.icon-eye {
            font-size: 24px;
            font-weight: normal;
        }
    }

    .icon-multiple-users {
        font-size: 22px;
    }

    .name-role-container {
        padding: 0 30px 0 15px;
        width: calc(100% - 40px);

        .role {
            padding: 0;
            margin: 0;
        }

        .name {
            padding: 0;
            margin: 0;
            font-size: 18px;
            line-height: 26px;
            color: $chalkboard-dark;
            -ms-text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .col-arrow {
        position: absolute;
        height: 18px;
        right: 12px;
        top: 50%;
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

.row-colors {
    padding: 0;
    // height: 0;

    div[class*="-link"] {
        box-shadow: 0 2px 4px 0 $shadow-color;
    }

    .owner-link {
        @include policy-relatives-border-bottom($kelly);
    }

    .insured-link,
    .annuitant-link {
        @include policy-relatives-border-bottom($apricot-dark);
    }

    .payor-link,
    .beneficiary-link {
        @include policy-relatives-border-bottom($sky);
    }

    .unclickable {
        cursor: default;
    }
}

.policy-relatives-menu {
    .dropdown-menu {
        top: 0;
        border: 0;
        width: calc(100% - 32px);
        padding: 0;
        margin: 0;
        margin-left: 16px;

        & > li > a {
            font-size: 14px;
            color: $chalkboard-dark;
            padding: 15px 20px;
            text-transform: capitalize;
            border-bottom: solid 1px $natural-medium;

            &:hover,
            &:focus {
                background: none;
                background-color: $natural-medium;
            }
        }
    }
}
