.copyright {
    width: calc(100% - 30px);
    text-align: center;
    height: auto;
    line-height: 17px;
    padding: 28px 5px;
    margin: 30px auto 0;
    opacity: 1;
    transition-timing-function: ease;
    -webkit-transition: opacity ease 1s;
    transition: opacity ease 1s;

    .search & {
        padding: 0;
        margin: 6px auto 26px;
    }

    &.hide-footer {
        transition-timing-function: step-start;
        opacity: 0;
        height: 0;
        padding: 0;
        margin: 0;
        width: 0;
        font-size: 0;
    }

    p,
    a {
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        line-height: 17px;
    }

    p {
        color: #939393;
        margin: 0;
    }

    a {
        color: #0089d5;
    }

    span {
        color: $chalkboard-dark;
        font-weight: 800;
    }

    br {
        &.break-line-1,
        &.break-line-2 {
            display: block;
        }

        &.break-line-1 {
            @include bp(medium) {
                display: none;
            }
        }
    }

    & > ul {
        display: inline-block;
        list-style: none;
        margin: 0 auto;
        padding: 0;
        position: relative;

        & > li {
            position: relative;
            font-size: 12px;
            line-height: 17px;
            vertical-align: middle;
        }
    }

    @media (min-width: 480px) {
        br {
            &.break-line-2 {
                display: none;
            }
        }

        & > ul {
            & > li {
                float: left;
                padding: 0 8.5px;

                &:before {
                    content: '';
                    display: block;
                    width: 1px;
                    height: 15px;
                    background-color: #cfcfcf;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;
                }

                &:first-child {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}
