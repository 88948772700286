.pending-summary-container,
.requirements-view-container {
    h4 {
        margin: 39px 0 36px;
    }

    hr {
        margin-top: 15px;
        margin-bottom: 24px;
    }

    .separator-vertical-line {
        background-color: $silver;
    }

    .field-container {
        padding-left: 26px;

        &.right-side {
            padding-left: 0;
            float: left;
            clear: left;

            @include bp(large) {
                padding-left: 26px;
                float: right;
                clear: none;
            }
        }
    }

    .widget-text {
        display: inline-block;
        vertical-align: middle;
    }

    .pending-text-format {
        font-size: 16px !important;
        display: inline-block;
    }

    .pending-policy-note {
        padding: 15px 30px 15px 0;
        text-align: left;

        .note-body {
            padding: 6px 0 2px;
        }
    }

    .pending-requirement-box {
        background-color: $natural-medium;
        color: $chalkboard-dark;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 15px;

        .box-header {
            background-color: $natural-medium;
            width: 100%;

            .open-note {
                .col-icon {
                    .icon {
                        &-sort-down-arrow {
                            transform: rotate(180deg);
                        }

                        &-policy {
                            color: $silver-medium;
                        }
                    }
                }
            }

            .note-row {
                display: table-row !important;
                background-color: $natural;
                border: none;
            }

            .req-code,
            .col-note-icon {
                padding: 18px 15px 20px;
            }

            .req-code {
                font-size: 20px;
                line-height: 25px;
                margin-top: 0;
                margin-bottom: 0;
                min-height: 78px;
            }

            .col-icon {
                width: 50px;
                text-align: center;

                .icon {
                    &-policy {
                        margin-top: 22px;
                        font-size: 22px;
                        color: $cement-medium;
                        cursor: pointer;
                        display: block;
                        width: 50px;

                        &[disabled] {
                            &:before {
                                cursor: default;
                            }
                        }
                    }

                    &-sort-down-arrow {
                        @include transition(all .15s ease);
                    }
                }
            }
        }

        .xs-pending-policy-note {
            padding: 16px 10px 20px 15px;
            background-color: $natural;

            .note-body {
                padding: 5px 0 0;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
            }
        }

        .box-body {
            padding: 15px 15px 20px;

            label {
                line-height: 18px;
                margin-bottom: 7px;
            }

            .label {
                &.column-content {
                    line-height: 18px;
                }
            }
        }
    }
}
