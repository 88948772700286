.left {
    float: left;
}

.right {
    float: right;
}

.inline-block {
    display: inline-block;
}

.inline {
    display: inline;
}

.link-separator {
    background-color: #cacaca;
    margin: -.25em .4em;
    width: 1px;
    height: 1.4em;
    display: inline-block;
}

.separator-vertical-line {
    display: inline-block;
    vertical-align: middle;
    margin: 1px 16px;
    width: 1px;
    height: 18px;
    background-color: #496f7d;

    @include bp(xsmall) {
        margin: 1px 4px;
    }
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.noclick {
    pointer-events: none;
}

.div-relative-container {
    position: relative;
}

.pos-on-right {
    right: 0;
}

.scrollable {
    overflow-y: scroll;
}

.fixed-content {
    position: fixed;
    width: 100%;
    min-height: 100%;
}

.vcenter {
    display: inline-block;
    vertical-align: middle;
}

.vcenter-absolute {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.section-list .title {
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    padding-bottom: 28px;
    padding-top: 28px;
    margin-bottom: 0;

    @include bp(medium) {
        padding-bottom: 30px;
        padding-top: 38px;
        margin-bottom: 10px;
        font-size: 24px;

        &.with-message {
            padding-bottom: 0;
        }
    }
}

.full-height {
    height: 100%;
}

.container-fluid {
    max-width: $max-width;
}

.full-width {
    max-width: none;
    width: 100%;
}

.page-count-message {
    margin-bottom: 30px;
    font-size: 15px;
    line-height: 15px;
    margin-top: 2px;
    color: $cement-medium;
    text-align: center;

    @include bp(medium) {
        margin-bottom: 40px;
        font-size: 14px;
        line-height: 14px;
        margin-top: 0;
    }
}

.inline-content {
    .column-label,
    .column-content {
        display: inline-block;
        vertical-align: middle;
        margin-left: 2px;
        margin-bottom: 0;
    }
}

.not-long-text {
    white-space: nowrap;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.mobile-not-long-text {
    @include bp(small) {
        white-space: nowrap;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        display: inherit;
    }
}

.multi-line {
    white-space: normal;
}

hr {
    border-color: $silver;
}

.row {
    &.thin-gutter {
        margin-left: -7px;
        margin-right: -7px;

        [class*="col-"] {
            padding: 0 7px;
        }
    }
}

.section-pad-bot {
    padding-bottom: 30px;
}

.main-view-container {
    &.legacy-nav,
    &.client {
        .summary-inforce {
            .product-info-section {
                background: $kelly-medium;
            }
        }

        .summary-annuity {
            .product-info-section {
                background: $sky-medium;
            }
        }

        .section-top-info {
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#003a58+0,006489+100 */
            background: #003a58; /* Old browsers */
            background: -moz-linear-gradient(top, #003a58 0%, #006489 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #003a58), color-stop(100%, #006489)); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(top, #003a58 0%, #006489 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(top, #003a58 0%, #006489 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(top, #003a58 0%, #006489 100%); /* IE10+ */
            background: linear-gradient(to bottom, #003a58 0%, #006489 100%); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#003a58', endColorstr='#006489', GradientType=0); /* IE6-9 */
        }
    }

    .section-top-info {
        background: $search-bg;
    }
}

.col-margin-bottom-separation {
    & > [class*="col-xs"],
    & > [class*="col-is"],
    & > [class*="col-sm"],
    & > [class*="col-md"],
    & > [class*="col-lg"] {
        margin-bottom: 20px;
    }
}

.vertical-padding {
    height: 20px;
    clear: both;

    &.view-application {
        height: 5px;
    }
}

.small-icon {
    font-size: 8px;
    vertical-align: middle;
}

@include bp(small) {
    .mobile-stack {
        display: block;
    }
}

.fl {
    float: left;
}

.hr-strong {
    border-color: $chalkboard-dark;
}

.border-none {
    border: none !important;
}

.field-space-bot {
    margin-bottom: 15px;
}

.no-margin {
    margin: 0 !important;
}

.margin-top {
    margin-top: 20px !important;
}

.margin-top-bottom-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.padding-bottom-18 {
    padding-bottom: 18px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-top-30 {
    padding-top: 30px;
}

.padding-bottom-16 {
    padding-bottom: 16px;
}

.padding-bottom-22 {
    padding-bottom: 22px;
}

.padding-left-20 {
    padding-left: 20px;
}

.no-padding-left {
    padding-left: 0;
}

.no-padding-right {
    padding-right: 0;
}

.big-padding-right {
    padding-right: 35px;
}

.padding-0 {
    padding: 0 !important;
}

.half-padding-left {
    padding-left: 7px !important;
}

.half-padding-right {
    padding-right: 7px !important;
}

.padding-right-8-34 {
    padding-right: 8.34px !important;
}

.padding-left-8-34 {
    padding-left: 8.34px !important;
}

.padding-right-11-67 {
    padding-right: 11.67px !important;
}

.padding-left-11-67 {
    padding-left: 11.67px !important;
}

.if {
    &-fade {
        &.ng-enter {
            transition: .15s linear all;
            opacity: 0;
        }

        &.ng-enter {
            &.ng-enter-active {
                opacity: 1;
            }
        }

        &.ng-leave {
            transition: .15s linear all;
            opacity: 1;

            &.ng-leave-active {
                opacity: 0;
            }
        }
    }

    &-height-fade {
        &.ng-enter {
            transition: .15s linear all;
            opacity: 0;
            height: 0;
        }

        &.ng-enter {
            &.ng-enter-active {
                opacity: 1;
                height: 100px;
            }
        }

        &.ng-leave {
            transition: .15s linear all;
            opacity: 1;
            height: 100px;

            &.ng-leave-active {
                opacity: 0;
                height: 0;
            }
        }
    }
}

//margin padding helpers
$spacing-values: .15em .33em .66em 1em 1.25em 1.5em;
$spacing-names: xs sm md lg xl xxl;
$spacing-dir: top left bottom right;
$counts: length($spacing-names);

@for $i from 1 to $counts {
    $sn: nth($spacing-names, $i);
    $sv: nth($spacing-values, $i);

    @each $dir in $spacing-dir {
        .padding-#{$sn}-#{$dir} {
            padding-#{$dir}: #{$sv};
        }

        .margin-#{$sn}-#{$dir} {
            margin-#{$dir}: #{$sv};
        }
    }
}

.bg-transparent {
    background: transparent !important;
}

.border-none {
    border: 0 !important;
}

.border-top {
    border-top: 1px solid $silver;
}

.cursor-hand {
    cursor: pointer;
}

.underline-text {
    &:hover {
        text-decoration: underline;
    }
}

.strikethrough {
    text-decoration: line-through;
}

.invalid-text {
    color: black;
    background: yellow;
    font-weight: bold;
    padding: 2px;
}