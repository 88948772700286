.table {
    &.table-align {
        &-right {
            > tbody,
            > thead {
                > tr {
                    > td {
                        text-align: right;

                        &:first-child {
                            text-align: left;
                        }
                    }
                }

                &:first-child {
                    tr {
                        &:first-child {
                            > th {
                                text-align: right;

                                &:first-child {
                                    text-align: left;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}