.contactus-container {
    margin-top: 30px;

    info-heading-block {
        margin-bottom: 20px;
    }

    textarea {
        resize: none;
        height: 200px;
    }

    dropdown {
        .dropdown {
            &-item {
                .contact {
                    p {
                        margin: 5px 0;
                    }
                }
            }
        }
    }

    .selected-contact {
        p {
            margin: 5px 0;
        }
    }
}