.terms-and-condition {
    &.terms-and-condition-view-container {
        .popup-container {
            .popup-content {
                padding-bottom: 0;
                overflow: hidden;
                max-width: 840px;

                h1 {
                    color: $chalkboard-dark;
                    font-size: 32px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 43px;
                    letter-spacing: -.3px;
                    margin: 0 0 16px;
                }

                h5 {
                    color: $chalkboard-dark;
                    font-size: 16px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 20px;
                    letter-spacing: -.2px;
                    margin: 0 0 6px;
                    padding: 0;
                }

                hr {
                    margin: 34px 0;
                }

                p {
                    color: $cement-dark;
                    font-size: 14px;
                    line-height: 20px;
                    margin: 0 0 25px;
                }

                ul {
                    &.square {
                        list-style-type: square;
                    }
                }

                ol {
                    & > li {
                        color: $cement-dark;
                    }
                }
            }
        }
    }
}
