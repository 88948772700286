.contact-container {
    mailing-location {
        display: block;
    }

    h1.section-title {
        font-size: 1.4em;
        // line-height: 22px;
        font-weight: 600;
        color: $chalkboard-dark;
        margin-top: 11.5px;

        @include bp(small) {
            font-size: 24px;
        }
    }

    .section-sub-title {
        font-size: 14px;
        font-weight: 600;
        color: $chalkboard-dark;

        @include bp(small) {
            font-size: 18px;
            padding-top: 0;
        }
    }

    .vdivide {
        &:before {
            background: $silver;
            width: 1px;
            content: '';
            display: block;
            position: absolute;
            top: 24px;
            bottom: 0;
            left: 0;
            min-height: 70px;

            @include bp(small) {
                display: none;
            }
        }
    }

    .left-contents {
        padding-right: 15px;
        margin-left: -15px;

        .section-title {
            padding-bottom: 7px;
        }

        .policy {
            margin-top: 30px;

            .icon {
                color: $kelly;
                font-size: 22px;
                font-weight: normal;
                line-height: 35px;
                width: 25px;
            }

            &.annuity {
                .icon {
                    color: $sky;
                }
            }

            .info {
                padding-left: 15px;
                width: calc(100% - 40px);

                h2 {
                    margin: 0;
                }
            }
        }
    }

    .right-contents {
        padding-left: 15px;

        .icon {
            font-weight: 600;

            span,
            a {
                font-family: 'Open Sans', sans-serif;
            }

            span {
                &.number {
                    color: $cement-medium;
                }
            }

            a {
                color: $bright-link;
                text-decoration: none;

                :hover {
                    text-decoration: none;
                }
            }

            // &.icon-maps-location-circle {
            //     margin-top: 30px;
            // }
        }

        h2 {
            font-size: 1.2em;
            display: inline-block;
        }

        h3 {
            font-size: 1em;
            line-height: 1.4em;
        }

        .address-container {
            mailing-location.border-bottom {
                &:last-child {
                    border-bottom: 0;
                }
            }
        }

        .border-bottom {
            border-bottom: 1px solid $silver;
        }
    }

    .hours {
        margin-bottom: 10px;
    }

    @include bp(small) {
        font-size: 16px;

        label {
            &.note {
                font-size: 14px;
                margin: 30px 0;
            }
        }

        .popup-content {
            .mobile-padding {
                padding-right: 0;
                padding-left: 0;
            }

            hr {
                margin-left: -25px;
                margin-right: -25px;
            }

            .right-contents {
                padding-left: 5px;
                padding-right: 5px;
            }

            .left-contents {
                padding-left: 10px;
                padding-right: 10px;
                margin-bottom: 17px;

                hr {
                    margin-top: 0;
                    margin-bottom: 0;
                }

                .policies-container {
                    margin: 0 -25px 0 -10px;
                    padding: 30px 20px;
                    background-color: rgba($natural-medium, .5);

                    .policy {
                        .icon {
                            line-height: 40px;
                        }
                    }

                    .first-item {
                        margin-top: 0;
                    }
                }

                .btn-show-details {
                    color: $chalkboard-dark;
                    text-decoration: none;
                    float: none;
                    width: 100%;
                    display: block;

                    &:hover {
                        text-decoration: none;
                        color: $chalkboard-dark;
                    }

                    i {
                        font-size: 16px;
                        font-weight: bold;
                        color: $chalkboard-dark;
                        float: right;
                        line-height: 27.04px;
                    }
                }
            }
        }

        .policies-header {
            font-size: 16px;
            font-weight: 600;
            padding: 25px 20px;
            background-color: rgba($natural-medium, .5);
            margin: -20px -25px 0 -10px;
            border-bottom: 1px solid $silver;
        }
    }
}
