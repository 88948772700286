.cl-and-r {
    margin-top: 50px;

    .filter-container {
        position: absolute;
        right: 15px;
        top: 0;
        @include bp(smaller) {
            position: relative;
            padding-left: 15px;
            margin-top: 15px;
        }
    }

    .form-control {
        padding-right: 35px;
        padding-top: 0;
        padding-bottom: 0;
        height: 30px;

        &.ng-touched {
            &.ng-valid {
                border-color: $silver;

                &:focus {
                    border-color: $sky;
                }
            }
        }
    }

    &-caption {
        background-color: $natural-medium;
        padding: 1.5em 2em;
        margin-bottom: 15px;

        h1 {
            font-size: 20px;
            font-weight: 400;
            color: $chalkboard-dark;
            margin: 0;
        }
    }

    .table {
        > tbody {
            > tr {
                > td {
                    @include bp($small) {
                        padding: 8px;
                    }
                }
            }
        }

        th {
            height: 2.75em;
            position: relative;

            &.pml-sticky {
                position: sticky;
                top: 63px;
            }

            .th-contents {
                display: flex;
                flex-direction: row;
                // align-content: left;
                position: absolute;
                top: 10px;
                left: 4px;
                bottom: 0;
                right: 0;

                span {
                    flex: 0 auto;
                    margin-right: .65em;
                    @include bp(smaller) {
                        white-space: nowrap;
                    }
                }
            }

            &.commission-grid {
                padding-top: 10px;
            }
        }

        th:first-child span {
            padding-left: .2em;
        }

        .filter-arrows-container {
            // position: absolute;
            // right: 0;
            // top: 15px;
            display: inline-block;
            flex: 0 auto;
            margin-top: .35em;

            .icon-sort {
                &-up-arrow,
                &-down-arrow {
                    display: block;
                    color: $chalkboard;
                    font-size: .705em;

                    &.sorted {
                        color: $chalkboard-dark;
                        font-weight: bold;
                    }
                }

                &-up-arrow {
                    margin-bottom: 5px;
                }

                &-down-arrow {
                    margin-top: -5px;
                }
            }
        }

        &-striped {
            > tbody {
                > tr {
                    &:nth-of-type(odd) {
                        background-color: $silver-medium;
                    }
                }
            }

            &.nested {
                > tbody {
                    > tr {
                        &:hover {
                            cursor: pointer;
                        }

                        &:nth-of-type(2n+1) {
                            background-color: $natural;
                        }

                        &:nth-of-type(4n+1) {
                            background-color: $silver-medium;
                        }

                        &.child-row {
                            > td {
                                @include bp($small) {
                                    margin-left: 15px;
                                }
                            }

                            &:hover {
                                cursor: default;
                            }

                            > td {
                                padding: 0;

                                .table {
                                    border: 1px solid $silver;
                                }

                                &:nth-of-type(1) {
                                    &:before {
                                        content: '';
                                    }
                                }
                            }

                            tr {
                                border-bottom: 1px solid $silver;
                            }
                        }
                    }
                }
            }
        }

        &.licenses {
            th {
                min-width: 5em;
                padding-top: 0;
                vertical-align: inherit;

                // span {
                //     display: inline-block;
                //     width: 25%;
                // }
            }
        }
    }

    .contracts {
        th {
            min-width: 5em;
            padding-top: 0;
            vertical-align: inherit;
            @include bp($small) {
                min-width: inherit;
                max-width: inherit;
            }
        }

        td:nth-child(2n),
        th:nth-child(2n) {
            // min-width: 15em;
            // max-width: 15em;
            @include bp($small) {
                min-width: inherit;
                max-width: inherit;
            }
        }

        td {
            vertical-align: middle;

            .icon-bill {
                color: $chalkboard-dark;
                font-size: 23px;
            }

            a {
                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }

            .commission-doc {
                display: block;
                background-color: $apricot;
                margin: 8px 0;
                color: $white;
                font-weight: bold;
                transition: background-color .5s ease;

                &:hover {
                    background-color: darken($apricot, 5%);
                }

                &.annuity {
                    background-color: $sky;

                    &:hover {
                        background-color: darken($sky, 5%);
                    }
                }

                &.life {
                    background-color: $kelly;

                    &:hover {
                        background-color: darken($kelly, 5%);
                    }
                }
            }
        }
    }

    .trainings {
        table {
            > thead {
                > tr {
                    th {
                        &:nth-of-type(1) {
                            @include bp($large) {
                                width: 150px;
                            }
                        }
                    }
                }
            }
        }
    }

    table,
    thead,
    tbody,
    th,
    td,
    tr {
        @include bp(small) {
            display: block;
        }
    }

    thead {
        tr {
            @include bp(small) {
                position: absolute;
                top: -9999px;
                left: -9999px;
            }
        }
    }

    td {
        @include bp(small) {
            position: relative;
            padding-left: 200px;
            margin-left: 150px;
        }

        &:before {
            @include bp(small) {
                position: absolute;
                top: 12px;
                left: 6px;
                width: 200px;
                padding-right: 40px;
                white-space: nowrap;
                margin-left: -150px;
            }
        }
    }

    .contracts,
    .licenses,
    .appointments,
    .trainings {
        td {
            &:before {
                @include bp(small) {
                    font-weight: bold;
                }
            }
        }
    }

    .contracts {
        td {
            &:nth-of-type(1) {
                &:before {
                    @include bp(small) {
                        content: 'Code';
                    }
                }
            }

            &:nth-of-type(2) {
                &:before {
                    @include bp(small) {
                        content: 'Office';
                    }
                }
            }

            &:nth-of-type(3) {
                &:before {
                    @include bp(small) {
                        content: 'Type';
                    }
                }
            }

            &:nth-of-type(4) {
                &:before {
                    @include bp(small) {
                        content: 'Company';
                    }
                }
            }

            &:nth-of-type(5) {
                &:before {
                    @include bp(small) {
                        content: 'Start Date';
                    }
                }
            }
        }
    }

    .licenses {
        td {
            &:nth-of-type(1) {
                &:before {
                    @include bp(small) {
                        content: 'License #';
                    }
                }
            }

            &:nth-of-type(2) {
                &:before {
                    @include bp(small) {
                        content: 'State';
                    }
                }
            }

            &:nth-of-type(3) {
                &:before {
                    @include bp(small) {
                        content: 'Start Date';
                    }
                }
            }

            &:nth-of-type(4) {
                &:before {
                    @include bp(small) {
                        content: 'Expiry Date';
                    }
                }
            }

            &:nth-of-type(5) {
                &:before {
                    @include bp(small) {
                        content: '';
                    }
                }
            }
        }

        .child-row {
            .table {
                tr {
                    td {
                        &:nth-of-type(1) {
                            &:before {
                                @include bp(small) {
                                    content: 'Description';
                                }
                            }
                        }

                        &:nth-of-type(2) {
                            &:before {
                                @include bp(small) {
                                    content: 'Status';
                                }
                            }
                        }

                        &:nth-of-type(3) {
                            &:before {
                                @include bp(small) {
                                    content: 'Start Date';
                                }
                            }
                        }

                        &:nth-of-type(4) {
                            &:before {
                                @include bp(small) {
                                    content: 'Expiry Date';
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .appointments {
        td {
            &:nth-of-type(1) {
                &:before {
                    @include bp(small) {
                        content: 'License #';
                    }
                }
            }

            &:nth-of-type(2) {
                &:before {
                    @include bp(small) {
                        content: 'State';
                    }
                }
            }

            &:nth-of-type(3) {
                &:before {
                    @include bp(small) {
                        content: 'Company';
                    }
                }
            }

            &:nth-of-type(4) {
                &:before {
                    @include bp(small) {
                        content: 'Start Date';
                    }
                }
            }

            &:nth-of-type(5) {
                &:before {
                    @include bp(small) {
                        content: 'Expiry Date';
                    }
                }
            }
        }

        .child-row {
            .table {
                tr {
                    td {
                        &:nth-of-type(1) {
                            &:before {
                                @include bp(small) {
                                    content: 'Line Code';
                                }
                            }
                        }

                        &:nth-of-type(2) {
                            &:before {
                                @include bp(small) {
                                    content: 'Reason';
                                }
                            }
                        }

                        &:nth-of-type(3) {
                            &:before {
                                @include bp(small) {
                                    content: 'Description';
                                }
                            }
                        }

                        &:nth-of-type(4) {
                            &:before {
                                @include bp(small) {
                                    content: 'Status';
                                }
                            }
                        }

                        &:nth-of-type(5) {
                            &:before {
                                @include bp(small) {
                                    content: 'Start Date';
                                }
                            }
                        }

                        &:nth-of-type(6) {
                            &:before {
                                @include bp(small) {
                                    content: 'Expiry Date';
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .trainings {
        td {
            &:nth-of-type(1) {
                &:before {
                    @include bp(small) {
                        content: 'Course';
                    }
                }
            }

            &:nth-of-type(2) {
                &:before {
                    @include bp(small) {
                        content: 'State';
                    }
                }
            }

            &:nth-of-type(3) {
                &:before {
                    @include bp(small) {
                        content: 'Completion Date';
                    }
                }
            }

            &:nth-of-type(4) {
                &:before {
                    @include bp(small) {
                        content: 'Last Update Date';
                    }
                }
            }

            &:nth-of-type(5) {
                &:before {
                    @include bp(small) {
                        content: 'Credit Hours';
                    }
                }
            }
        }
    }

    .bonus-documents {
        h4 {
            font-weight: 400;
            font-size: 20px;
        }
    }
}
