.wg-client-add-payment-account {
    position: relative;
    margin: 10px 0;

    .ep-checkbox {
        float: none;

        .opt-in-label {
            font-size: 14px;
            font-weight: bold;
            line-height: 27px;
        }
    }

    .btn {
        &-group {
            margin-top: 20px;
        }
    }

    .panel {
        &-title {
            .icon {
                &-close {
                    background-color: $kelly;
                    color: $white;
                }
            }
        }

        &-group {
            margin-bottom: 0;
        }
    }

    .spinner {
        &.spinner-small {
            top: 50%;
        }
    }

    .accordion {
        &-toggle {
            span {
                font-size: 18px;
                font-weight: normal;

                &.icon-close {
                    font-size: 15px;
                }
            }
        }
    }
}