body {
    &.unsubmitted {
        .filter-section {
            .e-app-link {
                &:before {
                    content: '|';
                }
            }
        }
    }
}

wg-unsubmitted-item {
    a {
        &.no-click {
            &:hover {
                cursor: default;
            }
        }
    }
}