.alert-details {
    hr {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .alert-desktop {
        margin-top: 110px;
    }

    .error-container {
        margin-bottom: 40px;
    }

    .main-title {
        padding-left: 15px;
        padding-right: 15px;
        background-color: $sky-dark;
        height: 50px;
        margin-top: -1px;
    }

    .btn-view-policy {
        width: 140px;
        height: 40px;
        font-size: 14px;
        padding: 0;
    }

    .alert-detail-field {
        margin-bottom: 20px;

        label {
            display: block;
        }

        p {
            color: $cement-medium;
        }
    }

    .alert-container {
        margin-bottom: -20px;

        .modal-content-title {
            font-weight: normal;

            @include bp(medium) {
                text-align: left;
            }
        }

        .label {
            &.column-content {
                display: block;
                font-size: 14px;
                line-height: 22px;
                color: $chalkboard-dark;
                font-weight: 400;
                padding: 0;
                margin: 0;
            }
        }

        .modal-details-date {
            line-height: 26px;
        }

        .modal-content-owner-info {
            font-weight: 400;

            .owner-name {
                display: inline-block;
                vertical-align: middle;
                font-size: 16px;
                line-height: 20px;
                color: $chalkboard-dark;
                margin-right: 8px;
                text-transform: capitalize;
            }

            .owner-info {
                color: $cement-medium;
                font-size: 14px;
                display: block;
                line-height: 20px;
                padding-top: 2px;
            }

            .separator-vertical-line {
                background-color: $silver;
                margin: 0 5px;
                height: 14px;
            }
        }

        .buttons-container {
            .btn {
                background-color: $sea-foam-medium;

                &:hover {
                    background-color: $secondary-btn-hover;
                }
            }
        }

        & + hr {
            margin-bottom: 20px;
        }
    }

    .buttons-container {
        margin-top: 20px;
    }
}

.alert-modal {
    background: $modal-background;
    overflow: hidden;
    overflow-y: scroll;
}
