/* Tiempos Headline Family */

@font-face {
    /* Tiempos Headline - Medium */
    font-family: 'TiemposHeadline';
    src: url('../fonts/tiempos/TiemposHeadlineWeb-Medium.woff2') format('woff2'), url('../fonts/tiempos/TiemposHeadlineWeb-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    /* Tiempos Headline - Medium/Italic */
    font-family: 'TiemposHeadline';
    src: url('../fonts/tiempos/TiemposHeadlineWeb-MediumItalic.woff2') format('woff2'), url('../fonts/tiempos/TiemposHeadlineWeb-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    /* Tiempos Headline - SemiBold */
    font-family: 'TiemposHeadline';
    src: url('../fonts/tiempos/TiemposHeadlineWeb-Semibold.woff2') format('woff2'), url('../fonts/tiempos/TiemposHeadlineWeb-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    /* Tiempos Headline - Semibold/Italic */
    font-family: 'TiemposHeadline';
    src: url('../fonts/tiempos/TiemposHeadlineWeb-SemiboldItalic.woff2') format('woff2'), url('../fonts/tiempos/TiemposHeadlineWeb-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}