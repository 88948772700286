.flex {
    display: flex;
    flex: 1 auto;

    &-child {
        &-0 {
            flex: 0 auto;
        }

        &-1 {
            flex: 1 auto;
        }

        &-2 {
            flex: 2 auto;
        }
    }

    &-direction {
        &-row {
            flex-direction: row;
        }

        &-column {
            flex-direction: column;
        }
    }

    &-justify {
        &-content {
            &-between {
                justify-content: space-between;
            }

            &-around {
                justify-content: space-around;
            }

            &-stretch {
                justify-content: stretch;
            }

            &-right {
                justify-content: flex-end;
            }

            &-left {
                justify-content: flex-start;
            }
        }
    }

    &-wrap {
        flex-wrap: wrap;

        &-none {
            flex-wrap: nowrap;
        }
    }

    &-align {
        &-items {
            &-center {
                align-items: center;
            }

            &-right {
                align-items: flex-end;
            }
        }
    }

    // Legacy
    &-justify-between {
        justify-content: space-between;
    }

    &-flex-justify-stretch {
        justify-content: stretch;
    }

    &-flex-justify-right {
        justify-content: right;
    }
}