label {
    &.required {
        color: $chalkboard-dark;

        &:after {
            color: $cherry;
            content: '*';
            margin-left: .25em;
        }
    }
}

select {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    background-color: transparent;
    padding: 10px;
    border: 1px solid $silver;
    width: 100%;
    @include transition(all .5s ease);

    &:focus {
        outline: none;
        border-color: $sky;
    }

    &:hover {
        cursor: pointer;
    }

    &:before {
        font-family: 'icomoon' !important;
        content: '\e61d';
    }

    &.ng-invalid {
        color: $form-placeholder-text;
    }
}

.selectize {
    &-control {
        &.single {
            .selectize {
                &-input {
                    border-color: $silver;
                    background-image: none;

                    &:after {
                        z-index: -1;
                        font-family: icomoon;
                        top: 47%;
                        right: 14px;
                        transform: rotate(90deg);
                        content: '\e61d';
                        font-size: 14px;
                        border: 0;
                    }
                }
            }
        }
    }

    &-dropdown {
        &.single {
            border-color: $silver;
        }
    }

    &-input {
        font-family: inherit;
        font-size: 14px;
        -webkit-font-smoothing: inherit;
        line-height: 1.69;
        color: #555555;
        border-radius: 0;

        input {
            font-size: 14px;
            line-height: 1.69;
            @include input-placeholder {
                color: $chalkboard-medium;
            }
        }
    }
}

.form {
    &-control {
        height: 45px;
        border: 1px solid $silver;
        background-color: transparent;
        @include input-placeholder {
            opacity: .5;
        }
        @include transition(all .5s ease);

        &:focus {
            outline: none;
            border-color: $sky;
        }

        &.ng-touched.ng-invalid {
            border-color: $cherry;
        }

        &.ng-touched.ng-valid {
            border-color: $kelly;
        }

        &.form-search-filter {
            &.ng-touched.ng-valid {
                border-color: $silver;
            }
        }
    }

    &-group {
        input,
        select {
            padding: 10px;

            &[type="date"] {
                padding: 2px 10px;
                width: 100%;
            }
        }

        label {
            color: $chalkboard-dark;
            font-weight: bold;
        }

        .error,
        .valid {
            &-icon {
                position: absolute;
                right: 25px;
                top: 35px;

                &.icon-left {
                    right: inherit;
                    top: 16px;
                    left: 10px;
                }
            }
        }

        .error {
            &-icon {
                color: $cherry;
            }

            &-description {
                &:before {
                    content: '\2022';
                    padding-right: 5px;
                }
            }
        }

        .valid-icon {
            color: $kelly;
        }

        .select-wrapper {
            position: relative;
            z-index: 0;
        }
    }
}

.mask {
    &-input-container {
        position: relative;

        .btn-show-mask {
            position: absolute;
            right: 10px;
            top: 9px;

            &:hover {
                cursor: pointer;
            }
        }

        .form-control {
            padding: 6px 55px 6px 6px;
        }
    }
}

.select {
    &-wrapper {
        position: relative;

        &:before {
            font-family: 'icomoon' !important;
            content: '\e61d';
            position: absolute;
            right: 20px;
            top: 12px;
            z-index: -1;
            @include transform(rotate(90deg));
        }

        .form-control {
            &.ng-valid {
                border-color: $silver;
            }

            &:focus {
                border-color: $sky;
            }
        }
    }
}

.wg {
    &-dropdown {
        .wg-dropdown-option-list {
            display: block;
            left: 0;
            width: auto;
        }

        &-option {
            padding: 10px;
            text-transform: capitalize;
            @include transition(all .5s ease);

            &:hover {
                cursor: pointer;
                background-color: $silver;
            }

            &:focus {
                background-color: $silver;
            }

            &:focus {
                outline: none;
            }
        }

        &.form-control {
            padding: 10px;
        }

        .wg-dropdown-selected {
            opacity: .5;
        }

        &.selected {
            text-transform: capitalize;

            .wg-dropdown-selected {
                opacity: 1;
            }
        }

        &:after {
            font-family: 'icomoon' !important;
            content: '\e61d';
            position: absolute;
            right: 20px;
            top: 12px;
            z-index: -1;
            @include transition(all .15s ease);
            @include transform(rotate(90deg));
        }

        &.active {
            &:after {
                @include transform(rotate(-90deg));
            }
        }
    }
}

.ui {
    &-select {
        &-container {
            &:focus {
                outline: none;
            }

            .ui-select-match {
                outline: none;
                @include input-placeholder {
                    color: green;
                }
            }

            .btn {
                padding: 10px;
                font-size: 14px;
                line-height: 23px;
                color: $chalkboard-medium;
                font-weight: 400;

                &-default {
                    &:hover,
                    &:active,
                    &:focus {
                        background-color: transparent;
                        border-color: $silver;
                    }

                    &:active {
                        box-shadow: none;
                    }

                    &-focus {
                        box-shadow: none;
                        background-color: transparent;
                    }

                    &[disabled] {
                        &:hover,
                        &:active,
                        &:focus {
                            background-color: $natural-dark;
                        }
                    }
                }
            }

            .form {
                &-control {
                    &.ng-touched {
                        &.ng-valid {
                            border-color: $silver;
                        }
                    }

                    &:focus {
                        border-color: $silver;
                    }
                }
            }

            &.dropdown {
                background-color: $white;

                .dropdown {
                    &-menu {
                        z-index: 3;
                        width: 100%;
                    }
                }
            }

            .caret {
                display: inline-block;
                border-right: 1px solid;
                border-bottom: 1px solid;
                border-top: 0;
                border-left: 0;
                width: 10px;
                height: 10px;
                transform: rotate(45deg);
                @include transition(all .25s ease);
            }

            &.ui-select-bootstrap {
                .ui-select-toggle {
                    > .caret {
                        top: 17px;
                        right: 20px;
                    }
                }
            }

            &.open {
                &.ui-select-bootstrap {
                    .ui-select-toggle {
                        > .caret {
                            top: 23px;
                            transform: rotate(-135deg);
                            right: 20px;
                        }
                    }
                }
            }
        }

        &-placeholder {
            &.text-muted {
                color: $chalkboard-medium;
                font-weight: 400;
            }
        }

        &-search {
            @include input-placeholder {
                color: $chalkboard-medium;
                opacity: 1;
            }
        }
    }
}

.radio {
    display: inline-block;
    width: 25px;
    height: 25px;
    border: 1px solid $cement;
    border-radius: 50%;

    &:hover {
        cursor: pointer;
    }

    &.active {
        box-shadow: inset 0 0 0 7px $sky;
    }
}

.select-wrapper {
    position: relative;
    z-index: 0;

    &.small {
        .form-control {
            height: 30px;
            padding: 0 5px;
            font-size: 13px;
        }

        &:before {
            top: 4px;
        }
    }
}