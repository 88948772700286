.dialog-block {
    position: fixed;
    background-color: transparent;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    overflow: auto;
}

@-moz-keyframes spinner {
    to {
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    to {
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes spinner {
    to {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -ms-animation: #{$str};
    animation: #{$str};
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }
}

@include keyframes(dotTransparencyOne) {
    0% {
        opacity: 1;
    }

    25% {
        opacity: .6;
    }

    50% {
        opacity: .2;
    }

    75% {
        opacity: .2;
    }

    100% {
        opacity: 1;
    }
}

@include keyframes(dotTransparencyTwo) {
    0% {
        opacity: .2;
    }

    25% {
        opacity: 1;
    }

    50% {
        opacity: .6;
    }

    75% {
        opacity: .2;
    }

    100% {
        opacity: .2;
    }
}

@include keyframes(dotTransparencyThree) {
    0% {
        opacity: .2;
    }

    25% {
        opacity: .2;
    }

    50% {
        opacity: 1;
    }

    75% {
        opacity: .6;
    }

    100% {
        opacity: .2;
    }
}

.linear-loader {
    width: 66px;
    text-align: center;
    background-color: transparent;
    padding: 0;
    margin: 10px auto 0;
    position: absolute;
    left: calc(50% - 33px);

    [class^="dot-"],
    [class*=" dot-"] {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        display: inline-block;
        background-color: #015d82;
        margin-right: 5px;
    }

    .dot-01 {
        @include animation('dotTransparencyOne 1.6845s infinite');
    }

    .dot-02 {
        @include animation('dotTransparencyTwo 1.6845s infinite');
    }

    .dot-03 {
        margin-right: 0;
        @include animation('dotTransparencyThree 1.6845s infinite');
    }

    span {
        font-family: ibm;
        color: #b2deec;
        font-size: 18px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
    }
}

.spinner {
    min-width: 24px;
    min-height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    /* width: 100%; */
    /* padding: 10px; */
    border: 2px solid transparent;
    border-radius: 5px;
    cursor: default;
    z-index: 1000;

    &:before {
        content: 'Loading…';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 30px;
        margin-top: -15px;
        margin-left: -15px;
    }

    &:not(:required) {
        &:before {
            content: '';
            border-radius: 50%;
            border: 4px solid #ccc;
            border-top-color: $sky;
            -moz-animation: spinner .5s linear infinite;
            -o-animation: spinner .5s linear infinite;
            animation: spinner .5s linear infinite;
            -webkit-animation: spinner .5s linear infinite;
        }
    }

    &.spinner-small {
        top: auto;
        bottom: -45px;
        width: 24px;
        height: 24px;

        &::before {
            width: 20px;
            height: 20px;
            top: 0;
            left: 0;
            margin-top: 0;
            margin-left: 0;
        }
    }

    &.spinner-within-document {
        display: block;
        position: relative;
        z-index: inherit;
        top: 0;
        bottom: 0;
        transform: translate(-50%, 0);
    }
}

.dropdown-spinner-container {
    padding: 15px 0;
}
